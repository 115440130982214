import Validator from "Validator"
import MESSAGE from './messages';

export default function validateLogin(data){
  let rules = {
      'email' : 'required',
      'password': 'required|between:8,20'
  };

 

  let validationResponse = Validator.make(data, rules,MESSAGE);
  let isValid = validationResponse.passes();
  let validationErrors = validationResponse.getErrors();

  let errors = {};
  for(let field in validationErrors){
    errors[field] = validationErrors[field][0];
  }

  return {
    errors,
    isValid
  }
}