import { helpCentreConstants } from '../_constants';

export function helpcentre(state = { loading: true, data: [] }, action) {

    switch (action.type) {

        case helpCentreConstants.GET_HELPCENTER_REQUEST:
            return {
                loading: true,
                data: []
            };
        case helpCentreConstants.GET_HELPCENTER_SUCCESS:
            state.data = [...state.data, action.data];
            return {
                loading: false,
                ...state
            };
        case helpCentreConstants.GET_HELPCENTER_FAILED:
            return {
                error: action.error
            };

        // VIEW CATEGORY
        case helpCentreConstants.VIEW_REQUEST:
            return {
                ...state
            };
        case helpCentreConstants.VIEW_SUCCESS:

            return {
                ...action.user.res
            };
        case helpCentreConstants.VIEW_FAILURE:
            return {
                error: action.error
            };
        case helpCentreConstants.GET_HELPCENTER_UPDATE:
            return {};

        case helpCentreConstants.CATEGORY_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
