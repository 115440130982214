import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import validateLogin from "../../../_helpers/validators/login";
import { userActions } from "../../../_actions";
import logo from "../../../../src/assets/logo.png";

class DeleteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
      submitted: false,
      userDeleted: false,
      apiError: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let { errors, isValid } = validateLogin(this.state, name);
    this.setState({
      errors: errors,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    let { errors, isValid } = validateLogin(this.state);
    if (!isValid) {
      this.setState({ errors: errors }, () => {});
    }
    if (email && password && isValid) {
      dispatch(userActions.userLogin(email, password))
        .then((res) => {
          if (res.status) {
            dispatch(userActions.userUpdate(res.res.access_token)).then(
              (res) => {
                if (res.status) {
                  this.setState({ userDeleted: true });
                } else {
                  this.setState({ apiError: res.err&& res.err.msg });
                }
              }
            );
          } else {
            this.setState({ apiError: res.err && res.err.msg });
          }
        })
        .catch((err) => {
          console.log("errrr", err);
        });
    }
  }
  render() {
    const { email, password, submitted, errors } = this.state;
    return (
      <div className="main_bg">
        <div className="app flex-row align-items-center custom_form">
          <Container>
            <Row className="justify-content-center">
              <Col md="7">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <Form onSubmit={this.handleSubmit}>
                        <div className="text-center">
                          <img
                            src={logo}
                            alt="Krishifal"
                            className="logo-login"
                          />
                        </div>
                        {this.state.userDeleted && (
                          <div className="alert alert-success">
                            User Deleted Successfully
                          </div>
                        )}
                        {this.state.apiError && !this.state.userDeleted && (
                          <div  className="alert alert-danger">
                            {this.state.apiError}
                          </div>
                        )}
                        <h1>Delete User</h1>
                        <p className="text-muted">
                          Use your Email pass to delete account
                        </p>
                        <div
                          className={
                            "form-group" +
                            (submitted && !email ? " has-error" : "")
                          }
                        >
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="Phone"
                              name="email"
                              value={email}
                              onChange={this.handleChange}
                            />
                            <div className="help-block">{errors.phone}</div>
                          </InputGroup>
                        </div>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            maxLength="20"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                          />
                          <div className="help-block">{errors.password}</div>
                        </InputGroup>
                        <Row>
                          <Col xs="12" className="text-center">
                            <Button color="primary">Delete</Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

const connectedLoginPage = connect(mapStateToProps)(DeleteUser);
export { connectedLoginPage as DeleteUser };
// export default Login;
