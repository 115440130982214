import { listingConstants,purchaseReqConstants } from '../_constants';
import { purchaseReqService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const purchaseReqActions = {
    getList,
    
    getSinglePurchaseReq,
    initPayment,
    orderRecieved,
    clear
};

function getParam(meta){
  var url = "?";
  if(meta.page!=undefined && meta.page != ''){
      url = url+`pageNo=${meta.page}&`;
  }
  if(meta.pageSize!=undefined  && meta.pageSize != ''){
      url = url+`limit=${meta.pageSize}&`;
  }
  if(meta.search!=undefined && meta.search!=''){
     url = url+`search=${meta.search}&`;
  }
  if(meta.status!=undefined && meta.status!=''){
     url = url+`status=${meta.status}&`;
  }
  if(meta.sort!=undefined && meta.sort!=''){
     url = url+`sort=${meta.sort}&`;
  }
  if(meta.sortBy!=undefined && meta.sortBy!=''){
      url = url+`sortBy=${meta.sortBy}&`;
   }
 
   return url;
}

//******** PURCHASE REQUEST LIST *********//
function getList(cc,type="") { 
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
        purchaseReqService.getList(cc,getParam(meta),type)
            .then(
                purchaseReqList => {
                    if (purchaseReqList.status) {
                        let res = {
                            items: purchaseReqList.res.order,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(purchaseReqList.res.count),
                                // mandi_name:parseInt(purchaseReqList.res.inventory[0].mandi_name)
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(purchaseReqList.err.msg.toString()));
                        dispatch(alertActions.error(purchaseReqList.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}



   //*******  SINGLE PURCHASE REQUEST VIEW  ******///////// 
 function getSinglePurchaseReq(user,type="") {     
    return dispatch => {
        dispatch(request());
        purchaseReqService.viewPurchaseReq(user,type).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: purchaseReqConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: purchaseReqConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: purchaseReqConstants.VIEW_FAILURE, error };
   }
  }

   //*******  INITIATE PAYMENT REQUEST  ******/////////
   function initPayment(user,type="") {     
    return dispatch => {
        dispatch(request());
       return  purchaseReqService.initPayment(user,type).then(
          user => {
            return new Promise((resolve, reject) => {  
            if (user.status === 1) {
                dispatch(alertActions.success(user.res.msg.toString()));
                resolve(1);   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
              reject(0);
            }
          });
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: purchaseReqConstants.INIT_PAYMENT_REQUEST  };
   }
   function success(user) {
     return { type: purchaseReqConstants.INIT_PAYMENT_SUCCESS, user };
   }
   function failure(error) {
     return { type: purchaseReqConstants.INIT_PAYMENT_FAILURE, error };
   }
  }

  function orderRecieved(order_id,params) {     
    return dispatch => {
        dispatch(request());
       return purchaseReqService.orderRecieved(order_id,params).then(
          response => {
            return new Promise((resolve, reject) => {     
            if (response.status === 1) {
               // dispatch(success(response))
                dispatch(alertActions.success(response.res.msg.toString()));
                resolve(1);
                   
            } else {
                alertActions.error(response.err.msg, {
                containerId: "B"
              });
             // dispatch(failure( response.err.msg.toString()));
              dispatch(alertActions.error(response.err.msg.toString()));
              reject(0);
            }
          })
        },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: purchaseReqConstants.RECIEVED_REQUEST  };
   }
   function success(response) {
     return { type: purchaseReqConstants.RECIEVED_SUCCESS, response };
   }
   function failure(error) {
     return { type: purchaseReqConstants.RECIEVED_FAILURE, error };
   }
  }


function clear() {
    return { type: purchaseReqConstants.CLEAR_STORE };
}


