import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { purchaseReqConstants } from '../_constants/purchase.constant';

export const purchaseReqService = {
    getList,
    canclePurchaseReq,
    viewPurchaseReq,  
    initPayment,
    orderRecieved
};

//******* PURCHASE REQUEST LIST ******/////////

function getList(collId, params,type) {  
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
        return fetch( `${config.apiUrl}${APIURL['CC_PURCHASE_REQ_LIST']}${collId}/list${params}`,requestOptions)
        .then(handleResponse)
        .then(purchaseReqResponse => {
           if (purchaseReqResponse.status == 0) {
                return purchaseReqResponse;
            } else {
                return purchaseReqResponse;
            }
        })
        .catch(function(err){
        });
}

function canclePurchaseReq(params,type) {
    var URL = '';
       const requestOptions = {
           method: 'DELETE',
           headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                       'accessToken': localStorage.getItem('jwt')
                    },
       };
       return fetch(`${config.apiUrl}${APIURL['CANCLE_PURCHASE_REQUEST']}/${params.ord_id}`,requestOptions)
           .then(handleResponse)
           .then(purchaseOrdResponse => {
              if (purchaseOrdResponse.status == 0) {
                   return purchaseOrdResponse;
               } else {
                   return purchaseOrdResponse;
               }
           })
           .catch(function(err){
           });
  }

 //*******  VIEW PURCHASE REQUEST  ******/////////
    function viewPurchaseReq(id,type) {
        var URL="";
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
        if(type != '' ){
            URL = `${config.apiUrl}${APIURL["VIEW_CC_PURCHASE_REQ"]}/${id.id}`;
        }else{
            URL = `${config.apiUrl}${APIURL["VIEW_CC_PURCHASE_REQ"]}/${id.id}`;
        }
    
        return fetch(URL, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }
//*******  INITIATE PAYMENT REQUEST  ******//////////order/purchase/request/{:ord_id}/initiate/payment
function initPayment(params,type) {
    var URL = '';
       const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                       'accessToken': localStorage.getItem('jwt')
                    },
       };
       return fetch(`${config.apiUrl}${APIURL['CC_INIT_PAYMENT']}/${params.id}/initiate/payment`,requestOptions)
           .then(handleResponse)
           .then(purchaseReqResponse => {
              if (purchaseReqResponse.status == 0) {
                   return purchaseReqResponse;
               } else {
                   return purchaseReqResponse;
               }
           })
           .catch(function(err){
           });
   }


   function orderRecieved(order_id,params) {
    var URL = '';
       const requestOptions = {
           method: 'PUT',
           headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                       'accessToken': localStorage.getItem('jwt')
                    },
            body:JSON.stringify(params)
       };
       return fetch(`${config.apiUrl}${APIURL['ORDER_RECIEVED']}${order_id}`,requestOptions)
           .then(handleResponse)
           .then(purchaseReqResponse => {
              if (purchaseReqResponse.status == 0) {
                   return purchaseReqResponse;
               } else {
                   return purchaseReqResponse;
               }
           })
           .catch(function(err){
           });
   }

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}