import React,{Component} from 'react';
import states from '../../public/state-districts.json';
import {userService,ccService,clusterService,categoryService} from '../_services'

export class FilterComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            statesList:states,
            districtsList:[],
            blocksList:[],
            states:'',
            districts:'',
            block:'',
            ccList:[],
            cc:'',
            clusterList:[],
            cluster:'',
            status:'',
            catList:[],
            cat_id:'',
            coll_id:''
        }
    }

    onReset= () => {
        this.setState({
            statesList:states,
            districtsList:[],
            blocksList:[],
            states:'',
            districts:'',
            block:'',
            ccList:[],
            cc:'',
            clusterList:[],
            cluster:'',
            status:'',
            cat_id:'',
            coll_id:'',
            clus_id:''
           },()=>{
               this.setInitalCCCLuster();
               console.log(this.state,'ddd');
             this.props.onFilter(this.state);
           });
    }


    componentDidMount(){
       this.setInitalCCCLuster();
    }

    setInitalCCCLuster = ()=> {
            if(this.props.coll_id == 1){
                ccService.getList(`?`)
                .then((res)=>{
                    if(res.status==1){
                        this.setState({
                            ccList:res.res.cc},()=>{});  
                    }  
                })
            }
            if(this.props.clus_id){
                clusterService.getList(`?`)
                .then((res)=>{
                    if(res.status==1){
                    
                        this.setState({
                            clusterList:res.res.cluster},()=>{});  
                    }  
                })
            }
             if(this.props.cat_id){
                categoryService.getList('?')
                .then((res)=>{
                    if(res.status==1){
                       this.setState({
                           catList:res.res.category
                       })
                    }
                })
             }
    }

    handleChange = (e) => {
        let {name,value} = e.target;
        this.setState({[name]:value},()=>{
        if(name == 'states'){
           if(value == ''){
  
               this.setState({
                statesList:states,
                districtsList:[],
                blocksList:[],
                states:'',
                districts:'',
                block:'',
               },()=>{
                   this.setInitalCCCLuster()
               })
           }else{ 
           
                this.onstateChange();
         }
        }
        if(name == 'districts'){
          if(value == ''){
               this.setState({
                 statesList:states,
                 blocksList:[],
                 states:this.state.states,
                 districts:'',
                 block:'',
                },()=>{
                    this.onstateChange();
            })
            }else{
                this.onDistrictChange();
            
            }
         }
         if(name == 'block'){
            if(value == ''){

                this.setState({
                 block:'',
                },()=>{
                    this.onDistrictChange();
             })
            }else{
                this.onBlockChange();
            
            }
         }
    });
    }


onstateChange = () =>{
    userService.getDistricts(this.state.states)
    .then((result)=>{
       if(this.props.is_coll_id){ 
            ccService.getList(`?state=${this.state.states}`)
            .then((res)=>{
                if(res.status==1){
                    this.setState({
                        districtsList:result.res.district,
                        ccList:res.res.cc,
                        districts:'',
                        block:''
                    },()=>{});  
                }  
            })
         }
         if(this.props.is_clus_id){
            clusterService.getList(`?state=${this.state.states}`)
            .then((res)=>{
                if(res.status==1){
                    this.setState({
                        districtsList:result.res.district,
                        clusterList:res.res.cluster,
                        districts:'',
                        block:''},()=>{});  
                }  
            }); 
         }
    });
}

onDistrictChange = () => {
    userService.getBlock(this.state.districts)
    .then((result)=>{
        if(this.props.is_coll_id){
        ccService.getList(`?state=${this.state.states}&district=${this.state.districts}`)
        .then((res)=>{
            if(res.status==1){
            
                this.setState({blocksList:result.res.city,
                    ccList:res.res.cc,
                    block:''},()=>{});
            }
            });
        }
        if(this.props.is_clus_id){
            clusterService.getList(`?state=${this.state.states}&district=${this.state.districts}`)
            .then((res)=>{
                if(res.status==1){
                        this.setState({blocksList:result.res.city,
                        clusterList:res.res.cluster,
                    block:''},()=>{});
                  }
                });
            }
        });
    }


onBlockChange = () =>{
    if(this.props.is_coll_id){
    ccService.getList(`?state=${this.state.states}&district=${this.state.districts}&block=${this.state.block}`)
        .then((res)=>{
            if(res.status==1){
            
                this.setState({
                    ccList:res.res.cc},()=>{});
            }
        });
    }
    if(this.props.is_clus_id){
        clusterService.getList(`?state=${this.state.states}&district=${this.state.districts}&block=${this.state.block}`)
        .then((res)=>{
            if(res.status==1){
            
                this.setState({
                    clusterList:res.res.cluster},()=>{});
            }
        });
    }
}

    handleSubmit = (e) =>{
        e.preventDefault();
       this.props.onFilter(this.state);
    }

render(){
    return (
        <React.Fragment>
            <form className="filter" onSubmit={this.handleSubmit}>
                {this.props.states &&     
                <select name="states" value={this.state.states} onChange={this.handleChange}>
                    <option value="">Select State</option>
                    {this.state.statesList.states.map(data=> <option key={data.id} value={data.name}>{data.name}</option>)
                 }
                </select>
                }
                {this.props.districts && 
                <select name="districts" value={this.state.value} onChange={this.handleChange}>
                    <option value="" selected>select Dristrict</option>
                    {this.state.districtsList.map(data =><option key={data} value={data}>{data}</option>)
                 }
                </select>
                 }
                {this.props.block && 
                <select name="block" value={this.state.value} onChange={this.handleChange}>
                    <option value="">select City</option>
                    {this.state.blocksList.map(data =><option key={data} value={data}>{data}</option>)
                 }
                </select>
                  }
                {this.props.coll_id && 
                    <select name="coll_id" value={this.state.value} onChange={this.handleChange}>
                        <option value="">Select CC</option>
                        {this.state.ccList.map((data,index) =><option key={index} value={data._id}>{data.name}</option>)
                        }
                    </select>
               }
                {this.props.clus_id && 
                <select name="clus_id" value={this.state.value} onChange={this.handleChange}>
                    <option value="">Select Cluster</option>
                    {this.state.clusterList.map((data,index) =><option key={index} value={data._id}>{data.name}</option>)
                 }
                </select>
               }
                {this.props.status && 
                    <select name="status" value={this.state.status} onChange={this.handleChange}>
                                <option value="">Select Status</option>
                                <option value="0">Pending</option>
                                <option value="1">Verified</option>
                                <option value="2">Rejected</option>
                    </select>
                }
                 {this.props.cat_id && 
                    <select name="cat_id" value={this.state.cat_id} onChange={this.handleChange}>
                                  <option value="">Select category</option>
                                  {this.state.catList.map((data,index) =><option key={index} value={data._id}>{data.name.en}</option>)
                 }
                    </select>
                }
                 {this.props.purchase_status && 
                    <select name="status" value={this.state.value} onChange={this.handleChange}>
                                <option value="">Select Status</option>
                                <option value="1">In-process (cancellable)</option>
                                <option value="2">In-process(Non-cancellable)</option>
                                <option value="3">Cancelled</option>
                                <option value="4">Payment initiated</option>
                                <option value="5">Paid</option>
                    </select>
                }
                 {this.props.purchase_order_status && 
                    <select name="status" value={this.state.value} onChange={this.handleChange}>
                                 <option value="">Select Status</option>
                                <option value="1">In-process (cancellable)</option>
                                <option value="2">In-process(Non-cancellable)</option>
                                <option value="3">Cancelled</option>
                                <option value="4">Completed</option>
                                <option value="5">cancelled (refunded)</option>
                    </select>
                }
                 {this.props.purchase_order_status_cluster && 
                    <select name="status" value={this.state.value} onChange={this.handleChange}>
                                <option value="">Select Status</option>
                                <option value="1">In-process (cancellable)</option>
                                <option value="2">In-process(Non-cancellable)</option>
                                <option value="3">Cancelled</option>
                                <option value="4">Completed</option>
                                <option value="5">cancelled (refunded)</option>
                    </select>
                }
                <button className="btn btn-search" type="submit">Filter</button>
                <button className="btn btn-search" onClick={this.onReset} type="button">Reset</button>
              </form>
            </React.Fragment>
          );
          
    }
}
