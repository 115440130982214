import { offerConstants } from '../_constants';

export function offers(state = { loading: true, data: [] }, action) {

    switch (action.type) {

        case offerConstants.GET_OFFER_REQUEST:
            return {
                loading: true,
                data: []
            };
        case offerConstants.GET_OFFER_SUCCESS:
            state.data = [...state.data, action.data];
            return {
                loading: false,
                ...state
            };
        case offerConstants.GET_OFFER_FAILED:
            return {
                error: action.error
            };

        // VIEW OFFER
        case offerConstants.GET_OFFER_REQUEST:
            return {
                ...state
            };
        case offerConstants.GET_OFFER_SUCCESS:

            return {
                ...action.user.res
            };
        case offerConstants.GET_OFFER_FAILED:
            return {
                error: action.error
            };
        case offerConstants.GET_OFFER_UPDATE_REQUEST:
            return {};

        case offerConstants.GET_OFFER_UPDATE_FAILED:
            return {
                error: action.error
            };
        default:
            return state
    }
}
