export const ccConstants = {
    CC_REGISTER_REQUEST: 'CC_REGISTER_REQUEST',
    CC_REGISTER_SUCCESS: 'CC_REGISTER_SUCCESS',
    CC_REGISTER_FAILURE: 'CC_REGISTER_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    CC_UPDATE_REQUEST: 'CC_UPDATE_REQUEST',
    CC_UPDATE_SUCCESS: 'CC_UPDATE_SUCCESS',
    CC_UPDATE_FAILURE: 'CC_UPDATE_FAILURE',


    VIEW_REQUEST: 'CC_VIEW_REQUEST',
    VIEW_SUCCESS: 'CC_VIEW_SUCCESS',
    VIEW_FAILURE: 'CC_VIEW_FAILURE',

    VERIFY_REQUEST: 'CC_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'CC_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'CC_VERIFY_FAILURE',

    ENABLE_REQUEST: 'CC_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'CC_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'CC_ENABLE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}