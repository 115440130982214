import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import validateResetPassword from '../../../_helpers/validators/resetpassword';
import { connect } from 'react-redux';

import { userActions } from '../../../_actions';
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword:'',
      confirmPassword:'',
      errors:{},
      token:'',
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [ name ]: value },()=>{
      const   { errors, isValid } = validateResetPassword(this.state, name);
      this.setState({
       errors: errors,
     });
     });
    this.setState({ [ name ]: value });
    this.setState({ token:this.props.match.params.token });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { newPassword, confirmPassword,token } = this.state;
    const { dispatch } = this.props;
    const { errors, isValid } = validateResetPassword(this.state);
    if(!isValid){
      this.setState({ errors:errors },()=>{});
    }
    if (newPassword && token) {
      dispatch(userActions.resetPassword(token,newPassword));
    }
  }
  render() {
    const { loggingIn } = this.props;
    const { token, newPassword, confirmPassword, submitted,errors } = this.state;
    return (
        <div className="main_bg">
            <div className="app flex-row align-items-center custom_form">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Form onSubmit={ this.handleSubmit }>
                                            <h1>Reset Password</h1>
                                            <p className="text-muted">Enter your password</p>
                                            <div className={ 'form-group' + (submitted && !newPassword ? ' has-error' : '') }>
                                                <InputGroup className="mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-lock"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="password" placeholder="New Password" autoComplete="New Password"  name="newPassword" value={ newPassword } onChange={ this.handleChange } />
                                                    <div className="help-block">{errors.newPassword}</div>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-lock"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="password" placeholder="Confirm Password" autoComplete="Confirm Password"  name="confirmPassword" value={ confirmPassword } onChange={ this.handleChange } />
                                                    <div className="help-block">{errors.confirmPassword}</div>
                                                </InputGroup>
                                            </div>
                                            <Row>
                                                <Col xs="12" className="text-center">
                                                    <Button color="primary" className="px-4">Reset Password</Button>
                                                    { loggingIn &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
  }
}

const connectedResetPage = connect()(ResetPasswordPage);
export { connectedResetPage as ResetPasswordPage };
// export default Login;
