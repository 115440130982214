import React,{Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../public/logo.png'

 export class Loader extends Component {

    constructor(props){
        super(props);
    }
    
    render(){
        const style = this.props.loading?'block':'none';              
        return (
                <div className="loader" style={{'display':style}}>
                <div className="loaderinner">
                              <img src={logo} alt="Krishifal" className="logo_loader"/>
              <Spinner variant="success" className="loader-size-color" animation="border" role="status">
                 <span className="sr-only">Loading...</span>
            </Spinner>
            </div>
            </div>                
         )
        }
}
 