import { notificationConstants } from '../_constants';

export function notification(state = {}, action) {  
  switch (action.type) {
    case notificationConstants.GETALL_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case notificationConstants.NOTIFICATION_ADD_REQUEST:      
      return {
          loading: true,
          ...state
        };
      case notificationConstants.NOTIFICATION_ADD_SUCCESS:
      return {
        loading: false,
        ...state
      };
      case notificationConstants.NOTIFICATION_ADD_FAILURE,notificationConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      case notificationConstants.NOTIFICATION_LIST:
        return {
          ...action.params
       
          
        }

    
    // DELETE NOTIFICATION
    case notificationConstants.DELETE_NOTIFICATION_REQUEST,
          notificationConstants.DELETE_NOTIFICATION_SUCCESS:
      return {};

    case notificationConstants.DELETE_NOTIFICATION_FAILURE:
        return { 
          error: action.error
        };

    default:
      return state
  }
}
