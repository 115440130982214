import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { purchaseReqConstants } from '../_constants/purchase.constant';

export const paymentService = {
    getList,
    sendMoney
    
};

//******* PURCHASE ORDER LIST ******/////////

function getList(collId, params,type) {  
    var URL = '';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        };
      
        if(type == 'pending' ){
            URL = `${config.apiUrl}${APIURL['PAYMENT_LIST']}${collId}/pending/list${params}`
        }else if(type=="failed"){
            URL = `${config.apiUrl}${APIURL['PAYMENT_LIST']}${collId}/failed/list${params}`
        }else{
            URL = `${config.apiUrl}${APIURL['PAYMENT_LIST']}${collId}/success/list${params}`
        }

        return fetch( URL,requestOptions)
        .then(handleResponse)
        .then(purchaseOrdResponse => {
           if (purchaseOrdResponse.status == 0) {
                return purchaseOrdResponse;
            } else {
                return purchaseOrdResponse;
            }
        })
        .catch(function(err){
        });
}

function sendMoney(params) {  
    var URL = '';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body:JSON.stringify({'ord_id':params})
        };
        return fetch( `${config.apiUrl}${APIURL['SEND_PAYMENT']}`,requestOptions)
        .then(handleResponse)
        .then(purchaseOrdResponse => {
           if (purchaseOrdResponse.status == 0) {
                return purchaseOrdResponse;
            } else {
                return purchaseOrdResponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}