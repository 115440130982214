import { graphConstants } from '../_constants';
import { graphService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const graphActions = {
    dashboardGraph,
    clear
};
  

  //*******  DASHBOARD GRAPH  ******///////// 
  function dashboardGraph(accountType,graphType) {     
    return dispatch => {
        dispatch(request());
        graphService.dashboardGraph(accountType,graphType).then(
          user => {            
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: graphConstants.GRAPH_REQUEST  };
   }
   function success(user) {
     return { type: graphConstants.GRAPH_SUCCESS, user };
   }
   function failure(error) {
     return { type: graphConstants.GRAPH_FAILURE, error };
   }
  }

function clear() {
    return { type: graphConstants.CLEAR_STORE };
}


