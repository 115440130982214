export const buyerConstants = {
   BUYER_REGISTER_REQUEST: 'BUYER_REGISTER_REQUEST',
   BUYER_REGISTER_SUCCESS: 'BUYER_REGISTER_SUCCESS',
   BUYER_REGISTER_FAILURE: 'BUYER_REGISTER_FAILURE',

   BUYER_DELETE_REQUEST: 'BUYER_DELETE_REQUEST',
   BUYER_DELETE_SUCCESS: 'BUYER_DELETE_SUCCESS',
   BUYER_DELETE_FAILURE: 'BUYER_DELETE_FAILURE',

   BUYER_UPDATE_REQUEST: 'BUYER_UPDATE_REQUEST',
   BUYER_UPDATE_SUCCESS: 'BUYER_UPDATE_SUCCESS',
   BUYER_UPDATE_FAILURE: 'BUYER_UPDATE_FAILURE',

   ORDER_VIEW_REQUEST: 'ORDER_VIEW_REQUEST',
   ORDER_VIEW_SUCCESS: 'ORDER_VIEW_SUCCESS',
   ORDER_VIEW_FAILURE: 'ORDER_VIEW_FAILURE',

   BUYER_VIEW_REQUEST: 'BUYER_VIEW_REQUEST',
   BUYER_VIEW_SUCCESS: 'BUYER_VIEW_SUCCESS',
   BUYER_VIEW_FAILURE: 'BUYER_VIEW_FAILURE',

   VERIFY_REQUEST: 'BUYER_VERIFY_REQUEST',
   VERIFY_SUCCESS: 'BUYER_VERIFY_SUCCESS',
   VERIFY_FAILURE: 'BUYER_VERIFY_FAILURE',

   ENABLE_REQUEST: 'BUYER_ENABLE_REQUEST',
   ENABLE_SUCCESS: 'BUYER_ENABLE_SUCCESS',
   ENABLE_FAILURE: 'BUYER_ENABLE_FAILURE',

   ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
   ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
   ACTIVATE_FAILURE: 'ACTIVATE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}