import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { productConstants } from '../_constants/product.constant';
export const productService = {
    getList,
    addProduct,
    deleteProduct,
    viewProduct,
    productUpdate,
    verifyProduct,
    enableProduct
    
   
};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_PRODUCT_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(productResoponse => {
           if (productResoponse.status == 0) {
                return productResoponse;
            } else {
                return productResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function deleteProduct(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_PRODUCT']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(productResoponse => {
           if (productResoponse.status == 0) {
                return productResoponse;
            } else {
                return productResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

 //*******  VIEW PRODUCT  ******/////////
    function viewProduct(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_PRODUCT"]}/${id.id}`, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }

     //*******  PRODUCT UPDATE  ******/////////
    function productUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_PRODUCT']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(productResoponse => {
               if (productResoponse.status === 1) {
                    return productResoponse;
                } else {
                    return productResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addProduct(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_PRODUCT']}`, requestOptions)
        .then(handleResponse)
        .then(productResoponse => {
           if (productResoponse.status == 0) {
                return productResoponse;
            } else {
                return productResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// VERIFY PRODUCT //
function verifyProduct(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_PRODUCT']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(productResoponse => {
           if (productResoponse.status == 0) {
                return productResoponse;
            } else {
                return productResoponse;
            }
        })
        .catch(function(err){
        });
}

// ENABLE PRODUCT //
function enableProduct(params) {
   const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_PRODUCT']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(productResoponse => {
           if (productResoponse.status == 0) {
                return productResoponse;
            } else {
                return productResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}