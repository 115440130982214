import Validator from "Validator"
import MESSAGE from './messages';

export default function validateResetPassword(data){
  let rules = {
      'newPassword' : 'required|between:8,20',
      'confirmPassword': 'required|same:newPassword'
  };

  let validationResponse = Validator.make(data, rules,MESSAGE);
  let isValid = validationResponse.passes();
  let validationErrors = validationResponse.getErrors();

  let errors = {};
  for(let field in validationErrors){
    errors[field] = validationErrors[field][0];
  }

  return {
    errors,
    isValid
  }
}