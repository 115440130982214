import { listingConstants,productConstants } from '../_constants';
import { productService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const productActions = {
    getList,
    addProduct,
    deleteProduct,
    getSingleProduct,
    updateProduct,
    verifyProduct,
    enableProduct,
    clear
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined  && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search!=''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.cat_id!=undefined && meta.cat_id!=''){
       url = url+`cat_id=${meta.cat_id}&`;
    }
    if(meta.sort!=undefined && meta.sort!=''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy!=''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
   
     return url;
}

//******** PRODUCT LIST *********//
function getList() {
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;  
        productService.getList(getParam(meta))
            .then(
                productLists => {
                    if (productLists.status) {
                        let res = {
                            items: productLists.res.product,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(productLists.res.count),
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(productLists.err.msg.toString()));
                        dispatch(alertActions.error(productLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

//********* ADD PRODUCT **********//
function addProduct(params) {
    return (dispatch) => {        
        dispatch(request(params));
        productService.addProduct(params)
            .then(
                productResponse => {
                    if (productResponse.status) {
                        dispatch(success(productResponse));
                        // dispatch(alertActions.success(productResponse.res.msg));
                        // setTimeout(() => {                            
                            history.push("/product");
                            dispatch(alertActions.success("Product added successfully"));
                        //   }, 1000);
                    } else {
                        dispatch(failure(productResponse.err.msg.toString()));
                        dispatch(alertActions.error(productResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: productConstants.PRODUCT_REGISTER_REQUEST,params } }
    function success(params) { return { type: productConstants.PRODUCT_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: productConstants.PRODUCT_REGISTER_FAILURE, error } }
}


//******** DELETE PRODUCT *********//
function deleteProduct(params){
    return (dispatch) => {        
        dispatch(request(params));
        productService.deleteProduct(params)
            .then(
                productResponse => {
                    if (productResponse.status) {
                        dispatch(success(productResponse));
                        // dispatch(alertActions.success('Product has been deleted successfully'));
                    } else {
                        dispatch(failure(productResponse.err.msg.toString()));
                        dispatch(alertActions.error(productResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: productConstants.DELETE_REQUEST,params } }
    function success(params) { return { type: productConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: productConstants.DELETE_FAILURE, error } }
}

   //*******  SINGLE PRODUCT VIEW  ******///////// 
 function getSingleProduct(user) {     
    return dispatch => {
        dispatch(request());
        productService.viewProduct(user).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: productConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: productConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: productConstants.VIEW_FAILURE, error };
   }
  }

   //*******  SINGLE PRODUCT UPDATE  ******///////// 
  function updateProduct(params,id) {      
    return (dispatch) => {        
        dispatch(request(params,id));
        productService.productUpdate(params,id)
            .then(
                productResponse => {                    
                    if (productResponse && productResponse.status) {
                        dispatch(success(productResponse));
                        history.push("/product");
                        dispatch(alertActions.success(productResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/product");
                        //   }, 1000);
                    } else {
                        dispatch(failure(productResponse.err.message));
                        dispatch(alertActions.error(productResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: productConstants.PRODUCT_UPDATE_REQUEST,params } }
    function success(params) { return { type: productConstants.PRODUCT_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: productConstants.PRODUCT_UPDATE_FAILURE, error } }
}


//******** VERIFY PRODUCT *********//
function verifyProduct(params){
    return (dispatch) => {        
        dispatch(request(params));
        productService.verifyProduct(params)
            .then(
                productResponse => {
                    if (productResponse.status) {
                        dispatch(success(productResponse));
                        dispatch(alertActions.success(productResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/product");
                          }, 1000);
                    } else {
                        dispatch(failure(productResponse.err.msg.toString()));
                        dispatch(alertActions.error(productResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: productConstants.VERIFY_REQUEST,params } }
    function success(params) { return { type: productConstants.VERIFY_SUCCESS, params } }
    function failure(error) { return { type: productConstants.VERIFY_FAILURE, error } }
}

//******** ENABLE/DISABLE PRODUCT *********//
function enableProduct(params){
    return (dispatch) => {        
        dispatch(request(params));
        productService.enableProduct(params)
            .then(
                productResponse => {
                    if (productResponse.status) {
                        dispatch(success(productResponse));
                        dispatch(alertActions.success(productResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/sar");
                          }, 1000);
                    } else {
                        dispatch(failure(productResponse.err.msg.toString()));
                        dispatch(alertActions.error(productResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: productConstants.ENABLE_REQUEST,params } }
    function success(params) { return { type: productConstants.ENABLE_SUCCESS, params } }
    function failure(error) { return { type: productConstants.ENABLE_FAILURE, error } }
}

function clear() {
    return { type: productConstants.CLEAR_STORE };
}


