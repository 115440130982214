import { sarConstants } from '../_constants';
import { initailState } from '../_constants';

export function sar(state = {}, action) {  
    
  switch (action.type) {
    case sarConstants.SAR_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case sarConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case sarConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW SAR
      case sarConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case sarConstants.VIEW_SUCCESS:   
            
        return {
          adminDetails:action.user.res.adminDetails,   
          prev_cntr:action.user.res.adminDetails.collection_center         
        };
      case sarConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE SAR
        case sarConstants.SAR_UPDATE_REQUEST,
        sarConstants.SAR_UPDATE_SUCCESS:
          return {};

        case sarConstants.SAR_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE SAR
    case sarConstants.DELETE_REQUEST,
          sarConstants.DELETE_SUCCESS:
      return {};

    case sarConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY SAR
    case sarConstants.VERIFY_REQUEST,
    sarConstants.VERIFY_SUCCESS:
      return {};

    case sarConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE SAR
    case sarConstants.ENABLE_REQUEST,
    sarConstants.ENABLE_SUCCESS:
      return {};

    case sarConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
