import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    forgotPassword,
    resetPassword,
    changePassword,
    getAddressByPincode,
    delete: _delete,
    getDistricts,
    getBlock,
    userLogin,
    updateUser
};

// let config ={};
function login(email, password) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ email, password }),
    };
    return fetch(`${config.apiUrl}${APIURL['ADMIN_LOGIN']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.status == 0) {
                return user;
            } else {
                localStorage.setItem('jwt', user.res.access_token);
                localStorage.setItem('user', JSON.stringify(user.res.profile));
                return user;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function userLogin(phone, password) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ phone, password }),
    };
    return fetch(`${config.apiUrl}${APIURL['USER_LOGIN']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
           return user
        })
        .catch(function(err){
           // debugger;
        });
}

function updateUser(accessToken) {
    
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(),accessToken},
        body: JSON.stringify({ is_deleted:1 }),
    };
    return fetch(`${config.apiUrl}${APIURL['UPDATE_USER']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
           return user
        })
        .catch(function(err){
           // debugger;
        });
}


function changePassword(oldPassword, newPassword) {
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),

        body: JSON.stringify({ 'old_pass':oldPassword, 'new_pass':newPassword }),
    };
    return fetch(`${config.apiUrl}${APIURL['CHANGE_PASSWORD']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
           
            if (user.status == 0) {
                return user;
            } else {
                return user;
            }
        })
        .catch(function(err){
           // debugger;
        });
}


function resetPassword(token,newPassword){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 'resetPassToken':token,'new_pass':newPassword }),
    };
    return fetch(`${config.apiUrl}${APIURL['RESET_PASSWORD']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.status == 0) {
                return user;
            } else {
                return user;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

 function getAddressByPincode(pincode){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}user/address/${pincode}`, requestOptions).then(handleResponse)
                .then((address)=>{
                    return address;
                });
            
}


function forgotPassword(username){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ 'email':username }),
    };
    return fetch(`${config.apiUrl}${APIURL['FORGOT_PASSWORD']}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.status == 0) {
                return user;
            } else {
                return user;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    location.reload(true);

}

function getAll(queryParam) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}${APIURL['USERS']}?pageNo=${queryParam.page}&count=${queryParam.perPage}&search=${queryParam.search}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function getDistricts(params){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_DISTRICT']}/${params}`, requestOptions)
        .then(handleResponse)
        .then(userResponse => {
           if (userResponse.status == 0) {
                return userResponse;
            } else {
                return userResponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function getBlock(params){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_BLOCK']}/${params}`, requestOptions)
        .then(handleResponse)
        .then(userResponse => {
           if (userResponse.status == 0) {
                return userResponse;
            } else {
                return userResponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

