import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    getAll,
    forgotPassword,
    resetPassword,
    changePassword,
    delete: _delete,
    userLogin,
    userUpdate,
    userUpdate
};

function login(username, password) {
    return dispatch => {
     dispatch(request({ username }));
     userService.login(username, password)
            .then(
                user => {
                    if (user.status) {
                        dispatch(success(user.res.profile));
                        history.push('/dashboard');
                        dispatch(alertActions.success('You are successfully logged in!'));
                     } else {
                        dispatch(failure(user.err.msg.toString()));
                        dispatch(alertActions.error(user.err.msg.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function userLogin(username, password) {
    return dispatch => {
       return  userService.userLogin(username, password)
               .then(
                   user => {
                       return user
                   },
                   error => {
                     return  error
                   }
               );
       };
       
}
function userUpdate(accessToken) {
    return dispatch => {
       return  userService.updateUser(accessToken)
               .then(
                   user => {
                       return user
                   },
                   error => {
                     return  error
                   }
               );
       };
       
}

function changePassword(oldPassword, newPassword) {
    return dispatch => {
     dispatch(request({ newPassword }));
     userService.changePassword(oldPassword, newPassword)
            .then(
                user => {
                    if (user.status) {
                        dispatch(success(user.res));
                        history.push('/login');
                        dispatch(alertActions.success(user.res.msg.toString()));
                     } else {
                        dispatch(failure(user.err.msg.toString()));
                        dispatch(alertActions.error(user.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}


function forgotPassword(username){
    return dispatch => {
        dispatch(request({ username }));
        userService.forgotPassword(username)
        .then(
            user => {
                if (user.status) {
                    dispatch(success(user.res));
                    history.push('/login');
                    dispatch(alertActions.success(user.res.msg.toString()));
                } else {
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                debugger;
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(user) { return { type: userConstants.FORGOT_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_FAILURE, error } }
}


function resetPassword(token,newPassword){
    return dispatch => {
        dispatch(request({ newPassword }));
        userService.resetPassword(token, newPassword)
        .then(
            user => {
                if (user.status) {
                    dispatch(success(user.res));
                    history.push('/');
                    dispatch(alertActions.success(user.res.msg.toString()));
                } else {
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                debugger;
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request(user) { return { type: userConstants.RESET_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll(queryParam) {
    return dispatch => {
        dispatch(request(queryParam));

        userService.getAll(queryParam)
            .then(
                users => dispatch(success(users.responseData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST, queryParam } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}