import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { inventoryConstants } from '../_constants/inventory.constant';

export const inventoryService = {
    getList,
    addInventory,
    deleteInventory,
    viewInventory,
    inventoryUpdate,   
    getListProduct 
};

function getList(collId, params,type) {  
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
  
    if(type == 'cluster'){
        URL =  `${config.apiUrl}${APIURL['CLUSTER_INVENTORY_LIST']}${collId}/inventory/list${params}`
    }else{
        URL = `${config.apiUrl}${APIURL['CC_INVENTORY_LIST']}${collId}/inventory/list${params}`
    }
    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(inventoryResoponse => {
           if (inventoryResoponse.status == 0) {
                return inventoryResoponse;
            } else {
                return inventoryResoponse;
            }
        })
        .catch(function(err){
        });
}

function getListProduct(collId, params,type) {  
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
  
    if(type == 'cluster'){
        URL =  `${config.apiUrl}${APIURL['CLUSTER_INVENTORY_LIST']}${collId}/inventory/detail/list${params}`
    }else{
        URL = `${config.apiUrl}${APIURL['CC_INVENTORY_LIST']}${collId}/inventory/detail/list${params}`
    }
    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(inventoryResoponse => {
           if (inventoryResoponse.status == 0) {
                return inventoryResoponse;
            } else {
                return inventoryResoponse;
            }
        })
        .catch(function(err){
        });
}

function deleteInventory(params,type) {
 var URL = '';
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    if(type == ''){
        URL = `${config.apiUrl}${APIURL['DELETE_CC_INVENTORY']}/${params.id}`;
    }else{
        URL = `${config.apiUrl}${APIURL['DELETE_CLUSTER_INVENTORY']}/${params.id}`;
    }

    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(inventoryResoponse => {
           if (inventoryResoponse.status == 0) {
                return inventoryResoponse;
            } else {
                return inventoryResoponse;
            }
        })
        .catch(function(err){
        });
}

 //*******  VIEW INVENTORY  ******/////////
    function viewInventory(id,type) {
        var URL="";
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
        if(type != '' ){
            URL = `${config.apiUrl}${APIURL["VIEW_CLUSTER_INVENTORY"]}/${id.id}`;
        }else{
            URL = `${config.apiUrl}${APIURL["VIEW_CC_INVENTORY"]}/${id.id}`;
        }
    
        return fetch(URL, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }

     //*******  INVENTORY UPDATE  ******/////////
    function inventoryUpdate(params,id,type) {   
    var URL ="";     
        const requestOptions = {
            method: 'PUT',
            headers: { 
                         'Content-Type': 'application/json',
                        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };

        if(type==""){
            URL = `${config.apiUrl}${APIURL['EDIT_CC_INVENTORY']}/${id}`;
        }else{
            URL = `${config.apiUrl}${APIURL['EDIT_CLUSTER_INVENTORY']}/${id}`;
        }
    
        return fetch(URL,requestOptions )
            .then(handleResponse)
            .then(inventoryResoponse => {                
               if (inventoryResoponse.status === 1) {
                    return inventoryResoponse;
                } else {
                    return inventoryResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addInventory(params,type) {
    var URL = "";
    const requestOptions = {
        method: 'POST',
        headers: {   'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    if(type == 'cluster'){
        URL = `${config.apiUrl}${APIURL['ADD_CLUSTER_INVENTORY']}`;
    }else{
        URL =  `${config.apiUrl}${APIURL['ADD_CC_INVENTORY']}`;
    }

    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(inventoryResoponse => {
           if (inventoryResoponse.status == 0) {
                return inventoryResoponse;
            } else {
                return inventoryResoponse;
            }
        })
        .catch(function(err){
        });
}


function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}