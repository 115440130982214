import { graphConstants } from '../_constants';
import { initailState } from '../_constants';

export function graph(state = {}, action) {  
    
  switch (action.type) {

      // VIEW DASHBOARD GRAPH
      case graphConstants.GRAPH_REQUEST:      
      return {
          ...state
        };
      case graphConstants.GRAPH_SUCCESS:         
        return {
          ...action.user.res
        };
      case graphConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

    default:
      return state
  }
}
