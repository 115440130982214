import React, { Component, Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { alertActions,sellersActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { PublicRoute } from '../_components';
import { LoginPage } from '../views/Auth/LoginPage';
import {DeleteUser} from '../views/Auth/DeleteUser';
import { RegisterPage } from '../views/Auth/RegisterPage';
import { ForgotPasswordPage } from '../views/Auth/ForgotPasswordPage';
import {ResetPasswordPage} from '../views/Auth/ResetPasswordPage';
import Toatser   from '../_helpers/toaster';
import '../App.scss';
import {notificationsActions,paginationActions} from '../_actions';

//import 'bootstrap/dist/css/bootstrap.min.css';

const Aboutus = lazy(() => import('../views/Aboutus')); 
const DefaultLayout = lazy(() => import('../_containers/DefaultLayout'));

const Page404 = lazy(() => import('../views/Pages/Page404'));
const Page500 = lazy(() => import('../views/Pages/Page500'));

// import { DefaultLayout } from "../_containers/DefaultLayout";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;




class App extends Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        let prevLocation;
        history.listen((location, action) => {
           
           if(prevLocation != location.pathname){
                 dispatch(sellersActions.clear());
                dispatch(alertActions.clear());
                dispatch(paginationActions.$setDefault());
           }
          prevLocation = location.pathname;
        });
    }

    componentDidMount() {
        if(this.props.authentication.loggedIn){
         this.props.dispatch(notificationsActions.getUpdatedList())
         setInterval(()=>  this.props.dispatch(notificationsActions.getUpdatedList()), 960000);
        }
      }


    render() {
        const { alert } = this.props;
         return (
            <React.Fragment>
                <Toatser 
                 {...this.props}
               />
            <Router history={history}>
                {/* <div> */}
              
                    <Suspense fallback={loading()}>
                        <Switch>
                        <Route exact path="/404" name="Page 404"render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                        <Route exact path="/about" name="Page 500" render={props => <Aboutus {...props} />} />
                        <Route exact path="/delete-user" component={DeleteUser} />
                        <PublicRoute exact path="/login" component={LoginPage} />
                        <PublicRoute exact path="/register" component={RegisterPage} />
                        <PublicRoute exact path="/reset-password/:token" component={ResetPasswordPage} />
                        <PublicRoute exact path="/forgot-password" component={ForgotPasswordPage} />
                        <PrivateRoute  path="/" component={DefaultLayout} />
                        
                        </Switch>
                    </Suspense>
                {/* </div> */}
            </Router>
            </React.Fragment>
        );

    }

    
}


function mapStateToProps(state) {
   
    const { alert,authentication } = state;
    return {
        alert,
        authentication
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 