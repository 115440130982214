import { listingConstants } from '../_constants';
import { buyerConstants } from '../_constants';
import { buyersService } from '../_services/buyers.service';
import { alertActions } from '.';
import { history } from '../_helpers';

export const buyersActions = {
    getList,
    buyerOrderHistory,
    viewOrderHistory,
    onActivate,
    addBuyer,
    deleteBuyer,
    getSingleBuyer,
    updateBuyer,
    verifyBuyer,
    enableBuyer,
    clear
};


function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined  && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search != ''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.states!=undefined && meta.states != ''){
       url = url+`state=${meta.states}&`;
    }
    if(meta.district!=undefined && meta.district != ''){
       url = url+`district=${meta.district}&`;
    }
    if(meta.block!=undefined && meta.block != ''){
       url = url+`block=${meta.block}&`;
    }
    if(meta.clus_id!=undefined && meta.clus_id != ''){
       url = url+`clus_id=${meta.clus_id}&`;
    }
    if(meta.sort!=undefined && meta.sort != ''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy != ''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
     if(meta.status!=undefined && meta.status != ''){
        url = url+`status=${meta.status}&`;
     }
     return url;
}

//********* BUYERS LIST **********//
function getList() {
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;               
        buyersService.getList(getParam(meta))
            .then(
                buyerLists => {
                    if (buyerLists.status) {
                        let res = {
                            items: buyerLists.res.seller,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(buyerLists.res.count),
                            },
                        }                        
                        dispatch(success(res));
                       // dispatch(alertActions.success(buyerLists.res.msg.toString()));
                    } else {
                        dispatch(failure(buyerLists.err.msg.toString()));
                        dispatch(alertActions.error(buyerLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}
//********* BUYERS ORDER HISTORY LIST **********//
function buyerOrderHistory(orderId) {
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;                
        buyersService.buyerOrderHistory(orderId,`?pageNo=${meta.page}&limit=${meta.pageSize}`)
            .then(
                buyerLists => {
                    if (buyerLists.status) {
                        let res = {
                            items: buyerLists.res.order,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(buyerLists.res.count),
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(buyerLists.err.msg.toString()));
                        dispatch(alertActions.error(buyerLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

  //*******  SINGLE BUYER ORDER HISTORY  ******///////// 
  function viewOrderHistory(user) {           
    return dispatch => {
        dispatch(request());
        buyersService.viewOrderHistory(user).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: buyerConstants.ORDER_VIEW_REQUEST  };
   }
   function success(user) {
     return { type: buyerConstants.ORDER_VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: buyerConstants.ORDER_VIEW_FAILURE, error };
   }
  }
//********* ADD BUYER **********//
function addBuyer(params) {
    return (dispatch) => {        
        dispatch(request(params));
        buyersService.addBuyer(params)
            .then(
                buyerResponse => {
                    if (buyerResponse.status) {
                        history.push("/buyers");
                        dispatch(alertActions.success(buyerResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/buyers");
                        //   }, 2000);                        
                    } else {
                        dispatch(failure(buyerResponse.err.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.BUYER_REGISTER_REQUEST,params } }
    function success(params) { return { type: buyerConstants.BUYER_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.BUYER_REGISTER_FAILURE, error } }
}


//******** DELETE BUYER *********//
function deleteBuyer(params){
    return (dispatch) => {        
        dispatch(request(params));
        buyersService.deleteBuyer(params)
            .then(
                buyerResponse => {
                    if (buyerResponse.status) {
                        dispatch(success(buyerResponse));
                        dispatch(alertActions.success(buyerResponse.res.msg.toString()));
                    } else {
                        dispatch(failure(buyerResponse.err.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.BUYER_DELETE_REQUEST,params } }
    function success(params) { return { type: buyerConstants.BUYER_DELETE_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.BUYER_DELETE_FAILURE, error } }
}

//******** ACTIVATE BUYER *********//
function onActivate(params){
    return (dispatch) => {        
        dispatch(request(params));
        buyersService.onActivate(params)
            .then(
                buyerResponse => {
                    if (buyerResponse.status) {
                        dispatch(alertActions.success('Buyer activated successfully'));
                    } else {
                        dispatch(failure(buyerResponse.err.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.ACTIVATE_REQUEST,params } }
    function success(params) { return { type: buyerConstants.ACTIVATE_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.ACTIVATE_FAILURE, error } }
}


 //*******  SINGLE BUYER VIEW  ******///////// 
 function getSingleBuyer(user) {     
    return dispatch => {
        dispatch(request());
        buyersService.viewBuyer(user).then(
          user => {
            if (user.status == 1) {
                 // user=JSON.parse(JSON.stringify(user))
                  // value= user.res.sellerDetails.seller.address['0'].pincode
              // let pinCodes = await  userService.getAddressByPincode(value).then(
              //   pinCodes=>pinCodes
              // )
              // user.res.pinCodes=pinCodes
                dispatch(success(user))        
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: buyerConstants.BUYER_VIEW_REQUEST  };
   }
   function success(user) {
     return { type: buyerConstants.BUYER_VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: buyerConstants.BUYER_VIEW_FAILURE, error };
   }
  }

   //*******  SINGLE BUYER UPDATE  ******///////// 
  function updateBuyer(params,id) {      
    return (dispatch) => {        
        dispatch(request(params,id));
        buyersService.buyerUpdate(params,id)
            .then(
                buyerResponse => {                    
                    if (buyerResponse && buyerResponse.status) {
                        history.push("/buyers");
                        dispatch(alertActions.success(buyerResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/buyers");
                        //   }, 2000);
                    } else {
                        dispatch(failure(buyerResponse.res.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.BUYER_UPDATE_REQUEST,params } }
    function success(params) { return { type: buyerConstants.BUYER_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.BUYER_UPDATE_FAILURE, error } }
}

//******** VERIFY BUYER *********//
function verifyBuyer(params){    
    return (dispatch) => {        
        dispatch(request(params));
        buyersService.verifyBuyer(params)
            .then(
                buyerResponse => {
                    if (buyerResponse.status) {
                        history.push("/buyers");
                         dispatch(alertActions.success(buyerResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/buyers");
                        //   }, 2000);
                    } else {
                        dispatch(failure(buyerResponse.err.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.VERIFY_REQUEST,params } }
    function success(params) { return { type: buyerConstants.VERIFY_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.VERIFY_FAILURE, error } }
}

//******** ENABLE/DISABLE BUYER *********//
function enableBuyer(params){    
    return (dispatch) => {        
        dispatch(request(params));
        buyersService.enableBuyer(params)
            .then(
                buyerResponse => {
                    if (buyerResponse.status) {
                        history.push("/buyers");
                        dispatch(alertActions.success(buyerResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/buyers");
                        //   }, 2000);
                    } else {
                        dispatch(failure(buyerResponse.err.msg.toString()));
                        dispatch(alertActions.error(buyerResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: buyerConstants.ENABLE_REQUEST,params } }
    function success(params) { return { type: buyerConstants.ENABLE_SUCCESS, params } }
    function failure(error) { return { type: buyerConstants.ENABLE_FAILURE, error } }
}
//******** CLEAR *********//
function clear() {
  
return { type: listingConstants.CLEAR_STORE };
}


