import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { reportConstants } from '../_constants/report.constant';
export const reportService = {
    reportExl   
};

function reportExl(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.apiUrl}${APIURL['REPORT_EXL']}`, requestOptions)
        .then(handleResponse)
        .then(reportResoponse => {
           if (reportResoponse.status == 0) {
                return reportResoponse;
            } else {
                return reportResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}