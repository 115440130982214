import { helpCentreConstants } from '../_constants';
import { helpCentreService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const helpCentreAction = {
    getData,
    updateData,
    clear
};

//******** HELP CENTERE  *********//
function getData(params) {
    return (dispatch, getState) => {      
        dispatch(request(getState()));
        helpCentreService.getData(params)
            .then(
                helpcenterData => {
                    if (helpcenterData.status) {
                        dispatch(success(helpcenterData.res));
                    } else {
                        dispatch(failure(helpcenterData.err.msg.toString()));
                        dispatch(alertActions.error(helpcenterData.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: helpCentreConstants.GET_HELPCENTER_REQUEST, meta} }
    function success(data) { return { type: helpCentreConstants.GET_HELPCENTER_SUCCESS, data } }
    function failure(error) { return { type: helpCentreConstants.GET_HELPCENTER_FAILED, error } }
}

//********* UPDATE HEPCENTER **********//
function updateData(params) {
    return (dispatch) => {        
        dispatch(request());
        helpCentreService.updateData(params)
            .then(
                helpcentreResponse => {
                    if (helpcentreResponse.status) {
                        setTimeout(() => {
                            dispatch(alertActions.success("succesfully updated"));
                          }, 1000);
                    } else {
                        dispatch(alertActions.error(helpcentreResponse.err.msg.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: helpCentreConstants.GET_HELPCENTER_REQUEST } }
    function success() { return { type: helpCentreConstants.GET_HELPCENTER_SUCCESS,  } }
    function failure(error) { return { type: helpCentreConstants.GET_HELPCENTER_FAILED, error } }
}


function clear() {
    return { type: barConstants.CLEAR_STORE };
}


