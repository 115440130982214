import config from 'config';
import { APIURL } from "../_constants/apiStore";
export const categoryService = {
    getList,
    addCategory,
    deleteCategory,
    viewCategory,
    categoryUpdate,
    verifyCategory,
    enableCategory
    
   
};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_CATEGORY_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(categoryResoponse => {
           if (categoryResoponse.status == 0) {
                return categoryResoponse;
            } else {
                return categoryResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function deleteCategory(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_CATEGORY']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(categoryResoponse => {
           if (categoryResoponse.status == 0) {
                return categoryResoponse;
            } else {
                return categoryResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

 //*******  VIEW CATEGORY  ******/////////
    function viewCategory(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_CATEGORY"]}/${id.id}`, requestOptions).then(handleResponse).then(
            categoryData =>{
                if (categoryData.status == 0) {
                    return categoryData;
                } else {
                    return categoryData;
                }
            }
        );
    }

     //*******  CATEGORY UPDATE  ******/////////
    function categoryUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_CATEGORY']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(categoryResoponse => {
               if (categoryResoponse.status === 1) {
                    return categoryResoponse;
                } else {
                    return categoryResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addCategory(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_CATEGORY']}`, requestOptions)
        .then(handleResponse)
        .then(categoryResoponse => {
           if (categoryResoponse.status == 0) {
                return categoryResoponse;
            } else {
                return categoryResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// VERIFY CATEGORY //
function verifyCategory(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt'),
                 },
                 body: JSON.stringify(params) 
    };
    return fetch(`${config.apiUrl}${APIURL['VERIFY_CATEGORY']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(categoryResoponse => {
           if (categoryResoponse.status == 0) {
                return categoryResoponse;
            } else {
                return categoryResoponse;
            }
        })
        .catch(function(err){
        });
}

// ENABLE CATEGORY //
function enableCategory(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_CATEGORY']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(categoryResoponse => {
           if (categoryResoponse.status == 0) {
                return categoryResoponse;
            } else {
                return categoryResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}