import { sellerConstants } from '../_constants';
import { initailState } from '../_constants';

export function seller(state = {}, action) {  
    
  switch (action.type) {
    case sellerConstants.SELLER_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case sellerConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case sellerConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
      
      // VIEW ORDER HISTORY
      case sellerConstants.ORDER_VIEW_REQUEST:      
      return {
          ...state
        };
      case sellerConstants.ORDER_VIEW_SUCCESS:         
        return {
          orderHistory:action.user.res
        };
      case sellerConstants.ORDER_VIEW_FAILURE:
        return { 
          error: action.error
        };
      // VIEW SELLER
      case sellerConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case sellerConstants.VIEW_SUCCESS:
        return {
          // items: action.user.res.sellerDetails,
          sellerDetail:action.user.res.sellerDetails
        };
      case sellerConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE SELLER
        case sellerConstants.SELLER_UPDATE_REQUEST,
        sellerConstants.SELLER_UPDATE_SUCCESS:
          return {};

        case sellerConstants.SELLER_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE SELLER
    case sellerConstants.DELETE_REQUEST,
          sellerConstants.DELETE_SUCCESS,
          sellerConstants.ACTIVATE_REQUEST,
          sellerConstants.ACTIVATE_SUCCESS:
      return {};

    case sellerConstants.DELETE_FAILURE,
    sellerConstants.ACTIVATE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY SELLER
    case sellerConstants.VERIFY_REQUEST,
    sellerConstants.VERIFY_SUCCESS:
      return {};

    case sellerConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE SELLER
    case sellerConstants.ENABLE_REQUEST,
    sellerConstants.ENABLE_SUCCESS:
      return {};

    case sellerConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
