import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { sellerConstants } from '../_constants';
export const sellersService = {
    getList,
    sellerOrderHistory,
    viewOrderHistory,
    onActivate,
    addSeller,
    deleteSeller,
    viewSeller,
    sellerUpdate,
    verifySeller,
    enableSeller
    
   
};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_SELLERS_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function sellerOrderHistory(orderId,params ) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['SELLERS_ORDER_HISTORY']}/${orderId}${params}`, requestOptions)
        .then(handleResponse)
        .then(sellersHstryResoponse => {
           if (sellersHstryResoponse.status == 0) {
                return sellersHstryResoponse;
            } else {
                return sellersHstryResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function deleteSeller(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_SELLER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function onActivate(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['ACTIVATE_SELLER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

 //*******  VIEW SELLER ORDER HISTORY  ******/////////
 function viewOrderHistory(user) {     
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
        'accessToken': localStorage.getItem('jwt')
     },
    };

    return fetch(`${config.apiUrl}${APIURL["SELLER_VIEW_HISTORY"]}/${user.id}`, requestOptions).then(handleResponse).then(
        sellerData =>{
            if (sellerData.status == 0) {
                return sellerData;
            } else {
                return sellerData;
            }
        }
    );
}

 //*******  VIEW SELLER  ******/////////
    function viewSeller(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_SELLER"]}/${id.id}`, requestOptions).then(handleResponse).then(
            sellerData =>{
                if (sellerData.status == 0) {
                    return sellerData;
                } else {
                    return sellerData;
                }
            }
        );
    }

     //*******  SELLER UPDATE  ******/////////
    function sellerUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_SELLERS']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(sellersResoponse => {
               if (sellersResoponse.status === 1) {
                    return sellersResoponse;
                } else {
                    return sellersResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addSeller(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_SELLER']}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// VERIFY SELLER //
function verifySeller(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body:JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_SELLER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
        });
}

// ENABLE SELLER //
function enableSeller(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_SELLER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sellersResoponse => {
           if (sellersResoponse.status == 0) {
                return sellersResoponse;
            } else {
                return sellersResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}