export const inventoryConstants = {
    
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',
    
    INVENTORY_REGISTER_REQUEST: 'INVENTORY_REGISTER_REQUEST',
    INVENTORY_REGISTER_SUCCESS: 'INVENTORY_REGISTER_SUCCESS',
    INVENTORY_REGISTER_FAILURE: 'INVENTORY_REGISTER_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    INVENTORY_UPDATE_REQUEST: 'INVENTORY_UPDATE_REQUEST',
    INVENTORY_UPDATE_SUCCESS: 'INVENTORY_UPDATE_SUCCESS',
    INVENTORY_UPDATE_FAILURE: 'INVENTORY_UPDATE_FAILURE',


    VIEW_REQUEST: 'INVENTORY_VIEW_REQUEST',
    VIEW_SUCCESS: 'INVENTORY_VIEW_SUCCESS',
    VIEW_FAILURE: 'INVENTORY_VIEW_FAILURE',

    VERIFY_REQUEST: 'INVENTORY_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'INVENTORY_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'INVENTORY_VERIFY_FAILURE',

    ENABLE_REQUEST: 'INVENTORY_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'INVENTORY_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'INVENTORY_ENABLE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}