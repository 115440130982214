import React, {useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import {history} from '../_helpers';
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};
var allFiles = [];
var removedFile = '';
history.listen((location, action) => {
  // clear alert on location change
  allFiles = [];

});
export const Previews = (props) => {
  const handleChangeStatus =  ({ meta, file }, status) => { 
      if(status == 'done'){
          allFiles.push(file);
      }
      if(status == 'removed'){
        removedFile =  file.name;
      }      
       allFiles.forEach((f, index, object) => { 
        if(removedFile === f.name){
              object.splice(index, 1);
                removedFile = '';
          }
        });
        props.onChange(allFiles);
     }
  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      accept="image/*,audio/*,video/*"
    />
  )
  }

