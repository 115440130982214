import React, { PureComponent } from "react";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Link } from "react-router-dom";
import moment from 'moment'
import * as _ from 'lodash';
import appicon from '../../public/appicon.png'
import { Badge,ButtonDropdown,DropdownItem, DropdownMenu,Button, DropdownToggle,ListGroup,ListGroupItem,ListGroupItemHeading,ListGroupItemText,UncontrolledDropdown} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export class ListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: new Array(19).fill(false),
      list:props.list,
      allChecked:false
    };
  }

  toggle(i) {
    const newArray = this.state.dropdownOpen.map((element, index) => { return (index === i ? !element : false); });
    this.setState({
      dropdownOpen: newArray,
    });
  }




  onDelete = (e) =>{ 
 
    if(this.props.listType == 'cc'){
      
      if((this.props.list.sar) && this.props.list.sar.sar_id){
        NotificationManager.error('You can not delete collection Center when SAR is assigned to this Collection Center', 'Warning!', 10000, () => {
        });
        
    }else{
      this.confirmAlertPop();
    }
  }else if(this.props.listType == 'cluster'){
    if(this.props.list.bar && this.props.list.bar.bar_id){
      NotificationManager.error('You can not delete cluster when BAR is assigned to this cluster', 'Warning!', 10000, () => {
      });
     
    }else{
      this.confirmAlertPop();
    }
  }else if(this.props.listType == 'sar'){
    if(_.isEmpty(this.props.list.collection_center)){
      this.confirmAlertPop();
  }else{
    NotificationManager.error('You can not delete SAR when collection center is assigned to this SAR', 'Warning!', 10000, () => {
    });
  }
  }else if(this.props.listType == 'bar'){
    if(_.isEmpty(this.props.list.cluster)){
      this.confirmAlertPop();
  }else{
    NotificationManager.error('You can not delete BAR when cluster is assigned to this BAR', 'Warning!', 10000, () => {
    });
  }
  }else{
    this.confirmAlertPop();
  }
}

confirmAlertPop(){
  confirmAlert({
    title: 'Confirm to Delete?',
    message: 'Are you sure, you want to delete this '+this.props.listType+'?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
        this.props.onDelete(this.props.list._id);
        }
      },
      {
        label: 'No',
        onClick: () => {
        
        }
      }
    ]
  });
}

  onActivate = (e) =>{ 
    confirmAlert({
      title: 'Confirm to Activate?',
      message: 'Are you sure, you want to Activate this '+this.props.listType+'?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
          this.props.onActivate(this.props.list._id);
          }
        },
        {
          label: 'No',
          onClick: () => {
          
          }
        }
      ]
    });
  }
 
  onVerify = (e) => {
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
           
          }
        },
        {
          label: 'No',
          onClick: () => {
          
          }
        }
      ]
    });
  }

  localUpdate = (name,clustername= '',ID="") =>{
    if(clustername == ''){
    localStorage.setItem('ccName', JSON.stringify(name))
    localStorage.setItem('ccID',ID)
    }else{
      localStorage.setItem('clusterName', JSON.stringify(name))
    }
  }

    //********** SELLER and BUYER LISTING *****************//
  render() {
    var { list, index, api,listType } = this.props;
    if(list && listType == 'seller'){
    return (
      <tr>
        <td className="col_order text-center">{index + 1}</td>        
        <td className="col_order text-center">
        {list.seller.s_id?list.seller.s_id:'N/A'}</td>
        {/* {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment> */}
            <td className="col_name captilize-text">
            <Link to={`/seller/view-seller/${this.props.list._id}`}>
              {list.firstname+" "+list.middlename+" "+list.lastname}</Link>
            </td>
            {/* </React.Fragment>
         } */}
          {/* {this.props.authentication.user.admin_type === 101 && 
              <React.Fragment>
              <td className="col_name captilize-text">
                  {list.firstname+" "+list.middlename+" "+list.lastname}
                </td>
              </React.Fragment>
          } */}
        <td className="col_name">
        {/* <a href="tel:555-123-4567">{list && list['phone'] || 'N/A'}</a> */}
        < a href="tel:{list && list.phone}">{list && list['phone'] || 'N/A'}</a>
        </td>
        <td className="col_name">
          {list.seller.address.length?list.seller.address[0].state:''}
        </td>
        <td className="col_name">
          {list.seller.address.length?list.seller.address[0].district:''}
         </td>
        <td className="col_name">
          {list.seller.address.length?list.seller.address[0].city:''}
        </td>        
        <td className="col_name">
        {list.seller.address.length?list.seller.address[0].colony:''}
        </td> 
        {/* <td className="col_name">
          {list.seller.collection_center.name!=''?list.seller.collection_center.name:'N/A'}
        </td>   */}
        <td className="col_name">
        {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
        </td> 
        <td className="col_name text-center" >
        {(()=>{
                if(list.seller.seller_verified === 0){
                  return (<Badge color= 'info'>Pending</Badge> )
                }else if(list.seller.seller_verified  === 1){
                  return (<Badge color= 'success'>Verified</Badge> )
                }else{
                  return (<Badge color= 'danger'>Rejected</Badge> )
                }
              })()}       
          </td>
         <td className="col_name text-center">
           {(()=>{
                if(list.is_deleted === 1){
                  return (<Badge color= 'dark'>Deleted</Badge> )
                }else{
                  if(list.status == 2){
                  return (<Badge color= 'success'>Enable</Badge> )
                  }else{
                    return (<Badge color= 'danger'>Disable</Badge> )
                  }
                }
            })()}
           
          </td>
          <td className="col_name text-center cursor-pointer">
          <ButtonDropdown isOpen={this.state.dropdownOpen[0]} toggle={() => { this.toggle(0); }}>
                  <DropdownToggle caret>
                    Action
                  </DropdownToggle>
                  <DropdownMenu right>
                  <Link to={`/seller/edit/${this.props.list._id}`}>
                    <DropdownItem><i className="fa fa-edit fa-lg"></i>Edit<span className="text-muted"></span></DropdownItem>
                   </Link> 
                   { list.is_deleted!==1?
                    <DropdownItem onClick={this.onDelete}> <i className="fa fa-trash fa-lg" style={{"color":"red"}}></i>Delete</DropdownItem>:null}
                    {list.is_deleted ? 
                      <DropdownItem onClick={this.onActivate}>Activate</DropdownItem>:null}
                  </DropdownMenu>
                </ButtonDropdown>
          </td>
      </tr>

    );
    }
    else if(list && listType == 'buyer'){
      
      return (
        <tr>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_order text-center">
              {list.buyer.b_id?list.buyer.b_id:'N/A'}
        </td>         
        {/* {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment> */}
              <td className="col_name">
              <Link to={`/buyer/view-buyer/${this.props.list._id}`}>
                {list.firstname+" "+list.middlename+" "+list.lastname}</Link>
              </td>
           {/* </React.Fragment>
              }
          {this.props.authentication.user.admin_type === 110 && 
              <React.Fragment>
                  <td className="col_name">              
                {list.firstname+" "+list.middlename+" "+list.lastname}
              </td>
              </React.Fragment>
            }  */}
          <td className="col_name">
          {/* <a href="tel:555-123-4567">{list && list['phone'] || 'N/A'}</a> */}
          < a href="tel:{list && list.phone}">{list && list['phone'] || 'N/A'}</a>
          </td>
          <td className="col_name">
            {list.buyer.address.length?list.buyer.address[0].state:''}
          </td>
          <td className="col_name">
            {list.buyer.address.length?list.buyer.address[0].district:''}
           </td>
          <td className="col_name">
            {list.buyer.address.length?list.buyer.address[0].city:''}
          </td>          
          <td className="col_name">
          {list.buyer.address.length?list.buyer.address[0].colony:''}
          </td> 
            {/* <td className="col_name">
          {list.buyer.collection_center.name!=''?list.buyer.collection_center.name:'N/A'}
        </td>   */}
          <td className="col_name">
          {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
          </td> 
          <td className="col_name text-center" >
        {(()=>{
                if(list.buyer.buyer_verified === 0){
                  return (<Badge color= 'info'>Pending</Badge> )
                }else if(list.buyer.buyer_verified  === 1){
                  return (<Badge color= 'success'>Verified</Badge> )
                }else{
                  return (<Badge color= 'danger'>Rejected</Badge> )
                }
              })()}       
          </td>    

          <td className="col_name text-center">
           {(()=>{
                if(list.is_deleted === 1){
                  return (<Badge color= 'dark'>Deleted</Badge> )
                }else{
                  if(list.status == 2){
                    return (<Badge color= 'success'>Enable</Badge> )
                    }else{
                      return (<Badge color= 'danger'>Disable</Badge> )
                    }
                }
            })()}
           
          </td>
          <td className="col_name text-center cursor-pointer">
          <ButtonDropdown isOpen={this.state.dropdownOpen[0]} toggle={() => { this.toggle(0); }}>
                  <DropdownToggle caret>
                    Action
                  </DropdownToggle>
                  <DropdownMenu right>
                  <Link to={`/buyer/edit/${this.props.list._id}`}> <DropdownItem><i className="fa fa-edit fa-lg"></i>Edit<span className="text-muted"></span></DropdownItem>
                  </Link> 
                  { list.is_deleted!==1?
                    <DropdownItem onClick={this.onDelete}> <i className="fa fa-trash fa-lg" style={{"color":"red"}}></i>Delete</DropdownItem>
                    :null}
                    {list.is_deleted ? 
                      <DropdownItem onClick={this.onActivate}>Activate</DropdownItem>:null}
                  </DropdownMenu>
                </ButtonDropdown>
          </td>

        </tr>
      );
      }

//********** SELLER'S ORDER HISTORY and BUYER'S ORDER HISTORY *****************//

  else if(list && listType == 'seller_Order_History'){    
    return (
      <tr>
        <td className="col_order text-center">{index + 1}</td>
        <td className="col_order text-center">
        <Link to={`/seller/order-history/${this.props.list._id}`}>
          {list.order_id?list.order_id:'N/A'}
          </Link>
          </td>
          <td className="col_order text-center"> {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}</td>
        <td className="col_order text-center">{list.product?list.product.length:'N/A'}</td>
        <td className="col_order text-center"><i class="fa fa-rupee"></i> {list.amount?list.amount:'N/A'}</td> 
        {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment>
               <td className="col_name">
               {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'primary'>In-process (cancellable)</Badge> )
                }else if(list.status  === 2){
                  return (<Badge color= 'primary'>In-process(Non-cancellable)</Badge> )
                }else if(list.status  === 3){
                  return (<Badge color= 'danger'>cancelled</Badge> )
                }else if(list.status  === 4){
                  return (<Badge color= 'dark'>Payment initiated</Badge> )
                 }else{
                  return (<Badge color= 'success'>Paid</Badge> )
                }
              })()}    
               </td>
               </React.Fragment>
        }
        {this.props.authentication.user.admin_type === 101 && 
            <React.Fragment>
             <td className="col_name">
               {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'primary'>In-process</Badge> )
                }else if(list.status  === 2){
                  return (<Badge color= 'dark'>In-process</Badge> )
                }else if(list.status  === 3){
                  return (<Badge color= 'danger'>Cancelled</Badge> )
                }else if(list.status  === 4){
                  return (<Badge color= 'dark'>Payment Initiated </Badge> )
                 }else{
                  return (<Badge color= 'success'>Paid</Badge> )
                }
              })()}    
               </td>  
                 </React.Fragment>
            }
      </tr>
    );
    }
    else if(list && listType == 'buyer_Order_History'){           
      return (
        <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_order text-center">
            <Link to={`/buyer/order-history/${this.props.list._id}`}>
              {list.order_id?list.order_id:'N/A'}
              </Link>
              </td>
            <td className="col_order text-center">  {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}</td>
            <td className="col_order text-center">{list.product?list.product.length:'N/A'}</td>
            <td className="col_order text-center"><i class="fa fa-rupee"></i> {list.amount?list.amount:'N/A'}</td>   
            {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment>        
           <td className="col_name">
           {(()=>{
            if(list.status === 1){
              return (<Badge color= 'primary'>In-process (cancellable)</Badge> )
            }else if(list.status  === 2){
              return (<Badge color= 'info'>In-process(Non-cancellable)</Badge> )
            }else if(list.status  === 3){
              return (<Badge color= 'danger'>Cancelled(refund in process)</Badge> )
            }else if(list.status  === 4){
              return (<Badge color= 'success'>Completed</Badge> )
             }else{
              return (<Badge color= 'info'>Payment Failed</Badge> )
            }
          })()}    
           </td>
           </React.Fragment>
    }
            {this.props.authentication.user.admin_type === 110 && 
            <React.Fragment>
            <td className="col_name">
            {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'primary'>In-process</Badge> )
                }else if(list.status  === 2){
                  return (<Badge color= 'info'>In-process</Badge> )
                }else if(list.status  === 3){
                  return (<Badge color= 'danger'>Cancelled</Badge> )
                }else if(list.status  === 4){
                  return (<Badge color= 'success'>Completed</Badge> )
                 }else{
                  return (<Badge color= 'info'>Payment Failed</Badge> )
                }          
            })()}    
               </td>
               </React.Fragment>
            }
        </tr>
      );
      }

      //********** SAR and BAR LISTING *****************//
      else if(list && listType == 'sar'){
        return (
          <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name captilize-text">
            <Link to={`/sar/view-sar/${this.props.list._id}`}>
            {list.firstname+" "+list.middlename+" "+list.lastname}</Link>
            </td>
            <td className="col_name">
            {/* <a href="tel:555-123-4567">{list && list['phone'] || 'N/A'}</a> */}
            < a href="tel:{list && list.phone}">{list && list['phone'] || 'N/A'}</a>
            </td>
            <td className="col_name email_text">
              {list.email?list.email:'N/A'}
            </td>
            <td className="col_name">
              {list.gender===1?'Male':'Female'}
             </td>         
             <td className="col_name">
             {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
            </td> 
            {/* <td className="col_name text-center" >
            <Badge color={list.status?'success':'danger'}>{list.status?'Verified':'Not verified'}</Badge>            
            </td> */}
            <td className="col_name text-center">
           {(()=>{
                if(list.is_deleted === 1){
                  return (<Badge color= 'dark'>Deleted</Badge> )
                }else{
                  return (<Badge color= 'light'>Active</Badge> )
                }
            })()}
           
          </td>
              <td className="col_name text-center">
              <NotificationContainer/>
              <ButtonDropdown isOpen={this.state.dropdownOpen[0]} toggle={() => { this.toggle(0); }}>
                  <DropdownToggle caret>
                    Action
                  </DropdownToggle>
                  <DropdownMenu right>
                  <Link to={`/sar/edit/${this.props.list._id}`}> <DropdownItem><i className="fa fa-edit fa-lg"></i>Edit<span className="text-muted"></span></DropdownItem></Link> 
                  { list.is_deleted!==1?
                    <DropdownItem onClick={this.onDelete}> <i className="fa fa-trash fa-lg" style={{"color":"red"}}></i>Delete</DropdownItem>
                    :null}
                    {list.is_deleted ? 
                      <DropdownItem onClick={this.onActivate}>Activate</DropdownItem>:null}
                  </DropdownMenu>
                </ButtonDropdown>
             </td>
             
          </tr>
        );
        }
        else if(list && listType == 'bar'){
          return (
            <tr>
              <td className="col_order text-center">{index + 1}</td>
              <td className="col_name captilize-text">
              <Link to={`/bar/view-bar/${this.props.list._id}`}>
              {list.firstname+" "+list.middlename+" "+list.lastname}</Link>
              </td>
              <td className="col_name">
              {/* <a href="tel:555-123-4567">{list && list['phone'] || 'N/A'}</a> */}
              < a href="tel:{list && list.phone}">{list && list['phone'] || 'N/A'}</a>
            </td>
            <td className="col_name email_text">
              {list.email?list.email:'N/A'}
            </td>
            <td className="col_name">
            {list.gender===1?'Male':'Female'}
             </td>          
             <td className="col_name">
             {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
            </td>     
              {/* <td className="col_name text-center" >
              <Badge color={list.status?'success':'danger'}>{list.status?'Verified':'Not verified'}</Badge>              
              </td> */}
              <td className="col_name text-center">
           {(()=>{
                if(list.is_deleted === 1){
                  return (<Badge color= 'dark'>Deleted</Badge> )
                }else{
                  return (<Badge color= 'light'>Active</Badge> )
                }
            })()}
           
          </td>
              <td className="col_name text-center">
              <NotificationContainer/>
              <ButtonDropdown isOpen={this.state.dropdownOpen[0]} toggle={() => { this.toggle(0); }}>
                  <DropdownToggle caret>
                    Action
                  </DropdownToggle>
                  <DropdownMenu right>
                  <Link to={`/bar/edit/${this.props.list._id}`}> <DropdownItem><i className="fa fa-edit fa-lg"></i>Edit<span className="text-muted"></span></DropdownItem></Link> 
                  { list.is_deleted!==1?
                    <DropdownItem onClick={this.onDelete}> <i className="fa fa-trash fa-lg" style={{"color":"red"}}></i>Delete</DropdownItem>
                   :null}
                    {list.is_deleted ? 
                      <DropdownItem onClick={this.onActivate}>Activate</DropdownItem>:null}
                  </DropdownMenu>
                </ButtonDropdown>
             </td>
             
            </tr>
          );
          }

              //********** CATEGORY and BAR PRODUCT *****************//
      else if(list && listType == 'category'){
        return (
          <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name captilize-text">
            <Link to={`/category/view-category/${this.props.list._id}`}>
              {list.name.en}</Link>
            </td>
            <td className="col_name text-center">   
            <img className="img_catg" id="frontImage" src={list && list['image_url'] + list['image']} />
              {/* {list.image_url?list.image_url+list.image:list.image} */}
            </td>
            {/* <td className="col_name">
              {list.unit?list.unit.en:'N/A'}
            </td> */}
               
            <td className="col_name text-center">
              {(()=>{
                if(list.status === 0){
                  return (<Badge color= 'info'>Comming Soon</Badge> )
                }else if(list.status === 1){
                  return (<Badge color= 'success'>Activated</Badge> )
                }else{
                  return (<Badge color= 'danger'>Deactivated</Badge> )
                }
              })()}
                    
            </td>
            <td className="col_name text-center">
            <Link to={`/category/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
            </td>
            <td className="col_name text-center cursor-pointer">
               <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
            </td>
          </tr>
        );
        }
        else if(list && listType == 'product'){
          return (
            <tr>
              <td className="col_order text-center">{index + 1}</td>
              <td className="col_name captilize-text">
              <Link to={`/product/view-product/${this.props.list._id}`}>
                {list.en.name}</Link>
              </td>
              <td className="col_name">
              {list.premiumPercentage?list.premiumPercentage +' '+ ' %':'0'}
            </td>
            <td className="col_name">
              {list.discountPercentage?list.discountPercentage +' '+ ' %':'0'}
            </td>
            {/* <td className="col_name">
              {list.generic_offer?list.generic_offer +' '+ ' %':'0'}
            </td>
            <td className="col_name">
            {list && list.volOffer &&
                              list.volOffer.map(value =>
                                <span key="value">{value.quantity || 0}-{value.percentage } %,</span>
                              )}
            </td> */}
              <td className="col_name text-center">
              <Link to={`/product/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
              </td>
              <td className="col_name text-center">
                 <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
              </td>
            </tr>
          );
          }

    //********** COLLECTION CENTER and CC@ *****************//
    else if(list && listType == 'cc'){   
    return (
      <tr>
        <td className="col_order text-center">{index + 1}</td>
        <td className="col_name captilize-text" 
        onClick={() => { this.localUpdate(list.name,'',list._id)}}>
        <Link to={`/collection-center/viewcc/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
          {list.name?list.name:'N/A'}</Link>
        </td>
        <td className="col_name">
          {list.address?list.address.state:'N/A'}
        </td>
        <td className="col_name">
          {list.address?list.address.district:'N/A'}
         </td>
        <td className="col_name">
          {list.address?list.address.city:'N/A'}
        </td>
        <td className="col_name">
          {list.address?list.address.block:'N/A'}
        </td>  
        <td className="col_name text-center" >
        {list.address?list.address.pincode:'N/A'}
        </td>
        <td className="col_name text-center" >
        {list.address.postOffice?list.address.postOffice:'N/A'}
        </td>
         {this.props.authentication.user.admin_type === 111 && 
          <React.Fragment>
        <td className="col_name text-center">
        <Link to={`/collection-center/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
        </td>
        <td className="col_name text-center cursor-pointer">

           <i className="fa fa-trash fa-lg"  onClick={this.onDelete} style={{"color":"red"}}></i><br />
        </td>
        <NotificationContainer/>
        </React.Fragment>}
      </tr>
    );
    } 
    
    else if(list && listType == 'cluster'){    
      return (
        <tr>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_name captilize-text" onClick={() => { this.localUpdate(list.name,'cluster')}}>
          <Link to={`/cluster/viewcluster/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
            {list.name?list.name:'N/A'}</Link>
          </td>
          <td className="col_name">
            {list.address?list.address.state:'N/A'}
          </td>
          <td className="col_name">
            {list.address?list.address.district:'N/A'}
           </td>
          <td className="col_name">
            {list.address?list.address.city:'N/A'}
          </td>
          <td className="col_name">
            {list.address?list.address.block:'N/A'}
          </td>       
          <td className="col_name text-center" >
            {list.address?list.address.pincode:'N/A'}
          </td>
          <td className="col_name text-center" >
          {list.address.postOffice?list.address.postOffice:'N/A'}
          </td>
           {this.props.authentication.user.admin_type === 111 && 
                    <React.Fragment>
          <td className="col_name text-center">
          <Link to={`/cluster/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
          </td>
          <td className="col_name text-center cursor-pointer">
             <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
          </td>
          <NotificationContainer/>
          </React.Fragment>}
        </tr>
      );
      }
      else if(list && listType == 'inventory'){    
        let  our_prc= ( Number(list.mandi_price) + (((list.mandi_price)*(list.premiumPercentage))/100))
        let  final_prc= ( Number(list.mandi_price) + (((list.mandi_price)*((list.premiumPercentage) + (list.generic_offer)))/100))
          
      return (
        <tr>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_name">
          <Link to={`/collection-center/inventory/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
            {list.name?list.name:'N/A'}
            </Link>
          </td>        
          <td className="col_name">
          <i class="fa fa-rupee"></i> {list.mandi_price?list.mandi_price + ' per ' + list.unit:'N/A'}
          </td>
          <td className="col_name">
          {list.premiumPercentage?list.premiumPercentage +' '+ ' %':'0'}
          </td>
          <td className="col_name">
          {our_prc || 0}
          </td>
          <td className="col_name">
          {list.generic_offer?list.generic_offer + '%':'N/A'}
          </td>
          <td className="col_name">
          {list && list.volOffer &&
                        list.volOffer.map(value =>
                          <span key="value">{value.quantity}-{value.percentage} %,   </span>
                        )}
          </td>
          <td className="col_name">
          {final_prc || 0}
          </td>
          <td className="col_name">
          {(()=>{
               var quntity = (list.cum_listing_qty) + (list.actual_remaining)
                if(quntity === 0){
                  return (<Badge color= 'info'>Equal</Badge> )
                }else if(quntity >0){
                return (<Badge color= 'success'>{quntity} : Surplus</Badge> )
                }else{
                return (<Badge color= 'danger'>{quntity} : Deficit</Badge> )
                }
              })()}
          </td>
          <td className="col_name">
            {list.edited_by?list.edited_by:'N/A'}
           </td>
           {this.props.authentication.user.ediatbleAccess==true && 
               <React.Fragment>
          <td className="col_name text-center">
          <Link to={`/collection-center/inventory/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
          </td>
          <td className="col_name text-center">
             <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
          </td>
          </React.Fragment>}
        </tr>
      );
      }
      else if(list && listType == 'purchase'){      
        return (
          <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name">
            <Link to={`/collection-center/inventory/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
              {list.name?list.name:'N/A'}
              </Link>
            </td>        
            <td className="col_name">
            <i class="fa fa-rupee"></i> {list.mandi_price?list.mandi_price+' per '+list.unit:'N/A'}
            </td>
            <td className="col_name">
              {list.edited_by?list.edited_by:'N/A'}
             </td>
             {this.props.authentication.user.ediatbleAccess==true && 
                 <React.Fragment>
            <td className="col_name text-center">
            <Link to={`/collection-center/inventory/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
            </td>
            <td className="col_name text-center">
               <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
            </td>
            </React.Fragment>}
          </tr>
        );
        }
        else if(list && listType == 'purchase_request'){  
          
          return (
            <tr>
              <td className="col_order text-center">{index + 1}</td>
              <td className="col_name">
              <Link to={`/collection-center/purchase-request/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
                {list.order_id?list.order_id:'N/A'}
                </Link>
              </td>  
              <td className="col_name">
               {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
               </td>  
              <td className="col_name">
                {list.firstname?list.firstname+' '+list.lastname:'N/A'}
               </td>  
               
              <td className="col_name">
                {list.product?list.product.length:'N/A'}
              </td>
              <td className="col_name">
              <i class="fa fa-rupee"></i> {list.amount?list.amount:'N/A'}
               </td>
               {/* <td className="col_name">
               <i class="fa fa-rupee"></i> {list.price?list.price:'N/A'}
               </td>              */}
            {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment>
               <td className="col_name">
               {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'primary'>In-process (cancellable)</Badge> )
                }else if(list.status  === 2){
                  return (<Badge color= 'primary'>In-process(Non-cancellable)</Badge> )
                }else if(list.status  === 3){
                  return (<Badge color= 'danger'>cancelled</Badge> )
                }else if(list.status  === 4){
                  return (<Badge color= 'dark'>Payment initiated</Badge> )
                 }else{
                  return (<Badge color= 'success'>Paid</Badge> )
                }
              })()}    
               </td>
               </React.Fragment>
        }
        {this.props.authentication.user.admin_type === 101 && 
            <React.Fragment>
     <td className="col_name">
               {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'primary'>In-process</Badge> )
                }else if(list.status  === 2){
                  return (<Badge color= 'primary'>In-process</Badge> )
                }else if(list.status  === 3){
                  return (<Badge color= 'danger'>cancelled</Badge> )
                }else if(list.status  === 4){
                  return (<Badge color= 'dark'>Payment initiated</Badge> )
                 }else{
                  return (<Badge color= 'success'>Paid</Badge> )
                }
              })()}    
               </td>
            </React.Fragment>
        }
               {/* {(this.props.authentication.user.ediatbleAccess==true || this.props.authentication.user.ediatbleAccess=='') && 
                   <React.Fragment>
              <td className="col_name text-center">
              <Link to={`/collection-center/inventory/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
              </td>
              <td className="col_name text-center">
                 <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
              </td>
              </React.Fragment>} */}
            </tr>
          );
      }
      else if(list && listType == 'purchase_order'){    
        return (
          <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name">
            <Link to={`/collection-center/purchase-order/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
              {list.order_id?list.order_id:'N/A'}
              </Link>
            </td> 
            <td className="col_name">
              {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
             </td>
            <td className="col_name">
              {list.firstname?list.firstname+' '+list.lastname:'N/A'}
             </td>       
            <td className="col_name">

              {list.product?list.product.length:'N/A'}
            </td>
            <td className="col_name">
            <i class="fa fa-rupee"></i> {list.amount?list.amount:'N/A'}
             </td> 
             {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment>        
           <td className="col_name">
           {(()=>{
            if(list.status === 1){
              return (<Badge color= 'primary'>In-process (cancellable)</Badge> )
            }else if(list.status  === 2){
              return (<Badge color= 'info'>In-process(Non-cancellable)</Badge> )
            }else if(list.status  === 3){
              return (<Badge color= 'danger'>Cancelled(refund in process)</Badge> )
            }else if(list.status  === 4){
              return (<Badge color= 'success'>Completed</Badge> )
             }else{
              return (<Badge color= 'info'>Payment Failed</Badge> )
            }
          })()}    
           </td>
           </React.Fragment>
    }  
             {this.props.authentication.user.admin_type === 101 && 
            <React.Fragment>        
             <td className="col_name">
             {(()=>{
              if(list.status === 1){
                return (<Badge color= 'primary'>In-process</Badge> )
              }else if(list.status  === 2){
                return (<Badge color= 'primary'>In-Process</Badge> )
              }else if(list.status  === 3){
                return (<Badge color= 'danger'>cancelled</Badge> )
              }else if(list.status  === 4){
                return (<Badge color= 'success'>completed</Badge> )
               }else{
                return (<Badge color= 'info'>Refunded</Badge> )
              }
            })()}    
             </td>
             </React.Fragment>
      }
             
          </tr>
        );
    }

    else if(list && listType == 'purchase_order_cluster'){  
      return (
        <tr>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_name">
          <Link to={`/cluster/purchase-order/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
            {list.order_id?list.order_id:'N/A'}
            </Link>
          </td>   
          <td className="col_name">
           {list.createdAt?moment(list.createdAt).format('DD-MM-YYYY hh:mm A'):'N/A'}
           </td>     
          <td className="col_name">
          {list.product?list.product.length:'N/A'}
          </td>
          <td className="col_name">
          <i class="fa fa-rupee"></i> {list.amount?list.amount:'N/A'}
           </td>
           {/* <td className="col_name">
           <i class="fa fa-rupee"></i> {list.price?list.price:'N/A'}
           </td> */}
           <td className="col_name">
            {list.firstname?list.firstname+' '+list.lastname:'N/A'}
           </td> 
           {this.props.authentication.user.admin_type === 111 && 
            <React.Fragment>        
           <td className="col_name">
           {(()=>{
            if(list.status === 1){
              return (<Badge color= 'primary'>In-process (cancellable)</Badge> )
            }else if(list.status  === 2){
              return (<Badge color= 'info'>In-process(Non-cancellable)</Badge> )
            }else if(list.status  === 3){
              return (<Badge color= 'danger'>Cancelled(refund in process)</Badge> )
            }else if(list.status  === 4){
              return (<Badge color= 'success'>Completed</Badge> )
             }else{
              return (<Badge color= 'info'>Payment Failed</Badge> )
            }
          })()}    
           </td>
           </React.Fragment>
    } {this.props.authentication.user.admin_type === 110 && 
      <React.Fragment>
   <td className="col_name">
           {(()=>{
            if(list.status === 1){
              return (<Badge color= 'primary'>In-process </Badge> )
            }else if(list.status  === 2){
              return (<Badge color= 'info'>In-process</Badge> )
            }else if(list.status  === 3){
              return (<Badge color= 'danger'>Cancelled(refund in process)</Badge> )
            }else if(list.status  === 4){
              return (<Badge color= 'success'>Completed</Badge> )
             }else{
              return (<Badge color= 'info'>Payment Failed</Badge> )
            }
          })()}    
           </td>
      </React.Fragment>
      }
           {/* <td className="col_name">
           <Link to={`cluster/purchase-order/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
            Details
            </Link>
           </td> */}
           {/* {(this.props.authentication.user.ediatbleAccess==true || this.props.authentication.user.ediatbleAccess=='') && 
               <React.Fragment>
          <td className="col_name text-center">
          <Link to={`/collection-center/inventory/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
          </td>
          <td className="col_name text-center">
             <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
          </td>
          </React.Fragment>} */}
        </tr>
      );
  }
      else if(list && listType == 'clusterinevntory'){  
        let  our_prc= ( Number(list.mandi_price) - (((list.mandi_price)*(list.discountPercentage))/100))
        let  final_prc= ( our_prc - (((our_prc * list.generic_offer_buyer))/100))
              
        return (
          <tr>
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name">
            <Link to={`/cluster/inventory/view/${this.props.list._id}?name=${list.name?list.name:'N/A'}`}>
              {list.name?list.name:'N/A'}
              </Link>
            </td>        
            <td className="col_name">
            <i class="fa fa-rupee"></i> {list.mandi_price?list.mandi_price+' per '+list.unit:'0'}
            </td>
            <td className="col_name">
            {list.discountPercentage?list.discountPercentage +' '+ '%':'0'}
            </td>
            <td className="col_name">
            {our_prc || 0}
            </td>
            <td className="col_name">
            {list.generic_offer_buyer?list.generic_offer_buyer +' '+ '%':'0'}
            </td>
            <td className="col_name">
            {list && list.volOfferBuyer &&
                          list.volOfferBuyer.map(value =>
                            <span key="value">{value.quantity}-{value.percentage} %,   </span>
                          )}
            </td>
            <td className="col_name">
            {final_prc || 0}
            </td>
            <td className="col_name">
              {list.edited_by?list.edited_by:'N/A'}
             </td>
             {this.props.authentication.user.ediatbleAccess==true && 
               <React.Fragment>
            <td className="col_name text-center">
            <Link to={`/cluster/inventory/edit/${this.props.list._id}`}><i className="fa fa-edit fa-lg"></i></Link> <span className="text-muted"></span>
            </td>
            <td className="col_name text-center">
               <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
            </td>
            </React.Fragment>}
          </tr>
        );
      }

      else if(list && listType == 'payment_pending'){      
        return (
          <tr>
           { list.payment?
          <td className="col_order text-center"></td> : <td className="col_order text-center"><input type="checkbox" 
            checked={this.props.parentState[list._id]}  name={list._id} onChange={this.props.handleChange} /></td>
           }
            <td className="col_order text-center">{index + 1}</td>
            <td className="col_name">
            <Link to={`/collection-center/purchase-request/view/${this.props.list._id}`}>
              {list.order_id?list.order_id:'N/A'}
              </Link>
            </td>        
            <td className="col_name">
            <i class="fa fa-rupee"></i> {list.payableAmount?list.payableAmount:'0'}
            </td>
            <td className="col_name">
              {list.seller_id?list.firstname+" "+list.lastname:'N/A'}
             </td>
             <td className="col_name text-center">
           {(()=>{
                if(list.status === 1){
                  return (<Badge color= 'dark'>In Process (cancellable)</Badge> )
                }else if (list.status === 2) {
                  return (<Badge color= 'light'>In Process (Non-cancellable)</Badge> )
                }else if (list.status === 3) {
                  return (<Badge color= 'Danger'>Cancelled</Badge> )
                }else if (list.status === 4) {
                  return (<Badge color= {list.payment?'success':'dark'}>{list.payment?"Payment Processing":"Payment Initiated"}</Badge> )
                }else{
                  return (<Badge color= 'success'>Paid</Badge> )
                }
            })()}
           
          </td>
          
          </tr>
      
        );
          
      }

      else if(list && listType == 'payment_failed'){  
        
        return (
          <tr>
          <td className="col_order text-center"><input type="checkbox" checked={this.props.parentState[list._id]}  name={list._id} onChange={this.props.handleChange} /></td>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_name">
          <Link to={`/collection-center/purchase-request/view/${this.props.list._id}`}>
            {list.order_id?list.order_id:'N/A'}
            </Link>
          </td>        
          <td className="col_name">
          <i class="fa fa-rupee"></i> {list.payableAmount?list.payableAmount:'0'}
          </td>
          <td className="col_name">
            {list.seller_id?list.firstname+" "+list.lastname:'N/A'}
           </td>
           <td className="col_name">
            {list.payment && list.payment.statusMessage}
           </td>
           <td className="col_name text-center">
         {(()=>{
              if(list.status == 1){
                return (<Badge color= 'dark'>In Process (cancellable)</Badge> )
              }else if (list.status == 2) {
                return (<Badge color= 'light'>In Process (Non-cancellable)</Badge> )
              }else if (list.status == 3) {
                return (<Badge color= 'danger'>Cancelled</Badge> )
              }else if (list.status == 4) {
                return (<Badge color= 'danger'>Payment Failed</Badge> )
              }else{
                return (<Badge color= 'success'>Paid</Badge> )
              }
          })()}
         
        </td>
        
        </tr>
        );
      }

      else if(list && listType == 'payment_success'){      
        return (
          <tr>
          <td className="col_order text-center">{index + 1}</td>
          <td className="col_name">
          <Link to={`/collection-center/purchase-request/view/${this.props.list._id}`}>
            {list.order_id?list.order_id:'N/A'}
            </Link>
          </td>        
          <td className="col_name">
          <i class="fa fa-rupee"></i> {list.payableAmount?list.payableAmount:'0'}
          </td>
          <td className="col_name">
            {list.seller_id?list.firstname+" "+list.lastname:'N/A'}
           </td>
           <td className="col_name text-center">
         {(()=>{
              if(list.status === 1){
                return (<Badge color= 'dark'>In Process (cancellable)</Badge> )
              }else if (list.status === 2) {
                return (<Badge color= 'light'>In Process (Non-cancellable)</Badge> )
              }else if (list.status === 3) {
                return (<Badge color= 'Danger'>Cancelled</Badge> )
              }else if (list.status === 4) {
                return (<Badge color= 'dark'>Payment Initiated</Badge> )
              }else{
                return (<Badge color= 'success'>Paid</Badge> )
              }
          })()}
         
        </td>
        
        </tr>
        );
      }
      else if(list && listType == 'notification'){ 
        return (
          <ListGroup key={index}>
           <ListGroupItem  color={list.read?'secondary':'dark'} action>
             <div className="noti_img">
             <img src={appicon} alt="App icon" />
             </div>
                    <ListGroupItemHeading>{list.en?list.en.title:'N/A'}<span className="right">
                    <UncontrolledDropdown nav direction="down">
                      <DropdownToggle nav>
                      <i className="fa fa-ellipsis-h"></i>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                       <span onClick={()=>this.onDelete(list._id)} >Remove from notification</span>
                        </DropdownItem>                        
                      </DropdownMenu>
                    </UncontrolledDropdown>
                      </span> </ListGroupItemHeading>
                    <ListGroupItemText onClick={()=>this.props.onRead({'notify_img': list.notify_img,'notify_id':list.notify_id,'notification_type':list.notification_type,'_id':list._id})}>
                    {list.en.body?list.en.body:'N/A'}
                    <span className="time">
                      <i className="fa fa-clock-o"></i>
                        {new Date(Number(list.n_id)).toLocaleString()}
                      {/* { moment(moment.unix(list.n_id/1000).format("DD-MM-YYYY HH:mm")).fromNow()} */}
                      </span>
                    </ListGroupItemText>
                  </ListGroupItem>            
         </ListGroup>
          // <tr>
          //   <td className="col_order text-center">{index + 1}</td>
          //   <td className="col_name">
          //   <Link to='#'>
          //     {list.en?list.en.title:'N/A'}
          //     </Link>
          //   </td>        
          //   <td className="col_name">
          //     {list.en.body?list.en.body:'N/A'}
          //   </td>
          //   <td className="col_name">
          //   { moment(moment.unix(list.n_id/1000).format("DD-MM-YYYY HH:mm")).fromNow()} 
          //   </td>
          //   <td className="col_name text-center">
          //      <i className="fa fa-trash fa-lg" onClick={this.onDelete} style={{"color":"red"}}></i><br />
          //   </td>
          // </tr>
        );
      }  
  }
}

