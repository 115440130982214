import { listingConstants, clusterConstants } from '../_constants';
import { clusterService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const clusterActions = {
    getList,
    clusterDashboard,
    addCluster,
    deleteCluster,
    getSingleCluster,
    updateCluster,
    verifyCluster,
    enableCluster,
    clear
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search != ''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.states!=undefined && meta.states != ''){
       url = url+`state=${meta.states}&`;
    }
    if(meta.district!=undefined && meta.district != ''){
       url = url+`district=${meta.district}&`;
    }
    if(meta.block!=undefined && meta.block != ''){
       url = url+`block=${meta.block}&`;
    }
  
    if(meta.sort!=undefined && meta.sort != ''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy != ''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
     return url;
}

//******** Cluster LIST *********//
function getList() {
    return (dispatch, getState) => {
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;
        clusterService.getList(getParam(meta))
            .then(
                clusterLists => {
                    if (clusterLists.status) {
                        let res = {
                            items: clusterLists.res.cluster,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(clusterLists.res.count),
                            },
                        }
                        dispatch(success(res));
                    } else {
                        if(clusterLists.err.errCode==2 || clusterLists.err.errCode==34 || clusterLists.err.errCode==35){
                            localStorage.clear();
                          history.push("/login");
                        }    
                        dispatch(failure(clusterLists.err.msg.toString()));
                        dispatch(alertActions.error(clusterLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta } }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

//*******  CLUSTER DASHBOARD VIEW  ******///////// 
function clusterDashboard(clus_id) {
    return dispatch => {
        dispatch(request());
        return clusterService.clusterDashboard(clus_id).then(
            async user => {
                if (user.status === 1) {
                    dispatch(success(user))
                } else {
                    alertActions.error(user.err.msg, {
                        containerId: "B"
                    });
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: clusterConstants.VIEW_REQUEST };
    }
    function success(user) {
        return { type: clusterConstants.VIEW_SUCCESS, user };
    }
    function failure(error) {
        return { type: clusterConstants.VIEW_FAILURE, error };
    }
}

//********* ADD Cluster **********//
function addCluster(params) {
    return (dispatch) => {
        dispatch(request(params));
        clusterService.addCluster(params)
            .then(
                clusterResponse => {
                    
                    if (clusterResponse.status) {
                        dispatch(success(clusterResponse));
                        history.push("/cluster");
                        dispatch(alertActions. success(clusterResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/cluster");
                        // }, 1000);
                    } else {
                        dispatch(failure(clusterResponse.err.msg.toString()));
                        dispatch(alertActions.error(clusterResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: clusterConstants.CLUSTER_REGISTER_REQUEST, params } }
    function success(params) { return { type: clusterConstants.CLUSTER_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: clusterConstants.CLUSTER_REGISTER_FAILURE, error } }
}


//******** DELETE Cluster *********//
function deleteCluster(params, type = "") {
    // debugger;
    return (dispatch) => {
        dispatch(request(params));
     return   clusterService.deleteCluster(params, type)
            .then(       
                clusterResponse => {
                 return new Promise((resolve,reject)=>{
                    if (clusterResponse.status) {
                         dispatch(alertActions. success('Cluster has been deleted  successfully'));
                         resolve(1);
                    } else {
                        dispatch(alertActions.error(clusterResponse.err.msg.toString()));
                        reject(0)
                    }
                });
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: clusterConstants.DELETE_REQUEST, params } }
    function success(params) { return { type: clusterConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: clusterConstants.DELETE_FAILURE, error } }
}

//*******  SINGLE Cluster VIEW  ******///////// 
function getSingleCluster(user) {
    return dispatch => {
        dispatch(request());
        clusterService.viewCluster(user.id).then(
            user => {
                if (user.status === 1) {
                    dispatch(success(user))

                } else {
                    alertActions.error(user.err.msg, {
                        containerId: "B"
                    });
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: clusterConstants.VIEW_REQUEST };
    }
    function success(user) {
        return { type: clusterConstants.VIEW_SUCCESS, user };
    }
    function failure(error) {
        return { type: clusterConstants.VIEW_FAILURE, error };
    }
}

//*******  SINGLE Cluster UPDATE  ******///////// 
function updateCluster(params, id) {
    return (dispatch) => {
        dispatch(request(params, id));
        clusterService.clusterUpdate(params, id)
            .then(
                clusterResponse => {
                    if (clusterResponse && clusterResponse.status) {
                        dispatch(success(clusterResponse));
                        history.push("/cluster");
                        dispatch(alertActions. success(clusterResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/cluster");
                        // }, 1000);
                    } else {
                        dispatch(failure(clusterResponse.res.msg.toString()));
                        dispatch(alertActions.error(clusterResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: clusterConstants.CLUSTER_UPDATE_REQUEST, params } }
    function success(params) { return { type: clusterConstants.CLUSTER_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: clusterConstants.CLUSTER_UPDATE_FAILURE, error } }
}


//******** VERIFY Cluster *********//
function verifyCluster(params) {
    return (dispatch) => {
        dispatch(request(params));
        clusterService.verifyCluster(params)
            .then(
                clusterResponse => {
                    if (clusterResponse.status) {
                        dispatch(success(clusterResponse));
                        dispatch(alertActions.success(clusterResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/collectioncenter");
                        }, 1000);
                    } else {
                        dispatch(failure(clusterResponse.err.msg.toString()));
                        dispatch(alertActions.error(clusterResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: clusterConstants.VERIFY_REQUEST, params } }
    function success(params) { return { type: clusterConstants.VERIFY_SUCCESS, params } }
    function failure(error) { return { type: clusterConstants.VERIFY_FAILURE, error } }
}

//******** ENABLE/DISABLE Cluster *********//
function enableCluster(params) {
    return (dispatch) => {
        dispatch(request(params));
        clusterService.enableCluster(params)
            .then(
                clusterResponse => {
                    if (clusterResponse.status) {
                        dispatch(success(clusterResponse));
                        dispatch(alertActions.success(clusterResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/collectioncenter");
                        }, 1000);
                    } else {
                        dispatch(failure(clusterResponse.err.msg.toString()));
                        dispatch(alertActions.error(clusterResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: clusterConstants.ENABLE_REQUEST, params } }
    function success(params) { return { type: clusterConstants.ENABLE_SUCCESS, params } }
    function failure(error) { return { type: clusterConstants.ENABLE_FAILURE, error } }
}

function clear() {
    return { type: clusterConstants.CLEAR_STORE };
}


