import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { notificationConstants } from '../_constants/notifications.constants';

export const notificationService = {
    getList,
    addNotification,
    deleteNotification,
    readNotification,
    notificationUpdate,    
};

function getList(params) {  
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
   return fetch(`${config.apiUrl}${APIURL['NOTIFICATION_LIST']}/${params}`, requestOptions)
        .then(handleResponse)
        .then(notificationResoponse => {
           if (notificationResoponse.status == 0) {
                return notificationResoponse;
            } else {
                return notificationResoponse;
            }
        })
        .catch(function(err){
        });
}

function deleteNotification(params) {
 var URL = '';
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

        URL = `${config.apiUrl}${APIURL['NOTIFICATION_DELETE']}/${params.id}`;
 

    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(notificationResoponse => {
           if (notificationResoponse.status == 0) {
                return notificationResoponse;
            } else {
                return notificationResoponse;
            }
        })
        .catch(function(err){
        });
}

 //*******  VIEW INVENTORY  ******/////////
    function readNotification(params) {
        var URL="";
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
    
        return fetch(`${config.apiUrl}${APIURL["NOTIFICATION_READ"]}/${params._id}`, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }

     //*******  INVENTORY UPDATE  ******/////////
    function notificationUpdate(params,id,type) {   
    var URL ="";     
        const requestOptions = {
            method: 'PUT',
            headers: { 
                         'Content-Type': 'application/json',
                        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };

        if(type==""){
            URL = `${config.apiUrl}${APIURL['EDIT_CC_INVENTORY']}/${id}`;
        }else{
            URL = `${config.apiUrl}${APIURL['EDIT_CLUSTER_INVENTORY']}/${id}`;
        }
    
        return fetch(URL,requestOptions )
            .then(handleResponse)
            .then(notificationResoponse => {                
               if (notificationResoponse.status === 1) {
                    return notificationResoponse;
                } else {
                    return notificationResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addNotification(params) {
    
    const requestOptions = {
        method: 'POST',
        headers: {   
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['NOTIFICATION_ADD']}`, requestOptions)
        .then(handleResponse)
        .then(notificationResoponse => {
           if (notificationResoponse.status == 0) {
                return notificationResoponse;
            } else {
                return notificationResoponse;
            }
        })
        .catch(function(err){
        });
}


function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}