export const barConstants = {
    BAR_REGISTER_REQUEST: 'BAR_REGISTER_REQUEST',
    BAR_REGISTER_SUCCESS: 'BAR_REGISTER_SUCCESS',
    BAR_REGISTER_FAILURE: 'BAR_REGISTER_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    BAR_UPDATE_REQUEST: 'BAR_UPDATE_REQUEST',
    BAR_UPDATE_SUCCESS: 'BAR_UPDATE_SUCCESS',
    BAR_UPDATE_FAILURE: 'BAR_UPDATE_FAILURE',


    VIEW_REQUEST: 'BAR_VIEW_REQUEST',
    VIEW_SUCCESS: 'BAR_VIEW_SUCCESS',
    VIEW_FAILURE: 'BAR_VIEW_FAILURE',

    VERIFY_REQUEST: 'BAR_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'BAR_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'BAR_VERIFY_FAILURE',

    ENABLE_REQUEST: 'BAR_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'BAR_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'BAR_ENABLE_FAILURE',

    ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'ACTIVATE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}