import { listingConstants } from '../_constants';
import { initailState } from '../_constants';

export function listings(state = initailState, action) {  
  console.log(state,action,'!!!!!!~~~~~~~~~~~~~~~~~~~~~~~~~');
  switch (action.type) {
    case listingConstants.GETALL_REQUEST:      
        return {
          ...state,
          meta: {
            ...state.meta,
            ...action.meta,
          },
        };
      case listingConstants.GETALL_SUCCESS:
      return {
        items: action.lists.items,
        meta: {
          ...state.meta,
          ...action.lists.meta,
        },  
        
      };
    case listingConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
      case listingConstants.CLEAR_STORE:
        return {...state,
                items:[]
              };
    default:
      return state
  }
}
