import { listingConstants, ccConstants } from '../_constants';
import { ccService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const ccActions = {
    getList,
    ccDashboard,
    addCC,
    deleteCC,
    getSingleCC,
    updateCC,
    verifyCC,
    enableCC,
    clear
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search != ''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.states!=undefined && meta.states != ''){
       url = url+`state=${meta.states}&`;
    }
    if(meta.district!=undefined && meta.district != ''){
       url = url+`district=${meta.district}&`;
    }
    if(meta.block!=undefined && meta.block != ''){
       url = url+`block=${meta.block}&`;
    }
  
    if(meta.sort!=undefined && meta.sort != ''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy != ''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
     return url;
}

//******** CC LIST *********//
function getList() {
    return (dispatch, getState) => {
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;
        ccService.getList(getParam(meta))
            .then(
                ccLists => {
                    if (ccLists.status) {
                        let res = {
                            items: ccLists.res.cc,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(ccLists.res.count),
                            },
                        }
                        dispatch(success(res));
                    } else {
                        dispatch(failure(ccLists.err.msg.toString()));
                        dispatch(alertActions.error(ccLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta } }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

//*******  CC DASHBOARD VIEW  ******///////// 
function ccDashboard(coll_id) {
    return dispatch => {
        dispatch(request());
        return ccService.ccDashboard(coll_id).then(
            async user => {
                if (user.status === 1) {
                    dispatch(success(user))
                } else {
                    alertActions.error(user.err.msg, {
                        containerId: "B"
                    });
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: ccConstants.VIEW_REQUEST };
    }
    function success(user) {
        return { type: ccConstants.VIEW_SUCCESS, user };
    }
    function failure(error) {
        return { type: ccConstants.VIEW_FAILURE, error };
    }
}

//********* ADD CC **********//
function addCC(params) {
    return (dispatch) => {
        dispatch(request(params));
        ccService.addCC(params)
            .then(
                ccResponse => {
                    if (ccResponse.status) {
                        dispatch(success(ccResponse));
                        history.push("/collection-center");
                        dispatch(alertActions.success(ccResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/collection-center");
                        // }, 1000);
                    } else {
                        dispatch(failure(ccResponse.err.msg.toString()));
                        dispatch(alertActions.error(ccResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: ccConstants.CC_REGISTER_REQUEST, params } }
    function success(params) { return { type: ccConstants.CC_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: ccConstants.CC_REGISTER_FAILURE, error } }
}



//******** DELETE CC *********//
function deleteCC(params) {
    return (dispatch) => {
        dispatch(request(params));
       return ccService.deleteCC(params)
            .then(
                ccResponse => {
                    return new Promise((resolve,reject)=>{
                    if (ccResponse.status) {
                        dispatch(alertActions.success('Collection centre has been deleted successfully'));
                        resolve(1);
                    } else {
                        dispatch(alertActions.error(ccResponse.err.msg.toString()));
                        reject(0);
                    }
                })
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: ccConstants.DELETE_REQUEST, params } }
    function success(params) { return { type: ccConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: ccConstants.DELETE_FAILURE, error } }
}

//*******  SINGLE CC VIEW  ******///////// 
function getSingleCC(user) {
    return dispatch => {
        dispatch(request());
        ccService.viewCC(user.id).then(
            user => {
                if (user.status === 1) {
                    dispatch(success(user))

                } else {
                    alertActions.error(user.err.msg, {
                        containerId: "B"
                    });
                    dispatch(failure(user.err.msg.toString()));
                    dispatch(alertActions.error(user.err.msg.toString()));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: ccConstants.VIEW_REQUEST };
    }
    function success(user) {
        return { type: ccConstants.VIEW_SUCCESS, user };
    }
    function failure(error) {
        return { type: ccConstants.VIEW_FAILURE, error };
    }
}

//*******  SINGLE CC UPDATE  ******///////// 
function updateCC(params, id) {
    return (dispatch) => {
        dispatch(request(params, id));
        ccService.ccUpdate(params, id)
            .then(
                ccResponse => {
                    if (ccResponse && ccResponse.status) {
                        dispatch(success(ccResponse));
                        history.push("/collection-center");
                        dispatch(alertActions.success(ccResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/collection-center");
                        // }, 1000);
                    } else {
                        dispatch(failure(ccResponse.res.msg.toString()));
                        dispatch(alertActions.error(ccResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: ccConstants.CC_UPDATE_REQUEST, params } }
    function success(params) { return { type: ccConstants.CC_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: ccConstants.CC_UPDATE_FAILURE, error } }
}


//******** VERIFY CC *********//
function verifyCC(params) {
    return (dispatch) => {
        dispatch(request(params));
        ccService.verifyCC(params)
            .then(
                ccResponse => {
                    if (ccResponse.status) {
                        dispatch(success(ccResponse));
                        dispatch(alertActions.success(ccResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/collectioncenter");
                        }, 1000);
                    } else {
                        dispatch(failure(ccResponse.err.msg.toString()));
                        dispatch(alertActions.error(ccResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: ccConstants.VERIFY_REQUEST, params } }
    function success(params) { return { type: ccConstants.VERIFY_SUCCESS, params } }
    function failure(error) { return { type: ccConstants.VERIFY_FAILURE, error } }
}

//******** ENABLE/DISABLE CC *********//
function enableCC(params) {
    return (dispatch) => {
        dispatch(request(params));
        ccService.enableCC(params)
            .then(
                ccResponse => {
                    if (ccResponse.status) {
                        dispatch(success(ccResponse));
                        dispatch(alertActions.success(ccResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/collectioncenter");
                        }, 1000);
                    } else {
                        dispatch(failure(ccResponse.err.msg.toString()));
                        dispatch(alertActions.error(ccResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: ccConstants.ENABLE_REQUEST, params } }
    function success(params) { return { type: ccConstants.ENABLE_SUCCESS, params } }
    function failure(error) { return { type: ccConstants.ENABLE_FAILURE, error } }
}

function clear() {
    return { type: ccConstants.CLEAR_STORE };
}


