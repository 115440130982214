export default   {
    required: ':attr is required',
    email: 'Enter valid :attr address',
    between: ':attr must be :min-:max characters long',
    'newPassword_confirmation.required' : "Confirm new password is required",
    'newPassword_confirmation.same': 'New password and confirm password does not match',
   'confirmPassword.same': 'New password and confirm password does not match',
    checkFile:'File is not valid',
    'back':'only upload',
    'confirmPassword.same': 'New password and confirm password does not match',
    'aadhar_number.required':'Aadhaar No. is required',
    'front.required':'Aadhaar front is required',
    'back.required':'Aadhaar back is required',
    'dob.required': 'Date of Birth is required',
    'firstname.required':'First name is required',
    'lastname.required':'Last name is required',
    'houseno.required':'House No. is required',
    'colony.required':'Village/Colony is required',
    'SAR_img.required':'SAR Image is required!',
    'BAR_img.required': 'BAR Image is required!', 
    'name_en.required': 'Category name(English) is required!',
    'name_hi.required': 'Category name (Hindi) is required!',
    'unit_en.required': 'Unit(English) is required!',
    'unit_hi.required': 'Unit(Hindi) is required!',
    'category.required': 'Category Image is required!',
    'phone.required': 'Phone Number is required',
    'whatsapp.required': 'Whatsapp Number is required',
    'prod_img.required': 'Product Image is required',
    'truck_num.required': 'Truck number is required!',
    'truck_num.min': 'Truck number must be at least 10 characters!',
    'truck_num.max': 'Truck number may not be greater than 12 characters!',

    'kType.required':"Please Select Type",
    'fType.required':"Please Select Report Type",
    'timedureation.required':'Please Select Duration',
    'collectionCenter.required':"Please select CLuster/CC",
    'states.required':"Please select state",

    'title_en.required'      :'English title is required',
    'title_hi.required'      :'Hindi title is required',
    'body_en.required'      :'English message is required',
    'body_hi.required'      :'Hindi message is required', 
    'notify_img.required'    : 'Image is required!'
}