import { clusterConstants } from '../_constants';
import { initailState } from '../_constants';

export function cluster(state = {}, action) {  
    
  switch (action.type) {
    case clusterConstants.CLUSTER_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case clusterConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case clusterConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW CC
      case clusterConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case clusterConstants.VIEW_SUCCESS:         
        return {
          ...action.user.res
        };
      case clusterConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE CC
        case clusterConstants.CLUSTER_UPDATE_REQUEST,
        clusterConstants.CLUSTER_UPDATE_SUCCESS:
          return {};

        case clusterConstants.CLUSTER_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE CC
    case clusterConstants.DELETE_REQUEST,
          clusterConstants.DELETE_SUCCESS:
      return {};

    case clusterConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY CC
    case clusterConstants.VERIFY_REQUEST,
    clusterConstants.VERIFY_SUCCESS:
      return {};

    case clusterConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE CC
    case clusterConstants.ENABLE_REQUEST,
    clusterConstants.ENABLE_SUCCESS:
      return {};

    case clusterConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
