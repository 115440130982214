import { listingConstants,barConstants } from '../_constants';
import { barService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const barActions = {
    getList,
    activateBar,
    addBar,
    deleteBar,
    getSingleBar,
    updateBar,
    verifyBar,
    enableBar,
    clear
};
function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search != ''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.states!=undefined && meta.states != ''){
       url = url+`state=${meta.states}&`;
    }
    if(meta.district!=undefined && meta.district != ''){
       url = url+`district=${meta.district}&`;
    }
    if(meta.block!=undefined && meta.block != ''){
       url = url+`block=${meta.block}&`;
    }
    if(meta.coll_id!=undefined && meta.coll_id != ''){
       url = url+`coll_id=${meta.coll_id}&`;
    }
    if(meta.sort!=undefined && meta.sort != ''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy != ''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
     if(meta.status!=undefined && meta.status != ''){
        url = url+`status=${meta.status}&`;
     }
     return url;
}

//******** BAR LIST *********//
function getList() {
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings;        
        barService.getList(getParam(meta))
            .then(
                barLists => {
                    if (barLists.status) {
                        let res = {
                            items: barLists.res.sar,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(barLists.res.count),
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(barLists.err.msg.toString()));
                        dispatch(alertActions.error(barLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

//********* ADD BAR **********//
function addBar(params) {
    return (dispatch) => {        
        dispatch(request(params));
        barService.addBar(params)
            .then(
                barResponse => {
                    if (barResponse.status) {
                        dispatch(success(barResponse));
                        history.push("/bar");
                        dispatch(alertActions.success(barResponse.res.msg.toString()));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/bar");
                        //   }, 1000);
                    } else {
                        dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.BAR_REGISTER_REQUEST,params } }
    function success(params) { return { type: barConstants.BAR_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: barConstants.BAR_REGISTER_FAILURE, error } }
}


//******** DELETE BAR *********//
function deleteBar(params){
    return (dispatch) => {        
        dispatch(request(params));
        barService.deleteBar(params)
            .then(
                barResponse => {
                    if (barResponse.status) {
                        dispatch(success(barResponse));
                        dispatch(alertActions.success('BAR Deleted Successfully'));
                        // window.location.reload(false);
                    } else {
                        dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.DELETE_REQUEST,params } }
    function success(params) { return { type: barConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: barConstants.DELETE_FAILURE, error } }
}

//******** ACTIVATE BAR *********//
function activateBar(params){
    return (dispatch) => {        
        dispatch(request(params));
        barService.activateBar(params)
            .then(
                barResponse => {
                    if (barResponse.status) {
                        dispatch(success(barResponse));
                        dispatch(alertActions.success('BAR activated successfully'));
                        // window.location.reload(false);
                    } else {
                        dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.ACTIVATE_REQUEST,params } }
    function success(params) { return { type: barConstants.ACTIVATE_SUCCESS, params } }
    function failure(error) { return { type: barConstants.ACTIVATE_FAILURE, error } }
}

   //*******  SINGLE BAR VIEW  ******///////// 
 function getSingleBar(user) {     
    return dispatch => {
        dispatch(request());
        barService.viewBar(user).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: barConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: barConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: barConstants.VIEW_FAILURE, error };
   }
  }

   //*******  SINGLE BAR UPDATE  ******///////// 
  function updateBar(params,id) {      
    return (dispatch) => {        
        dispatch(request(params,id));
        barService.barUpdate(params,id)
            .then(
                barResponse => {                    
                    if (barResponse && barResponse.status) {
                        dispatch(success(barResponse));
                        history.push("/bar");
                        dispatch(alertActions.success('BAR updated successfully'));
                        // setTimeout(() => {
                        //     dispatch(success());
                        //     history.push("/bar");
                        //   }, 1000);
                    } else {
                       // dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                   // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.BAR_UPDATE_REQUEST,params } }
    function success(params) { return { type: barConstants.BAR_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: barConstants.BAR_UPDATE_FAILURE, error } }
}


//******** VERIFY BAR *********//
function verifyBar(params){
    return (dispatch) => {        
        dispatch(request(params));
        barService.verifyBar(params)
            .then(
                barResponse => {
                    if (barResponse.status) {
                        dispatch(success(barResponse));
                        dispatch(alertActions.success(barResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/bar");
                          }, 1000);
                    } else {
                        dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.VERIFY_REQUEST,params } }
    function success(params) { return { type: barConstants.VERIFY_SUCCESS, params } }
    function failure(error) { return { type: barConstants.VERIFY_FAILURE, error } }
}

//******** ENABLE/DISABLE BAR *********//
function enableBar(params){
    return (dispatch) => {        
        dispatch(request(params));
        barService.enableBar(params)
            .then(
                barResponse => {
                    if (barResponse.status) {
                        dispatch(success(barResponse));
                        dispatch(alertActions.success(barResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            history.push("/bar");
                          }, 1000);
                    } else {
                        dispatch(failure(barResponse.err.msg.toString()));
                        dispatch(alertActions.error(barResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: barConstants.ENABLE_REQUEST,params } }
    function success(params) { return { type: barConstants.ENABLE_SUCCESS, params } }
    function failure(error) { return { type: barConstants.ENABLE_FAILURE, error } }
}

function clear() {
    return { type: barConstants.CLEAR_STORE };
}


