import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { barConstants } from '../_constants';
export const barService = {
    getList,
    activateBar,
    addBar,
    deleteBar,
    viewBar,
    barUpdate,
    verifyBar,
    enableBar
    
   
};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_BAR_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function deleteBar(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_BAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
        });
}


function activateBar(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['ACTIVATE_BAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
        });
}

 //*******  VIEW BAR  ******/////////
    function viewBar(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_BAR"]}/${id.id}`, requestOptions).then(handleResponse).then(
            barData =>{
                if (barData.status == 0) {
                    return barData;
                } else {
                    return barData;
                }
            }
        );
    }

     //*******  BAR UPDATE  ******/////////
    function barUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_BAR']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(barResoponse => {
               if (barResoponse.status === 1) {
                    return barResoponse;
                } else {
                    return barResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addBar(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_BAR']}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// VERIFY BAR //
function verifyBar(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_BAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
        });
}

// ENABLE BAR //
function enableBar(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_BAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(barResoponse => {
           if (barResoponse.status == 0) {
                return barResoponse;
            } else {
                return barResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}