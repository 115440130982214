import { productConstants } from '../_constants';
import { initailState } from '../_constants';

export function product(state = {}, action) {  
    
  switch (action.type) {
    case productConstants.PRODUCT_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case productConstants.PRODUCT_REGISTER_SUCCESS:
          return {
            loading: false,
            ...state
          };
        case productConstants.PRODUCT_REGISTER_FAILURE:
              return {
                loading: false,  
            };
      case productConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case productConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW PRODUCT
      case productConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case productConstants.VIEW_SUCCESS:   
            
        return {
          productDetails:action.user.res          
        };
      case productConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE PRODUCT
        case productConstants.PRODUCT_UPDATE_REQUEST,
        productConstants.PRODUCT_UPDATE_SUCCESS:
          return {};

        case productConstants.PRODUCT_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE PRODUCT
    case productConstants.DELETE_REQUEST,
          productConstants.DELETE_SUCCESS:
      return {};

    case productConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY PRODUCT
    case productConstants.VERIFY_REQUEST,
    productConstants.VERIFY_SUCCESS:
      return {};

    case productConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE PRODUCT
    case productConstants.ENABLE_REQUEST,
    productConstants.ENABLE_SUCCESS:
      return {};

    case productConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
