import React, { Component } from "react";
import {Form,Table,Badge,Input } from 'reactstrap';
import Select from 'react-select';
import clonedeep from 'lodash/cloneDeep';
import { confirmAlert } from 'react-confirm-alert'; 


export class Content extends Component {
    constructor(props) {      
     super(props);
     this.state = {
      product:this.props.product,
      f_percent:this.props.futurestic_per,
      total_mandi_price:0,
      amount_to_be_paid:0,
      submitted:false
     };
     this.myRef = React.createRef();
   }
   inputRefs = [];
   setRef = (ref) => {
    this.inputRefs.push(ref);
  };
 
   handleChangeCheckbox = (e) =>{
     this.calculatePrice();
    }

   calculatePrice(){
    let product = [];
    var volumeOfferNeeds=[];
    var amount_to_be_paid = 0;
    var mandi_price = 0;
   var total_mandi_price= 0;
    product = clonedeep(this.state.product);
       product.map((data,index)=>{
         if(this.inputRefs[index].checked){
          var total_price=0;
             let our_price = parseFloat(data.mandi_price + (data.mandi_price*data.premiumPercentage)/100);
               total_price = total_price + our_price;
               total_mandi_price =  parseFloat(total_mandi_price + data.quantity*data.mandi_price);
             if(data.generic_offer>0){
               total_price =  parseFloat(total_price + (data.mandi_price*data.generic_offer)/100);              
             }
             if(data.volOffer){
                  volumeOfferNeeds =   data.volOffer.filter((data1,index)=>{
                          return  data1.quantity<=data.quantity;
                   })
                   volumeOfferNeeds.map((data2,index)=>{
                     
                        if(volumeOfferNeeds.length == (index+1)){
                           total_price = total_price + parseFloat((data.mandi_price*data2.percentage)/100);
                         
                        }
                   });
                  
             }
           amount_to_be_paid = amount_to_be_paid + total_price*data.quantity;
             
         }
       }
     );
     amount_to_be_paid = amount_to_be_paid + parseFloat((total_mandi_price*this.props.futurestic_per)/100);
     
     this.setState({amount_to_be_paid:amount_to_be_paid.toFixed(2),total_mandi_price:total_mandi_price.toFixed(2)});
   }


   priceReturn = (product,quantity) =>{
    var volumeOfferNeeds=[];
    var amount_to_be_paid = 0;
    var mandi_price = 0;
   var total_mandi_price= 0;
    product = clonedeep(product);
       product.map((data,index)=>{
            var total_price=0;
             let our_price = parseFloat(data.mandi_price + (data.mandi_price*data.premiumPercentage)/100);
               total_price = total_price + our_price;
               total_mandi_price =  parseFloat(total_mandi_price + quantity*data.mandi_price);
             if(data.generic_offer>0){
               total_price =  parseFloat(total_price + (data.mandi_price*data.generic_offer)/100);
              
             }
             if(data.volOffer){
                  volumeOfferNeeds =   data.volOffer.filter((data1,index)=>{
                          return  data1.quantity<=quantity;
                   })
                   volumeOfferNeeds.map((data2,index)=>{
                     
                        if(volumeOfferNeeds.length == (index+1)){
                           total_price = total_price + parseFloat((data.mandi_price*data2.percentage)/100);
                         
                        }
                   });
                  
             }
            
           amount_to_be_paid = amount_to_be_paid + total_price*quantity;
         }
       
     );
    return  amount_to_be_paid = amount_to_be_paid;
   }
  
   handleChange  = item => (e) => {
    let product = [];
     product = clonedeep(this.state.product);
        product.map((data,index)=>{
        if(index == e.target.name.split('quantity')[1]){
                    var prod_array = [];
                     prod_array.push(data);

                    data['amount'] = this.priceReturn(prod_array,e.target.value).toFixed(2);
            return  data['quantity'] = parseInt(e.target.value);
          }
       
        });
       this.setState({product},()=>{
         this.calculatePrice();
        });
    }
    
  
    defaultValue = (item="", event="")=>{
       if(item && event == ""){
        return {'label':item.name,value:item._id};
      }else if(item && event == 'handlchange'){
        return {'label':item.label,value:item.value};
      }
    }
  
    handleChangeProduct = (item,indexno) => (e)=>{
     this.defaultValue(e,'handlchange');
     const productdetails =  this.getProductDetails(e);
     let product = [];
     product = clonedeep(this.state.product);
     product.map((data,index)=>{
        if(index === indexno){
            data['mandi_price'] = productdetails[0]['mandi_price']
            data['premiumPercentage'] = productdetails[0]['premiumPercentage']
            data['prod_id'] = productdetails[0]['prod_id']
            data['volOffer'] = productdetails[0]['volOffer']
            data['generic_offer'] = productdetails[0]['generic_offer']
            data['name'] = productdetails[0]['en']['name']
            data['amount'] = this.priceReturn(productdetails,data.quantity).toFixed(2);
            
              return data;
        }
     });
     this.setState({product},()=>{
     })
    }
   loadProductdata=() =>{
      var data = this.props.items.map(data=>(
                       {'label':data.en.name,value:data.prod_id}
     ));
     return data;
   } 
  
  
  
  
   getProductDetails=(item)=>{
     return this.props.listOfProducts.filter((data)=>{
       return data.prod_id==item.value
        
     })
   }

   getTotalMandiPrice = () => {
     if(this.state.product){  
    let product = [];
    var total_mandi_price = 0;
    product = clonedeep(this.state.product);
       product.map((data,index)=>{
            total_mandi_price =  parseFloat( total_mandi_price +(data.mandi_price*data.quantity));
       });
       this.setState({total_mandi_price:total_mandi_price});
    }
   }

   finalAmount = ()=> {
    if(this.state.product){  
       this.calculatePrice();
        }
   }
   handleSubmit = (e)=>{
    e.preventDefault();

    var errors = true;
    this.inputRefs.map((item,index)=>{
      if(item.checked == true){
         errors = false;
       }
    });
     if(!errors){
    let product = [];
    product = clonedeep(this.state.product);
    var final_product = product.filter((data,index)=>{
         if(this.inputRefs[index].checked){
           return data;
         }
       }
     );
    
     confirmAlert({
      title: 'Confirm to Receive order?',
      message: 'Are you sure, you want to proceed to receive?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
           this.props.proceedToreceive({product:final_product,amount:this.state.amount_to_be_paid,price:this.state.total_mandi_price});
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
    }else{
      this.props.alertError("Please select any product");
    }
 }

 closeModel= (e)=>{
   this.props.closeModel();
 }
  
    render() {
      console.log("content",this.props);  
      return (
        <div>
         
           <Form onSubmit={this.handleSubmit}>
		   <div class="table-responsive">
        <Table bordered >
                 <thead>
                 <tr>
                   <th>Select</th>
                   <th>Select Name</th>
                   <th>Qantity</th>
                   <th>Mandi Price</th>
                   <th>Our Price</th>
                   {this.props.authentication.admin_type === 111 && 
                    <React.Fragment>
                   <th>Premimum Percantage</th>      
                   <th>Generic Offer Percantage</th>  
                    </React.Fragment>
                    }
                 </tr>
                 </thead>
                 <tbody>
                   { this.state.product && 
                     this.state.product.map((item, index, arr)=>
                     { 
                       var itemstatus = 1;

                        item.status.map((item1, index, arr)=>
                        { 
                          itemstatus = item1.Status;
                        })
                        // let  our_prc= ((((item.amount)*(this.state.f_percent))/100)  + Number(item.amount))
                        let  our_prc= ((((item.mandi_price*item.quantity)*(this.state.f_percent))/100)  + Number(item.amount)) 
                       return <tr key={index}>
                                  <td>
                                  <input 
                                  type="checkbox" disabled = {(itemstatus!=2)? "disabled" : ""} ref={this.setRef}   name ={"selectproduct"+index} value={item._id} className="form-check-input" id={"selectproduct"+index}  onClick={this.handleChangeCheckbox}/>
                                  <label className="form-check-label" htmlFor="selectall"></label>
                                  </td>                         
                            <td>
                          <Select
                         name="form-field-name"
                         defaultValue={this.defaultValue(item)}
                         value={this.defaultValue()} 
                         onChange={this.handleChangeProduct(item,index)}
                        //  options={this.props.productList}
                         isDisabled={(itemstatus!=2)? true :false}
                         isClearable={false}                
                         /></td>
                          <td><Input  type="number" min="1" disabled = {(itemstatus!=2)? "disabled" : ""}  onChange={this.handleChange(item)} id="checkbox1" name={"quantity"+index} value={item.quantity}/><span>{item.unit}</span></td>
                          <td>Rs. {item.mandi_price}</td>
                          <td>Rs. {Number(our_prc).toFixed(2) || '0'}</td>
                          {this.props.authentication.admin_type === 111 && 
                           <React.Fragment>
                            <td>{item.premiumPercentage} %</td>
                            <td>{item.generic_offer} %</td>
                            </React.Fragment>
                           }
                         </tr>
                     })}
                    
                 </tbody>
               </Table>
			   </div>
               <p style={{color: 'red','font-size': '10px'}}>*Note:All the items may received at 
               collection centre or may be for some products buyer request is not matched yet.
               For further details, please see status of products inside Product details</p>
               <p className="mar_bot"><span className="fw-500">Mandi Price:</span> <span className="fw-600">Rs. {this.state.total_mandi_price}</span>
               <span className="alignright"><span className="fw-500">Selected product amount:</span><span className="fw-600"> <strong style={{'font-size': "18px"}}>Rs. {this.state.amount_to_be_paid}</strong></span></span></p>
               <div className="text-center">
               <button className="btn-popup submit">Submit</button>
               <button className="btn-popup cancel" onClick={this.closeModel} type="button">Cancel</button>
               </div>
               </Form>
     </div>
      );
    };
  }