import { reportConstants } from '../_constants';
import { initailState } from '../_constants';

export function report(state = {}, action) {  
    
  switch (action.type) {
    case reportConstants.SAR_EXL_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case reportConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case reportConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

   
    default:
      return state
  }
}
