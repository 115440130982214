import React, { Component } from "react";

export class ListSearchControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      showSearchInfo: false,
      search:''
    };
  }

  static getDerivedStateFromProps(nextProps) {
     return {
        keyword: null,
        showSearchInfo: true
      }
  }

  onHandleChange = event => {
    var target = event.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      search: value
    },()=>{
      if(this.state.search == ""){
        this.props.onSearch("");
      }
    })
  };

  onSearch = e => {

    e.preventDefault();
    this.props.onSearch(this.state.search);
  };

  onClearSearch = () => {
    this.props.onSearch("");
    this.setState({
      keyword: "",
      showSearchInfo: false
    });
  };

  render() {    
    return (
      <div className="col-xs-12 box_search">
        <form onSubmit={this.onSearch}>
          <div className="search_wrp">
            <div className="input-group">
              <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Type keyword here"
                value={this.state.search}
                onChange={this.onHandleChange}
              />
              <span className="input-group-btn">
                <button
                  className="btn btn-search"
                  type="button"
                  onClick={this.onSearch}
                >
                  Search <i className="fa fa-search ml-1" />                  
                </button>
              </span>
            </div>
            {/* <button
              type="button"
              className="btn btn-default btn_clear"
              onClick={this.onClearSearch}
            >
              <i className="fa fa-close" />
            </button> */}
          </div>
          <div className={!this.state.showSearchInfo ? "hidden" : ""}>
          <strong>{this.state.keyword}</strong>
          </div>
        </form>
      </div>
    );
  }
}


