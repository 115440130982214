import { barConstants } from '../_constants';
import { initailState } from '../_constants';

export function bar(state = {}, action) {  
    
  switch (action.type) {
    case barConstants.BAR_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case barConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case barConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW BAR
      case barConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case barConstants.VIEW_SUCCESS:         
        return {
          // items: action.user.res.sellerDetails,
          adminDetails:action.user.res.adminDetails,
          prev_cluster:action.user.res.adminDetails.cluster    
        };
      case barConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE BAR
        case barConstants.BAR_UPDATE_REQUEST,
        barConstants.BAR_UPDATE_SUCCESS:
          return {};

        case barConstants.BAR_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE BAR
    case barConstants.DELETE_REQUEST,
          barConstants.DELETE_SUCCESS,
          barConstants.ACTIVATE_REQUEST,
          barConstants.ACTIVATE_SUCCESS:
      return {};

    case barConstants.DELETE_FAILURE,
        barConstants.ACTIVATE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY BAR
    case barConstants.VERIFY_REQUEST,
    barConstants.VERIFY_SUCCESS:
      return {};

    case barConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE BAR
    case barConstants.ENABLE_REQUEST,
    barConstants.ENABLE_SUCCESS:
      return {};

    case barConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
