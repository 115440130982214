export const notificationConstants = {
    NOTIFICATION_ADD_REQUEST: 'NOTIFICATION_ADD_REQUEST',
    NOTIFICATION_LIST:'NOTIFICATION_LIST',
    NOTIFICATION_ADD_SUCCESS: 'NOTIFICATION_ADD_SUCCESS',
    NOTIFICATION_ADD_FAILURE: 'NOTIFICATION_ADD_FAILURE',
 
     DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
     DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
     DELETE_NOTIFICATION_FAILURE: 'DELETE_FAILURE',
     
     CLEAR_STORE:'CLEAR_STORE',
     GETALL_REQUEST:'GETALL_REQUEST',
     GETALL_FAILURE:'GETALL_FAILURE'

 
 }