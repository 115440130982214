import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { sarConstants } from '../_constants/sar.constant';
export const sarService = {
    getList,
    onActivate,
    addSar,
    deleteSar,
    viewSar,
    sarUpdate,
    verifySar,
    enableSar
    
   
};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_SAR_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

function deleteSar(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_SAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}


function onActivate(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['ACTIVATE_SAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

 //*******  VIEW SAR  ******/////////
    function viewSar(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_SAR"]}/${id.id}`, requestOptions).then(handleResponse).then(
            sarData =>{
                if (sarData.status == 0) {
                    return sarData;
                } else {
                    return sarData;
                }
            }
        );
    }

     //*******  SAR UPDATE  ******/////////
    function sarUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_SAR']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(sarResoponse => {
               if (sarResoponse.status === 1) {
                    return sarResoponse;
                } else {
                    return sarResoponse;
                }
            })
            .catch(err=>{
            });
    }


function addSar(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_SAR']}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// VERIFY SAR //
function verifySar(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_SAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
        });
}

// ENABLE SAR //
function enableSar(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_SAR']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(sarResoponse => {
           if (sarResoponse.status == 0) {
                return sarResoponse;
            } else {
                return sarResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}