import { inventoryConstants } from '../_constants';
import { initailState } from '../_constants';

export function inventory(state = {}, action) {  
    
  switch (action.type) {
    case inventoryConstants.INVENTORY_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case inventoryConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case inventoryConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW INVENTORY
      case inventoryConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case inventoryConstants.VIEW_SUCCESS:   
            
        return {
          ...action.user.res         
        };
      case inventoryConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE INVENTORY
        case inventoryConstants.INVENTORY_UPDATE_REQUEST,
        inventoryConstants.INVENTORY_UPDATE_SUCCESS:
          return {};

        case inventoryConstants.INVENTORY_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE INVENTORY
    case inventoryConstants.DELETE_REQUEST,
          inventoryConstants.DELETE_SUCCESS:
      return {};

    case inventoryConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY INVENTORY
    case inventoryConstants.VERIFY_REQUEST,
    inventoryConstants.VERIFY_SUCCESS:
      return {};

    case inventoryConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE INVENTORY
    case inventoryConstants.ENABLE_REQUEST,
    inventoryConstants.ENABLE_SUCCESS:
      return {};

    case inventoryConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
