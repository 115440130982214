import { listingConstants,inventoryConstants } from '../_constants';
import { inventoryService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const inventoryActions = {
    getList,
    getProduct,
    addInventory,
    deleteInventory,
    getSingleInventory,
    updateInventory,
    getListProduct,
    clear
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined  && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search!=''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.cat_id!=undefined && meta.cat_id!=''){
       url = url+`cat_id=${meta.cat_id}&`;
    }
    if(meta.sort!=undefined && meta.sort!=''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy!=''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
   
     return url;
}
//******** INVENTORY LIST *********//
function getList(cc,type="") { 
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
      return  inventoryService.getList(cc,getParam(meta),type)
            .then(
                inventoryLists => {
                    if (inventoryLists.status) {
                        let res = {
                            items: inventoryLists.res.inventory,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(inventoryLists.res.count),
                                // mandi_name:parseInt(inventoryLists.res.inventory[0].mandi_name)
                            },
                        }    
                        return new Promise((resolve, reject) => {                    
                        dispatch(success(res));
                            resolve()
                         });
                    } else {
                        dispatch(failure(inventoryLists.err.msg.toString()));
                        dispatch(alertActions.error(inventoryLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}

function getListProduct(cc,type="") { 
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
      return  inventoryService.getListProduct(cc,`?pageNo=${meta.page}&limit=${meta.pageSize}`,type)
            .then(
                inventoryLists => {
                    if (inventoryLists.status) {
                        let res = {
                            items: inventoryLists.res.inventory,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(inventoryLists.res.count),
                            },
                        }    
                        return new Promise((resolve, reject) => {                    
                        dispatch(success(res));
                            resolve()
                         });
                    } else {
                        dispatch(failure(inventoryLists.err.msg.toString()));
                        dispatch(alertActions.error(inventoryLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}



//******** GET PRODUCT LIST *********//
function getProduct(cc,type="") { 
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
        inventoryService.getList(cc,`?pageNo=${meta.page}&limit=${meta.pageSize}&search=${meta.search}`,type)
            .then(
                inventoryLists => {
                    if (inventoryLists.status) {
                        let res = {
                            items: inventoryLists.res.inventory,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(inventoryLists.res.count),
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(inventoryLists.err.msg.toString()));
                        dispatch(alertActions.error(inventoryLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: inventoryConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: inventoryConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: inventoryConstants.GETALL_FAILURE, error } }
}

//********* ADD INVENTORY **********//
function addInventory(params,type="") {    
    return (dispatch) => {        
        dispatch(request(params));
        inventoryService.addInventory(params,type)
            .then(
                InventoryResponse => {
                    if (InventoryResponse.status) {
                        dispatch(success(InventoryResponse));
                             setTimeout(() => {
                           
                            // history.push(`/collection-center/inventory/${params.coll_id}`);
                       history.goBack();
                          }, 1000);
                        // dispatch(alertActions.success('Product added successfully'));
                   
                    } else {
                        dispatch(failure(InventoryResponse.err.msg.toString()));
                        dispatch(alertActions.error(InventoryResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: inventoryConstants.INVENTORY_REGISTER_REQUEST,params } }
    function success(params) { return { type: inventoryConstants.INVENTORY_REGISTER_SUCCESS, params } }
    function failure(error) { return { type: inventoryConstants.INVENTORY_REGISTER_FAILURE, error } }
}


//******** DELETE INVENTORY *********//
function deleteInventory(params,type=""){
    return (dispatch) => {        
        dispatch(request(params));
        inventoryService.deleteInventory(params,type)
            .then(
                InventoryResponse => {
                    if (InventoryResponse.status) {
                        dispatch(success(InventoryResponse));
                        dispatch(alertActions.success('Inventory deleted successfully'));
                    } else {
                        dispatch(failure(InventoryResponse.err.msg.toString()));
                        dispatch(alertActions.error(InventoryResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: inventoryConstants.DELETE_REQUEST,params } }
    function success(params) { return { type: inventoryConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: inventoryConstants.DELETE_FAILURE, error } }
}

   //*******  SINGLE INVENTORY VIEW  ******///////// 
 function getSingleInventory(user,type="") {     
    return dispatch => {
        dispatch(request());
        inventoryService.viewInventory(user,type).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: inventoryConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: inventoryConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: inventoryConstants.VIEW_FAILURE, error };
   }
  }

   //*******  SINGLE INVENTORY UPDATE  ******///////// 
  function updateInventory(params,id,type="") {            
    return (dispatch) => {        
        dispatch(request(params,id));
        inventoryService.inventoryUpdate(params,id,type)
            .then(
                InventoryResponse => {  
                    if (InventoryResponse && InventoryResponse.status) {
                        dispatch(success(InventoryResponse));
                        // dispatch(alertActions.success(InventoryResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            // history.push("/inventory");
                            history.goBack();
                          }, 1000);
                    } else {
                        dispatch(failure(InventoryResponse.res.msg.toString()));
                        dispatch(alertActions.error(InventoryResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: inventoryConstants.INVENTORY_UPDATE_REQUEST,params } }
    function success(params) { return { type: inventoryConstants.INVENTORY_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: inventoryConstants.INVENTORY_UPDATE_FAILURE, error } }
}


function clear() {
    return { type: inventoryConstants.CLEAR_STORE };
}


