import { homeConstants } from '../_constants';
import { homeService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const homeActions = {
    homeDashboard,
    clear
};


   //*******  DASHBOARD VIEW  ******///////// 
 function homeDashboard() { 
    return dispatch => {
        dispatch(request());
        return homeService.viewDashboard().then(
          async user => {
            if (user.status === 1) {              
              // user=JSON.parse(JSON.stringify(user))
              // let graphData = await homeService.dashboardGraph(2,4).then(
              //   graphData=>graphData
              // )
              // user.res.graphData=graphData
              dispatch(success(user))
                  
            } else {  
              if(user.err.errCode==2 || user.err.errCode==34 || user.err.errCode==35){
                localStorage.clear();
              history.push("/login");
            }        
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));          
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: homeConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: homeConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: homeConstants.VIEW_FAILURE, error };
   }
  }

function clear() {
    return { type: homeConstants.CLEAR_STORE };
}


