import { listingConstants,purchaseOrdConstants } from '../_constants';
import { purchaseOrdService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const purchaseOrderActions = {
    getList,
    deletePurchaseReq,
    getSinglePurchaseOrd,
    clear
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined  && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search!=''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.status!=undefined && meta.status!=''){
       url = url+`status=${meta.status}&`;
    }
    if(meta.sort!=undefined && meta.sort!=''){
       url = url+`sort=${meta.sort}&`;
    }
    if(meta.sortBy!=undefined && meta.sortBy!=''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
   
     return url;
  }

//******** PURCHASE REQUEST LIST *********//
function getList(cc,type="") { 
   
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
        purchaseOrdService.getList(cc,getParam(meta),type)
            .then(
                purchaseReqList => {
                    if (purchaseReqList.status) {
                        let res = {
                            items: purchaseReqList.res.order,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(purchaseReqList.res.count),
                                // mandi_name:parseInt(purchaseReqList.res.inventory[0].mandi_name)
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(purchaseReqList.err.msg.toString()));
                        dispatch(alertActions.error(purchaseReqList.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}


//******** DELETE PURCHASE REQUEST *********//
function deletePurchaseReq(params,type=""){
    return (dispatch) => {        
        dispatch(request(params));
        purchaseOrdService.deletePurchaseReq(params,type)
            .then(
                purchaseReqResponse => {
                    if (purchaseReqResponse.status) {
                        dispatch(success(purchaseReqResponse));
                        // dispatch(alertActions.success('PurchaseReq has been deleted successfully'));
                    } else {
                        dispatch(failure(purchaseReqResponse.err.msg.toString()));
                        dispatch(alertActions.error(purchaseReqResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: purchaseOrdConstants.DELETE_REQUEST,params } }
    function success(params) { return { type: purchaseOrdConstants.DELETE_SUCCESS, params } }
    function failure(error) { return { type: purchaseOrdConstants.DELETE_FAILURE, error } }
}
   //*******  SINGLE PURCHASE REQUEST VIEW  ******///////// 
 function getSinglePurchaseOrd(user,type="") {  
    return dispatch => {
        dispatch(request());
        purchaseOrdService.viewPurchaseOrd(user,type).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: purchaseOrdConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: purchaseOrdConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: purchaseOrdConstants.VIEW_FAILURE, error };
   }
  }




function clear() {
    return { type: purchaseOrdConstants.CLEAR_STORE };
}


