import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { purchaseReqConstants } from '../_constants/purchase.constant';

export const purchaseOrdService = {
    getList,
    viewPurchaseOrd,
    dispatchPurchaseOrd,
    canclePurchaseOrd,
    deliverdOrder,
    notAbleTotakeOrder
};

//******* PURCHASE ORDER LIST ******/////////

function getList(collId, params,type) {  
    var URL = '';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        };
      
        if(type == '' ){
            URL = `${config.apiUrl}${APIURL['CC_PURCHASE_ORD_LIST']}${collId}/list${params}`
        }else{
            URL = `${config.apiUrl}${APIURL['CLUSTER_PURCHASE_ORD_LIST']}${collId}/list${params}`
        }

        return fetch( URL,requestOptions)
        .then(handleResponse)
        .then(purchaseOrdResponse => {
           if (purchaseOrdResponse.status == 0) {
                return purchaseOrdResponse;
            } else {
                return purchaseOrdResponse;
            }
        })
        .catch(function(err){
        });
}


function dispatchPurchaseOrd(params,type) {
    var URL = '';
       const requestOptions = {
           method: 'PUT',
           headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                       'accessToken': localStorage.getItem('jwt')
                    },
            body:JSON.stringify(params)
       };
       return fetch(`${config.apiUrl}${APIURL['DISPATCH_ORDER']}${params.ord_id}`,requestOptions)
           .then(handleResponse)
           .then(purchaseOrdResponse => {
              if (purchaseOrdResponse.status == 0) {
                   return purchaseOrdResponse;
               } else {
                   return purchaseOrdResponse;
               }
           })
           .catch(function(err){
           });
}


function canclePurchaseOrd(params,type) {
    var URL = '';
       const requestOptions = {
           method: 'DELETE',
           headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                       'accessToken': localStorage.getItem('jwt')
                    },
       };
       return fetch(`${config.apiUrl}${APIURL['CANCLE_PURCHASE_ORDER']}${params.ord_id}`,requestOptions)
           .then(handleResponse)
           .then(purchaseOrdResponse => {
              if (purchaseOrdResponse.status == 0) {
                   return purchaseOrdResponse;
               } else {
                   return purchaseOrdResponse;
               }
           })
           .catch(function(err){
           });
}



 //*******  VIEW PURCHASE ORDER  ******/////////
    function viewPurchaseOrd(id,type) {
        var URL="";
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
        if(type == '' ){
            URL = `${config.apiUrl}${APIURL["VIEW_CC_PURCHASE_ORD"]}${id.ccID}/view/${id.id}`;
        }  else if(type == 'cluster'){            
            URL = `${config.apiUrl}${APIURL["VIEW_CLUSTER_PURCHASE_REQ"]}/${id.id}`;
        } else{
            URL = `${config.apiUrl}${APIURL["VIEW_CLUSTER_PURCHASE_REQ"]}/${id.id}`;
        }
        return fetch(URL, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }

    function deliverdOrder(params) {
        var URL="";
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
         URL = `${config.apiUrl}${APIURL["ORDER_DELIVER"]}${params.ord_id}`;
       
    
        return fetch(URL, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }

    function notAbleTotakeOrder(params,ord_id) {
        var URL="";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
         body:JSON.stringify({
            product:params,
            ord_id:ord_id
         })
        };
         URL = `${config.apiUrl}${APIURL["NOT_ABLE_TO_TAKE_ORDER"]}${ord_id}`;
       
    
        return fetch(URL, requestOptions).then(handleResponse).then(
            productData =>{
                if (productData.status == 0) {
                    return productData;
                } else {
                    return productData;
                }
            }
        );
    }


function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}