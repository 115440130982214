import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";

export const graphService = {
    dashboardGraph
};

 //******* Dashboard GRAPH ******/////////
 function dashboardGraph(accountType,graphType) {                     
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
        'accessToken': localStorage.getItem('jwt')
     },
    };
    return fetch(`${config.apiUrl}${APIURL["SELL_BUY_GRAPH"]}?graphType=${graphType}&accountType=${accountType}`, requestOptions).then(handleResponse).then(
        graphData =>{
            if (graphData.status == 0) {
                return graphData;
            } else {
                return graphData;
            }
        }
    );
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}