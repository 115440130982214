import config from 'config';
import { APIURL } from "../_constants/apiStore";
import { authHeader } from '../_helpers';

export const helpCentreService = {
    getData,
    updateData  
};

// let config ={};

function getData(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiUrl}${APIURL['GET_HELP_CENTRE_DATA']}/${params.type}`, requestOptions)
        .then(handleResponse)
        .then(helpcenterResponse => {
           if (helpcenterResponse.status == 0) {
                return helpcenterResponse;
            } else {
                return helpcenterResponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}



     //*******  HELPCENTER  UPDATE  ******/////////
    function updateData(params) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['UPDATE_HELP_CENTRE_DATA']}`,requestOptions )
            .then(handleResponse)
            .then(categoryResoponse => {
               if (categoryResoponse.status === 1) {
                    return categoryResoponse;
                } else {
                    return categoryResoponse;
                }
            })
            .catch(err=>{
            });
    }


function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}