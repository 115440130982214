import { ccConstants } from '../_constants';
import { initailState } from '../_constants';

export function cc(state = {}, action) {  
  switch (action.type) {
    case ccConstants.CC_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case ccConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case ccConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW CC
      case ccConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case ccConstants.VIEW_SUCCESS:         
        return {
          ...action.user.res
        };
      case ccConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE CC
        case ccConstants.CC_UPDATE_REQUEST,
        ccConstants.CC_UPDATE_SUCCESS:
          return {};

        case ccConstants.CC_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE CC
    case ccConstants.DELETE_REQUEST,
          ccConstants.DELETE_SUCCESS:
      return {};

    case ccConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY CC
    case ccConstants.VERIFY_REQUEST,
    ccConstants.VERIFY_SUCCESS:
      return {};

    case ccConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE CC
    case ccConstants.ENABLE_REQUEST,
    ccConstants.ENABLE_SUCCESS:
      return {};

    case ccConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
