import Validator from "Validator"
import MESSAGE from './messages';

export default function validateForgotPassword(data){
  let rules = {
      'email' : 'required|email',
  };

  let validationResponse = Validator.make(data, rules,MESSAGE);
  let isValid = validationResponse.passes();
  let validationErrors = validationResponse.getErrors();

  let errors = {};
  for(let field in validationErrors){
    errors[field] = validationErrors[field][0];
  }

  return {
    errors,
    isValid
  }
}