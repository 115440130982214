import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isLoggedIn} from '../_helpers/checkSession'

export const PublicRoute = ({ component: Component, ...rest }) => (            
    <Route {...rest} render={props => (        
        !isLoggedIn()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)