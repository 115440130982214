import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { clusterConstants } from '../_constants';
export const clusterService = {
    getList,
    clusterDashboard,
    addCluster,
    deleteCluster,
    viewCluster,
    clusterUpdate,
    verifyCluster,
    enableCluster


};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL['CLUSTER_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status == 0) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(function (err) {
            // debugger;
        });
}

//*******  VIEW CLUSTER Dashboard  ******/////////
function clusterDashboard(clus_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL["CLUSTER_DASH_COUNT"]}/${clus_id}`, requestOptions).then(handleResponse).then(
        clusterData => {
            if (clusterData.status == 0) {
                return clusterData;
            } else {
                return clusterData;
            }
        }
    );
}

function deleteCluster(params, type) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };

    return fetch(`${config.apiUrl}${APIURL['CLUSTER_DELETE']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status == 0) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(function (err) {
        });
}

//*******  VIEW Cluster  ******/////////
function viewCluster(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL["CLUSTER_VIEW"]}/${id}`, requestOptions).then(handleResponse).then(
        clusterData => {
            if (clusterData.status == 0) {
                return clusterData;
            } else {
                return clusterData;
            }
        }
    );
}

//*******  Cluster UPDATE  ******/////////
function clusterUpdate(params, id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['CLUSTER_EDIT']}/${id}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status === 1) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(err => {
        });
}


function addCluster(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['CLUSTER_ADD']}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status == 0) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(function (err) {
        });
}

// VERIFY Cluster //
function verifyCluster(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_Cluster']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status == 0) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(function (err) {
        });
}

// ENABLE Cluster //
function enableCluster(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_Cluster']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(clusterResoponse => {
            if (clusterResoponse.status == 0) {
                return clusterResoponse;
            } else {
                return clusterResoponse;
            }
        })
        .catch(function (err) {
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}