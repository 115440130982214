import config from 'config';

import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
export const buyersService = {
    getList,
    buyerOrderHistory,
    viewOrderHistory,
    onActivate,
    deleteBuyer,
    viewBuyer,
    buyerUpdate,
    addBuyer,
    verifyBuyer,
    enableBuyer,
    getClusterbycity
};


     //*******  BUYER LIST  ******/////////
function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_BUYERS_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
} 

     //*******  BUYER ORDER HISTORY LIST  ******/////////
     function buyerOrderHistory(orderId,params) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
        };
        return fetch(`${config.apiUrl}${APIURL['BUYERS_ORDER_HISTORY']}/${orderId}${params}`, requestOptions)
            .then(handleResponse)
            .then(buyersResoponse => {
               if (buyersResoponse.status == 0) {
                    return buyersResoponse;
                } else {
                    return buyersResoponse;
                }
            })
            .catch(function(err){
            });
    } 
    
     //*******  VIEW Buyer ORDER HISTORY  ******/////////
 function viewOrderHistory(user) { 
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
        'accessToken': localStorage.getItem('jwt')
     },
    };

    return fetch(`${config.apiUrl}${APIURL["BUYER_VIEW_HISTORY"]}/${user.id}`, requestOptions).then(handleResponse).then(
        buyerData =>{
            if (buyerData.status == 0) {
                return buyerData;
            } else {
                return buyerData;
            }
        }
    );
}

     //*******  BUYER DELETE  ******/////////
function deleteBuyer(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['DELETE_BUYER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
}

function onActivate(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };

    return fetch(`${config.apiUrl}${APIURL['ACTIVATE_BUYER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
}

 //*******  VIEW BUYER  ******/////////
    function viewBuyer(id) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
    
        return fetch(`${config.apiUrl}${APIURL["VIEW_BUYER"]}/${id.id}`, requestOptions).then(handleResponse).then(
            buyerData =>{                
                if (buyerData.status == 0) {
                    return buyerData;
                } else {
                    return buyerData;
                }
            }
        );
    }

     //*******  BUYER UPDATE  ******/////////
    function buyerUpdate(params,id) {
        const requestOptions = {
            method: 'PUT',
            headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                        'accessToken': localStorage.getItem('jwt')
                     },
                     body:params
        };
    
        return fetch(`${config.apiUrl}${APIURL['EDIT_BUYERS']}/${id}`,requestOptions )
            .then(handleResponse)
            .then(buyersResoponse => {
               if (buyersResoponse.status === 1) {
                    return buyersResoponse;
                } else {
                    return buyersResoponse;
                }
            })
            .catch(err=>{
            });
    }


     //*******  BUYER ADD  ******/////////
function addBuyer(params) {
    const requestOptions = {
        method: 'POST',
        headers: {  'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_BUYER']}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
}
// VERIFY BUYER //
function verifyBuyer(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body:JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_BUYER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
}

function getClusterbycity(params){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_CLUSTER_BY_CITY']}${params}/list`, requestOptions)
        .then(handleResponse)
        .then(clsuterRessponse => {
           if (clsuterRessponse.status == 0) {
                return clsuterRessponse;
            } else {
                return clsuterRessponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}

// ENABLE BUYER //
function enableBuyer(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                    'accessToken': localStorage.getItem('jwt')
                 },
                 body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_BUYER']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(buyersResoponse => {
           if (buyersResoponse.status == 0) {
                return buyersResoponse;
            } else {
                return buyersResoponse;
            }
        })
        .catch(function(err){
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}