import { listingConstants,notificationConstants } from '../_constants';
import { notificationService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const notificationsActions = {
    getList,
    addNotification,
    deleteNotification,
    getSingleNotification,
    updateNotification,
    clear,
    getUpdatedList
};

//******** INVENTORY LIST *********//
function getList() {    
    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
        notificationService.getList(`?pageNo=${meta.page}&limit=${meta.pageSize}`)
            .then(
                notificationsLists => {
                    if (notificationsLists.status) {
                        let res = {
                            items: notificationsLists.res.notification,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(notificationsLists.res.count),
                                // mandi_name:parseInt(notificationsLists.res.notifications[0].mandi_name)
                            },
                        } 
                        dispatch(success(res));
                    } else {
                        dispatch(failure(notificationsLists.err.msg.toString()));
                        dispatch(alertActions.error(notificationsLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}


function getUpdatedList() {    
    return (dispatch, getState) => {        
        dispatch(request());
       
        notificationService.getList(`?pageNo=1&limit=5`)
            .then(
                notificationsLists => {
                    if (notificationsLists.status) {
                        dispatch(notificatonlist(notificationsLists.res));
                    } else {
                        dispatch(failure(notificationsLists.err.msg.toString()));
                        dispatch(alertActions.error(notificationsLists.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
  
    function request(meta) { return { type: notificationConstants.GETALL_REQUEST, meta} }
    function notificatonlist(params){ return { type: notificationConstants.NOTIFICATION_LIST,params } }
    function failure(error) { return { type: notificationConstants.GETALL_FAILURE, error } }
}


//********* ADD INVENTORY **********//
function addNotification(params) {    
    return (dispatch) => {        
        dispatch(request(params));
        notificationService.addNotification(params)
            .then(
                NotificationResponse => {                    
                    if (NotificationResponse.status) {
                        dispatch(success(NotificationResponse));
                        dispatch(alertActions.success('Notification sent successfully'));
                    } else {
                        dispatch(failure(NotificationResponse.err.msg.toString()));
                        dispatch(alertActions.error(NotificationResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
  
    function request(params) { return { type: notificationConstants.NOTIFICATION_ADD_REQUEST,params } }
    function success(params) { return { type: notificationConstants.NOTIFICATION_ADD_SUCCESS, params } }
    function failure(error) { return { type: notificationConstants.NOTIFICATION_ADD_FAILURE, error } }
}


//******** DELETE NOTIFICATION *********//
function deleteNotification(params){
    return (dispatch) => {        
        dispatch(request(params));
        notificationService.deleteNotification(params)
            .then(
                NotificationResponse => {
                    if (NotificationResponse.status) {
                        dispatch(success(NotificationResponse));
                        dispatch(alertActions.success(NotificationResponse.res.msg));
                    } else {
                        dispatch(failure(NotificationResponse.err.msg.toString()));
                        dispatch(alertActions.error(NotificationResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: notificationConstants.DELETE_NOTIFICATION_REQUEST,params } }
    function success(params) { return { type: notificationConstants. DELETE_NOTIFICATION_SUCCESS, params } }
    function failure(error) { return { type: notificationConstants.DELETE_NOTIFICATION_FAILURE, error } }
}

   //*******  SINGLE NOTIFICATION VIEW  ******///////// 
 function getSingleNotification(user,type="") {     
    return dispatch => {
        dispatch(request());
        notificationsService.viewNotification(user,type).then(
          user => {
            if (user.status === 1) {
                dispatch(success(user))
                   
            } else {
                alertActions.error(user.err.msg, {
                containerId: "B"
              });
              dispatch(failure( user.err.msg.toString()));
              dispatch(alertActions.error(user.err.msg.toString()));
            }
          },
          error => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      };  
  
   function request() {
     return { type: notificationsConstants.VIEW_REQUEST  };
   }
   function success(user) {
     return { type: notificationsConstants.VIEW_SUCCESS, user };
   }
   function failure(error) {
     return { type: notificationsConstants.VIEW_FAILURE, error };
   }
  }

   //*******  SINGLE NOTIFICATION UPDATE  ******///////// 
  function updateNotification(params,id,type="") {            
    return (dispatch) => {        
        dispatch(request(params,id));
        notificationsService.notificationsUpdate(params,id,type)
            .then(
                NotificationResponse => {                   
                    if (NotificationResponse && NotificationResponse.status) {
                        dispatch(success(NotificationResponse));
                        // dispatch(alertActions.success(NotificationResponse.res.msg.toString()));
                        setTimeout(() => {
                            dispatch(success());
                            // history.push("/notifications");
                            history.goBack();
                          }, 1000);
                    } else {
                        dispatch(failure(NotificationResponse.res.msg.toString()));
                        dispatch(alertActions.error(NotificationResponse.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: notificationsConstants.INVENTORY_UPDATE_REQUEST,params } }
    function success(params) { return { type: notificationsConstants.INVENTORY_UPDATE_SUCCESS, params } }
    function failure(error) { return { type: notificationsConstants.INVENTORY_UPDATE_FAILURE, error } }
}


function clear() {
    return { type: notificationsConstants.CLEAR_STORE };
}
