import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class GoogleMaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 28.5355,
                lng: 77.3910
            },
            defcenter: {
                lat: 28.5355,
                lng: 77.3910
            },
            lat: 28.5355,
            lng: 28.5355,
            draggable: true,
            zoom: 16
        };
       
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return  {
            lat: nextProps.lat,
            lng: nextProps.lng,
            center: {
                lat: nextProps.lat,
                lng: nextProps.lng
            }
          }
      }

    

    componentDidUpdate(preProps) {
        if (preProps.lat !== this.props.lat) {
            this.setState({
                center: {
                    lat: this.state.lat,
                    lng: this.state.lng
                }
            });
        }
    }

    changeMarkerPosition = (e) => {
       
    }

    onChildMouseDown = (childKey, childProps, mouse) => {
        // set map no draggable 
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    onChildMouseMove = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }

    onChildMouseUp = () => {
        this.setState({
            draggable: true,
        }, () => {
            this.props.onDrag({
                lat: this.state.lat,
                lng: this.state.lng
            });
            this.setState({
                center: {
                    lat: this.state.lat,
                    lng: this.state.lng
                }
            });
        });
    }


    render() {
        return (
            <div style={{ height: '65vh', width: '100%' }}>
                <GoogleMapReact
                    draggable={this.state.draggable}
                    bootstrapURLKeys={{
                        key: 'AIzaSyATuHDh5TFNbsDJF8LhM2uR-EPeR7QhbRU',
                        language: 'en',
                        region: 'in'
                    }}
                    defaultCenter={this.state.defcenter}
                    center={this.state.center}
                    defaultZoom={this.state.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onChildMouseDown={this.onChildMouseDown}
                    onChildMouseUp={this.onChildMouseUp}
                    onChildMouseMove={this.onChildMouseMove}
                    onClick={this.changeMarkerPosition}
                    style={{ height: '65vh', width: '100%' }}
                    options={{
                        mapTypeControl: true
                    }}
                >
                    <div
                        className="place"
                        lat={this.state.lat}
                        lng={this.state.lng}
                    ></div>
                </GoogleMapReact>
            </div>
        );
    }
}

