export const INDEX_PAGE_SIZE_DEFAULT = 100;
export const INDEX_PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];
const data = {
    loading: true,
    search: '',
    states:'',
    district:'',
    block:'',
    page: 1,
    pageSize: INDEX_PAGE_SIZE_DEFAULT,
    pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
    pageTotal: 1,
    total: 0,
    image_url:'',
    id : '',
    coll_id:'',
    clus_id:'',
    status:'',
    cat_id:''
};
export const initailState = {
    items: [],
    meta: makeOject(data)
}

function makeOject(obj){
    for (var propName in obj) { 
        if (obj[propName] === null || obj[propName] === undefined || obj[propName]=='') {
          delete obj[propName];
        }
      }
      return obj;
}