import config from 'config';
import { authHeader } from '../_helpers';
import { APIURL } from "../_constants/apiStore";
import { ccConstants } from '../_constants';
export const ccService = {
    getList,
    ccDashboard,
    addCC,
    deleteCC,
    viewCC,
    ccUpdate,
    verifyCC,
    enableCC


};

// let config ={};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL['GET_CC_LIST']}${params}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status == 0) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(function (err) {
            // debugger;
        });
}

//*******  VIEW  CC Dashboard  ******/////////
function ccDashboard(coll_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL["CC_DASHBOARD_COUNT"]}/${coll_id}`, requestOptions).then(handleResponse).then(
        ccData => {
            if (ccData.status == 0) {
                return ccData;
            } else {
                return ccData;
            }
        }
    );
}

function deleteCC(params) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL['DELETE_CC']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status == 0) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(function (err) {
        });
}

//*******  VIEW CC  ******/////////
function viewCC(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };
    return fetch(`${config.apiUrl}${APIURL["VIEW_CC"]}/${id}`, requestOptions).then(handleResponse).then(
        ccData => {
            if (ccData.status == 0) {
                return ccData;
            } else {
                return ccData;
            }
        }
    );
}

//*******  CC UPDATE  ******/////////
function ccUpdate(params, id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['EDIT_CC']}/${id}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status === 1) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(err => {
        });
}


function addCC(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: params
    };
    return fetch(`${config.apiUrl}${APIURL['ADD_CC']}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status == 0) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(function (err) {
        });
}

// VERIFY CC //
function verifyCC(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
    };

    return fetch(`${config.apiUrl}${APIURL['VERIFY_CC']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status == 0) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(function (err) {
        });
}

// ENABLE CC //
function enableCC(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
        },
        body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}${APIURL['ENABLE_CC']}/${params.id}`, requestOptions)
        .then(handleResponse)
        .then(ccResoponse => {
            if (ccResoponse.status == 0) {
                return ccResoponse;
            } else {
                return ccResoponse;
            }
        })
        .catch(function (err) {
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}