import { homeConstants } from '../_constants';
import { initailState } from '../_constants';

export function home(state = {}, action) {  
    
  switch (action.type) {

      // VIEW DASHBOARD COUNT
      case homeConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case homeConstants.VIEW_SUCCESS:         
        return {
          ...action.user.res
        };
      case homeConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

    default:
      return state
  }
}
