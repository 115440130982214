export const purchaseReqConstants = {
    
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    VIEW_REQUEST: 'PURCHASE_VIEW_REQUEST',
    VIEW_SUCCESS: 'PURCHASE_VIEW_SUCCESS',
    VIEW_FAILURE: 'PURCHASE_VIEW_FAILURE',

    INIT_PAYMENT_REQUEST: 'PAYMENT_INIT_REQUEST',
    INIT_PAYMENT_SUCCESS: 'PAYMENT_INIT_SUCCESS',
    INIT_PAYMENT_FAILURE: 'PAYMENT_INIT_FAILURE',

    RECIEVED_REQUEST: 'RECIEVED_REQUEST',
    RECIEVED_SUCCESS: 'RECIEVED_SUCCESS',
    RECIEVED_FAILURE: 'RECIEVED_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}