export const productConstants = {
    PRODUCT_REGISTER_REQUEST: 'PRODUCT_REGISTER_REQUEST',
    PRODUCT_REGISTER_SUCCESS: 'PRODUCT_REGISTER_SUCCESS',
    PRODUCT_REGISTER_FAILURE: 'PRODUCT_REGISTER_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
    PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
    PRODUCT_UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',


    VIEW_REQUEST: 'PRODUCT_VIEW_REQUEST',
    VIEW_SUCCESS: 'PRODUCT_VIEW_SUCCESS',
    VIEW_FAILURE: 'PRODUCT_VIEW_FAILURE',

    VERIFY_REQUEST: 'PRODUCT_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'PRODUCT_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'PRODUCT_VERIFY_FAILURE',

    ENABLE_REQUEST: 'PRODUCT_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'PRODUCT_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'PRODUCT_ENABLE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}