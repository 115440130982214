import React,{Component} from 'react'; 
import {Modal, ModalBody,Button,ModalFooter} from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';



export class KrishiFalCropper extends Component {

     constructor(props){
        super(props);
            this.state = {
                src:null,
                show:false,
                crop: {
                    unit: '%',
                    width: 30,
                    aspect: 16 / 9,
                },
                propsReceive:false
            }
            
    }


 static getDerivedStateFromProps(props, state){
    if(props.show!==state.show && !state.propsReceive){
           return {
                show: props && props.show,
                src: props && props.src,
                propsReceive:true
             }
        }
 }
 setFalseReceiveProps = () =>{
  this.setState({
    propsReceive:false,
    src:null,
  });
 }
  toggleInfo = () => {
    this.setState({
      show: !this.state.show,
      src:null
    });

  }


  onImageLoaded = image => {
    this.imageRef = image;
  };



  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  
handleChange = (e) => {
    const { name, value } = e.target;
  
    this.setState({ [name]: value },()=>{ 
     let {errors, isValid} = validateAddProduct(this.state);
     if(this.state.submitted){
        this.setState({
        errors: errors,
        });
        }
      }
    );  
   }
  
  
   
  
    async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newFile.jpeg'
        );
        this.props.croppedImage({
          prod_img:croppedImageUrl.blob,
          front_src:croppedImageUrl.fileUrl
        });
        this.setState({
          prod_img:croppedImageUrl.blob,
          front_src:croppedImageUrl.fileUrl
        })
      }
    }
  
    getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
  
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
  
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({blob:blob,'fileUrl':this.fileUrl});
        }, 'image/jpeg');
      });
    }
   
    render(){
      
        return(
            <Modal isOpen={this.state.show} toggle={this.toggleInfo}
            className={'modal-info ' + this.props.className}>
            <Button className="close" onClick={this.toggleInfo}><i className="fa fa-times"></i></Button>
            <ModalBody>
            {this.state.src && (
            <ReactCrop
            src={this.state.src}
            crop={this.state.crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            />
            )}
            </ModalBody>
            <ModalFooter>
            <Button onClick={this.toggleInfo}>Crop Image</Button>
            </ModalFooter>
            </Modal>
        )
    }


}