export const helpCentreConstants = {
    GET_HELPCENTER_REQUEST: 'GET_HELPCENTER_REQUEST',
    GET_HELPCENTER_SUCCESS: 'GET_HELPCENTER_SUCCESS',
    GET_HELPCENTER_FAILED: 'GET_HELPCENTER_FAILED',

    GET_HELPCENTER_REQUEST:'GET_HELPCENTER_UPDATE_REQUEST',
    GET_HELPCENTER_UPDATE_SUCCESS: 'GET_HELPCENTER_UPDATE_SUCCESS',
    GET_HELPCENTER_UPDATE_FAILED: 'GET_HELPCENTER_UPDATE_FAILED',
   
};
