export const offerConstants = {
    GET_OFFER_REQUEST: 'GET_OFFER_REQUEST',
    GET_OFFER_SUCCESS: 'GET_OFFER_SUCCESS',
    GET_OFFER_FAILED: 'GET_OFFER_FAILED',

    GET_OFFER_REQUEST:'GET_OFFER_UPDATE_REQUEST',
    GET_OFFER_UPDATE_SUCCESS: 'GET_OFFER_UPDATE_SUCCESS',
    GET_OFFER_UPDATE_FAILED: 'GET_OFFER_UPDATE_FAILED',
   
};
