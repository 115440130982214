import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import validateForgotPassword from '../../../_helpers/validators/forgotpassword';
import { userActions } from '../../../_actions';
import logo from '../../../../src/assets/logo.png';

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
    const { errors, isValid } = validateForgotPassword(this.state, name);
    this.setState({
      errors: errors,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    const { errors, isValid } = validateForgotPassword(this.state);
    if(!isValid){
      this.setState({ errors:errors },()=>{});
    }
    if (email && isValid) {
      dispatch(userActions.forgotPassword(email));
    }
  }
  render() {
    const { loggingIn } = this.props;
    const { email, submitted,errors } = this.state;
    return (
        <div className="main_bg">
            <div className="app flex-row align-items-center custom_form">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Form onSubmit={ this.handleSubmit }>
										<div className="text-center"><img src={logo} alt="Krishifal" className="logo-login"/></div>
                                            <h1>Forgot Password</h1>
                                            <p className="text-muted">Enter your email</p>
                                            <div className={ 'form-group' + (submitted && !email ? ' has-error' : '') }>
                                                <InputGroup className="mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="icon-user"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="text" placeholder="Email" autoComplete="email"  name="email" value={ email } onChange={ this.handleChange } />
                                                    {
                                                        <div className="help-block">{errors.email}</div>
                                                    }
                                                </InputGroup>
                                            </div>
                                            <Row>
                                                <Col xs="12" className="text-center">
                                                    <Button color="primary">Forgot Password</Button>
                                                    { loggingIn &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </Col>
                                                <Col xs="12" className="text-center">
                                                    Back to <Link to="/login"> <Button color="link" className="px-0">Login</Button></Link>?
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
  }
}

const connectedForgotPage = connect()(ForgotPasswordPage);
export { connectedForgotPage as ForgotPasswordPage };
// export default Login;
