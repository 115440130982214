import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {listings} from './listing.reducer';
import {seller}   from './seller.reducer';
import {buyer}   from './buyer.reducer';
import {sar}   from './sar.reducer';
import {bar}   from './bar.reducer';
import {category}   from './category.reducer';
import {product}   from './product.reducer';
import {cc}   from './cc.reducer';
import {inventory}   from './inventory.reducer';
import {helpcentre} from './helpcenter.reducer';
import {cluster} from './cluster.reducer';
import {notification} from './notifications.reducer';
import { offers } from './offers.reducer';
import {purchase}   from './purchase.reducer';
import {home}   from './home.reducer';
import {graph}   from './graph.reducer';
import { report } from './report.reducer';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { fromJS } from 'immutable';

const persistConfig = {
  key:root,
  storage:storage,
  whitelist:[listings,users],
}


const rootReducer = combineReducers({
  authentication,
  registration,
  listings,
  users,
  seller,
  buyer,
  sar,
  bar,
  category,
  product,
  cc,
  home,
  graph,
  purchase,
  inventory,
  alert,
  helpcentre,
  cluster,
  notification,
  offers,
  report
});

export default persistReducer(persistConfig, rootReducer);