import config from 'config';
import { APIURL } from "../_constants/apiStore";

export const homeService = {
    viewDashboard
};

 //*******  VIEW Dashboard  ******/////////
    function viewDashboard() {                
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
            'accessToken': localStorage.getItem('jwt')
         },
        };
        return fetch(`${config.apiUrl}${APIURL["VIEW_DASHBOARD"]}`, requestOptions).then(handleResponse).then(
            homeData =>{
                if (homeData.status == 0) {
                    return homeData;
                } else {
                    return homeData;
                }
            }
        );
    }

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}