import { reportConstants } from '../_constants';
import { reportService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const reportActions = {
    reportExl,
    clear
};



//********* EXL REPORT **********//
function reportExl(params) {
    return (dispatch) => {        
        dispatch(request(params));
      return   reportService.reportExl(params)
            .then(
                reportResponse => {
                return new Promise((resolve,reject)=>{
                    if (reportResponse.status) {
                        resolve(reportResponse);
                    } else {
                        
                        dispatch(alertActions.error(reportResponse.err.msg.toString()));
                        reject(reportResponse);
                    }
                })
                },
                
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: reportConstants.REPORT_EXL_REQUEST,params } }
    function success(params) { return { type: reportConstants.REPORT_EXL_SUCCESS, params } }
    function failure(error) { return { type: reportConstants.REPORT_EXL_FAILURE, error } }
}

function clear() {
    return { type: reportConstants.CLEAR_STORE };
}


