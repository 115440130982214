import Validator from "Validator"
import MESSAGE from './messages';

export default function validateAddCategory(data,type){
  let rules = {
    'driver_name'       :'required|alpha|between:2,15',
    'driver_number'     :'required|isphonenumber|numeric',
    'truck_num'         :'required|min:10|max:12',
             
  };

  let validationResponse = Validator.make(data, rules,MESSAGE);
  validationResponse.extend('isphonenumber', phoneNumberRegexValidate, ':attr is not a valid phone number');
  let isValid = validationResponse.passes();
  let validationErrors = validationResponse.getErrors();

  let errors = {};
  for(let field in validationErrors){
    errors[field] = validationErrors[field][0];
  }

  function phoneNumberRegexValidate(name, value, params) {
    let hexadecimal =/^\d{10}$/;
    return value && hexadecimal.test(value) && value.toString().length === 10;
}

  return {
    errors,
    isValid
  }
}