import { offerConstants } from '../_constants';
import { offerService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';


export const offersAction = {
    getOffer,
    updateOffer,
    clear
};

//******** HELP CENTERE  *********//
function getOffer() {
    return (dispatch, getState) => {      
        dispatch(request(getState()));
        offerService.getOffer()
            .then(
                offersData => {
                    if (offersData.status) {
                        dispatch(success(offersData.res));
                    } else {
                        dispatch(failure(offersData.err.msg.toString()));
                        dispatch(alertActions.error(offersData.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: offerConstants.GET_OFFER_REQUEST, meta} }
    function success(data) { return { type: offerConstants.GET_OFFER_SUCCESS, data } }
    function failure(error) { return { type: offerConstants.GET_OFFER_FAILED, error } }
}

//********* UPDATE HEPCENTER **********//
function updateOffer(params) {
    return (dispatch) => {        
        dispatch(request());
        offerService.updateOffer(params)
            .then(
                offerResponse => {
                    if (offerResponse.status) {
                        setTimeout(() => {
                            dispatch(alertActions.success("Offers succesfully updated"));
                          }, 1000);
                          window.location.reload(false);
                    } else {
                        dispatch(alertActions.error(offerResponse.err.msg.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: offerConstants.GET_OFFER_REQUEST } }
    function success() { return { type: offerConstants.GET_OFFER_SUCCESS,  } }
    function failure(error) { return { type: offerConstants.GET_OFFER_FAILED, error } }
}


function clear() {
    return { type: barConstants.CLEAR_STORE };
}


