import React, { Component } from "react";
import {Form,Table,Badge,Input,Row,Col,FormGroup,Label, CardHeader, Card, CardBody } from 'reactstrap';
import validateLogisticInfo from '../_helpers/validators/validateLogisticInfo';
import { confirmAlert } from 'react-confirm-alert'; 
import { PopupboxManager} from 'react-popupbox';

export class LogisticsInfo extends Component {
    constructor(props) {
     super(props);
     this.state = {
      truck_num:'',
      driver_name:'',
      driver_number:'',
      name:'',
      submitted:false,
      errors:{}
     };
     this.myRef = React.createRef();
    
   }
   inputRefs = [];
   setRef = (ref) => {
    this.inputRefs.push(ref);
  };
 
  handleChange = (e) =>{
    const { name, value } = e.target;
    this.setState({ [name]: value },()=>{
        let {errors, isValid} = validateLogisticInfo(this.state);
        if(this.state.submitted){
            this.setState({
            errors: errors,
            });
        }
    }
    );  
  }
 
   handleSubmit = (e)=>{
    e.preventDefault();
    this,this.setState({submitted:true});
    let {errors, isValid} = validateLogisticInfo(this.state);  
    if(!isValid){
        this.setState({errors:errors},()=>{console.log(errors)});
    }
    if(isValid){
        confirmAlert({
        title: 'Confirm to Dispatch Order?',
        message: 'Are you sure, you want to dispatch Order?',
        buttons: [
            {
            label: 'Yes',
            onClick: () => {
                    this.props.dispatchOrder(this.state);                   
            }
            },
            {
            label: 'No',
            onClick: () => {
                
            }
            }
        ]
        });
        // }else{
        //   this.props.alertError("Please select any product");
        // }
    }
 }
cModel(){
  
  PopupboxManager.close();
}
 closeModel= (e)=>{

   this.props.closeModel();
 }
  
    render() {
      var {truck_num,driver_number,driver_name,errors} = this.state;
      return (
        <Card>
        <CardHeader>Vehicle Details 
          <button className="float-right close-modal" onClick={this.cModel}><i class="fa fa-times"></i></button>
          </CardHeader>
        <CardBody>
        <div>
           {/* <CardHeader>Vehicle Details</CardHeader> */}
           <Form onSubmit={this.handleSubmit}>          
                 <Row>
               <Col sm="6">
                  <FormGroup>
                    <Label>Truck Number<span class='required-label'>*</span></Label>
                    <Input type="text" name="truck_num" value={truck_num} onChange={this.handleChange}  className="form-control"/>
                    <div className="help-block">{errors.truck_num}</div>
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label> Driver Phone Number<span class='required-label'>*</span></Label>
                    <Input type="text"  name="driver_number" value={driver_number} onChange={this.handleChange}   className="form-control"/>
                    <div className="help-block">{errors.driver_number}</div>
                   
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label>Truck Driver Name<span class='required-label'>*</span></Label>
                    <Input type="text"  name="driver_name" value={driver_name} onChange={this.handleChange}   className="form-control"/>
                    <div className="help-block">{errors.driver_name}</div>
                   
                  </FormGroup>
                </Col>             
               
               
                <Col xs="12" className="text-center">
                <button type="submit" className="btn btn-primary">Submit</button>
                </Col>
           
          </Row>
            
               </Form>
     </div>
     </CardBody>
     </Card>
      );
    };
  }