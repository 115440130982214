import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store,persistor } from './_helpers';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter as Router } from 'react-router-dom';

render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
  

serviceWorker.register();