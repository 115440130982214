import { purchaseReqConstants } from '../_constants';
import { initailState } from '../_constants';

export function purchase(state = {}, action) {  
    
  switch (action.type) {
      case purchaseReqConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case purchaseReqConstants.VIEW_SUCCESS:   
        return {
          ...action.user.res         
        };
      case purchaseReqConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };
        case purchaseReqConstants.RECIEVED_REQUEST:      
        return {
            ...state
          };
        case purchaseReqConstants.RECIEVED_SUCCESS:   
          return {
            ...action.response.res         
          };
        case purchaseReqConstants.RECIEVED_FAILURE:
          return { 
            error: action.error
          };

    // DELETE INVENTORY
    case purchaseReqConstants.DELETE_REQUEST,
          purchaseReqConstants.DELETE_SUCCESS:
      return {};

    case purchaseReqConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
            // DELETE INVENTORY
    case purchaseReqConstants.INIT_PAYMENT_REQUEST,
    purchaseReqConstants.INIT_PAYMENT_SUCCESS:
    return {};

    case purchaseReqConstants.INIT_PAYMENT_FAILURE:
      return { 
        error: action.error
      };
   
    default:
      return state
  }
}
