export const APIURL = {
    ADMIN_LOGIN:'admin/login',
    USER_LOGIN:"user/login",
    UPDATE_USER:"user/Profile/edit",
    FORGOT_PASSWORD:'admin/forgot/password',
    RESET_PASSWORD:'admin/reset/password',
    CHANGE_PASSWORD: 'admin/change/password',
    
    VIEW_DASHBOARD: 'admin/dashboard/count',
    SELL_BUY_GRAPH: 'admin/dashboard/graph',

    GET_SELLERS_LIST:'admin/seller/list',
    ADD_SELLER:'admin/seller/add',
    VIEW_SELLER:'admin/seller/details',
    EDIT_SELLERS:'admin/seller/edit',
    DELETE_SELLER:'admin/seller/delete',
    VERIFY_SELLER:'admin/seller/verified',
    ENABLE_SELLER:'admin/seller/status',
    ACTIVATE_SELLER:'admin/seller/readd',
    SELLERS_ORDER_HISTORY:'order/seller/history',
    SELLER_VIEW_HISTORY:'order/admin/seller/view',

    GET_BUYERS_LIST:'admin/buyer/list',
    ADD_BUYER:'admin/buyer/add',
    EDIT_BUYERS:'admin/buyer/edit',
    VIEW_BUYER:'admin/buyer/details',
    DELETE_BUYER:'admin/buyer/delete',
    VERIFY_BUYER:'admin/buyer/verified',
    ENABLE_BUYER:'admin/buyer/status',
    ACTIVATE_BUYER:'admin/buyer/readd',
    BUYERS_ORDER_HISTORY:'order/buyer/history',
    BUYER_VIEW_HISTORY:'order/admin/buyer/view',
    

    GET_SAR_LIST:'admin/sar/list',
    ADD_SAR:'admin/sar/add',
    EDIT_SAR:'admin/sar/edit',
    VIEW_SAR:'admin/sar/detail',
    DELETE_SAR:'admin/sar/delete',
    VERIFY_SAR:'admin/sar/status',
    GET_DIST_SAR:'admin/sar/{district}/list',
    ACTIVATE_SAR:'admin/sar/readd',

    GET_BAR_LIST:'admin/bar/list',
    ADD_BAR:'admin/bar/add',
    EDIT_BAR:'admin/bar/edit',
    VIEW_BAR:'admin/bar/detail',
    DELETE_BAR:'admin/bar/delete',
    ENABLE_BAR:'admin/bar/status',
    ACTIVATE_BAR:'admin/bar/readd',

    GET_CATEGORY_LIST:'category/list',
    ADD_CATEGORY:'category/add',
    EDIT_CATEGORY:'category/edit',
    VIEW_CATEGORY:'category/view',
    DELETE_CATEGORY:'category/delete',
    VERIFY_CATEGORY:'category/change/status',

    GET_PRODUCT_LIST:'product/list',
    ADD_PRODUCT:'product/add',
    EDIT_PRODUCT:'product/edit',
    VIEW_PRODUCT:'product/view',
    DELETE_PRODUCT:'product/delete',

    GET_CC_LIST:'collection-center/list',
    CC_DASHBOARD_COUNT: 'collection-center/dashboard',
    ADD_CC:'collection-center/add',
    VIEW_CC:'collection-center/view',
    EDIT_CC:'collection-center/edit',
    DELETE_CC:'collection-center/delete',
    VERIFY_CC:'collection-center/verified',
    ENABLE_CC:'collection-center/status',

    CC_INVENTORY_LIST:'collection-center/',
    ADD_CC_INVENTORY:'collection-center/inventory/add',
    VIEW_CC_INVENTORY:'collection-center/inventory/view',
    EDIT_CC_INVENTORY:'collection-center/inventory/edit',
    DELETE_CC_INVENTORY:'collection-center/inventory/delete',

    CLUSTER_INVENTORY_LIST:'cluster/',
    ADD_CLUSTER_INVENTORY:'cluster/inventory/add',
    VIEW_CLUSTER_INVENTORY:'cluster/inventory/view',
    EDIT_CLUSTER_INVENTORY:'cluster/inventory/edit',
    DELETE_CLUSTER_INVENTORY:'cluster/inventory/delete',
    GET_CLUSTER_BY_CITY:'cluster/',

    LOGIN : 'user/login',
    USERS : 'user/userList',

    GET_HELP_CENTRE_DATA:'help-center/get',
    UPDATE_HELP_CENTRE_DATA: 'help-center/update',


    GET_OFFERS_ADV:'offer/advance/booking',
    UPDATE_OFFERS_ADV: 'offer/advance/booking/edit',


    CLUSTER_LIST:'cluster/list',
    CLUSTER_EDIT:'cluster/edit',
    CLUSTER_VIEW:'cluster/view',
    CLUSTER_DELETE:'cluster/delete',
    CLUSTER_ADD:'cluster/add',

    CLUSTER_DASH_COUNT: 'cluster/dashboard',

    NOTIFICATION_LIST:'notification/list',
    NOTIFICATION_DELETE:'notification/delete',
    NOTIFICATION_READ:'notification/read',
    NOTIFICATION_ADD:'notification/add',

    GET_DISTRICT:'admin/district/by',
    GET_BLOCK:'admin/city/by',
   

    CC_PURCHASE_ORD_LIST:'order/purchase/order/',
    CLUSTER_PURCHASE_ORD_LIST:'order/purchase/order/cluster/',
    VIEW_CC_PURCHASE_ORD:'order/purchase/order/',
    VIEW_CLUSTER_PURCHASE_REQ:'order/purchase/order/cluster/detail',
    DELETE_CC_PURCHASE_REQ:'collection-center/inventory/delete',
     DISPATCH_ORDER:'order/purchase/order/dispatch/',
    CANCLE_PURCHASE_ORDER:'order/purchase/order/cancelled/',
    ORDER_DELIVER:'order/purchase/order/received/',
    NOT_ABLE_TO_TAKE_ORDER:'order/purchase/order/not-able-to-take/',


    ORDER_RECIEVED:'order/purchase/request/received/',
    CC_INIT_PAYMENT:'order/purchase/request',
    VIEW_CC_PURCHASE_REQ:'order/purchase/request/view',
    CC_PURCHASE_REQ_LIST:'order/purchase/request/',
    CANCLE_PURCHASE_REQUEST:'order/purchase/request/cancelled',


    PAYMENT_LIST:'order/purchase/request/payment/initiated/',
    SEND_PAYMENT:'order/purchase/request/send/payment',

    REPORT_EXL:'admin/report'




}