import { categoryConstants } from '../_constants';
import { initailState } from '../_constants';

export function category(state = {}, action) {  
    
  switch (action.type) {
    case categoryConstants.CATEGORY_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case categoryConstants.GETALL_SUCCESS:
      return {
        items: action.params,
      };
    case categoryConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

      // VIEW CATEGORY
      case categoryConstants.VIEW_REQUEST:      
      return {
          ...state
        };
      case categoryConstants.VIEW_SUCCESS:   
            
        return {
          ...action.user.res          
        };
      case categoryConstants.VIEW_FAILURE:
        return { 
          error: action.error
        };

     // UPDATE CATEGORY
        case categoryConstants.CATEGORY_UPDATE_REQUEST,
        categoryConstants.CATEGORY_UPDATE_SUCCESS:
          return {};

        case categoryConstants.CATEGORY_UPDATE_FAILURE:
            return { 
              error: action.error
            };
    // DELETE CATEGORY
    case categoryConstants.DELETE_REQUEST,
          categoryConstants.DELETE_SUCCESS:
      return {};

    case categoryConstants.DELETE_FAILURE:
        return { 
          error: action.error
        };
    // VERIFY CATEGORY
    case categoryConstants.VERIFY_REQUEST,
    categoryConstants.VERIFY_SUCCESS:
      return {};

    case categoryConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE CATEGORY
    case categoryConstants.ENABLE_REQUEST,
    categoryConstants.ENABLE_SUCCESS:
      return {};

    case categoryConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
   
    default:
      return state
  }
}
