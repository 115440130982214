import { buyerConstants } from '../_constants/buyer.constant';
import { initailState } from '../_constants';

export function buyer(state = {}, action) {  
    
  switch (action.type) {
    case buyerConstants.BUYER_REGISTER_REQUEST:      
        return {
            loading: true,
            ...state
          };
      case buyerConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.params,
      };
    case buyerConstants.GETALL_FAILURE:
      return { 
        loading: false,
        error: action.error
      };
    // VIEW ORDER HISTORY
    case buyerConstants.ORDER_VIEW_REQUEST:      
    return {
        ...state
      };
    case buyerConstants.ORDER_VIEW_SUCCESS:             
      return {
        orderHistory:action.user.res
      };
    case buyerConstants.ORDER_VIEW_FAILURE:
      return { 
        error: action.error
      };
      // VIEW BUYER
      case buyerConstants.BUYER_VIEW_REQUEST:      
      return {
        loading: true,
          ...state
        };
      case buyerConstants.BUYER_VIEW_SUCCESS:     
        return {
          // items: action.user.res.buyerDetails,
          loading: false,
          buyerDetail:action.user.res.buyerDetails
        };
      case buyerConstants.BUYER_VIEW_FAILURE:
        return { 
          loading: false,
          error: action.error
        };

     // UPDATE BUYER
        case buyerConstants.BUYER_UPDATE_REQUEST,
        buyerConstants.BUYER_UPDATE_SUCCESS:
          return {
            loading: true,
          };

        case buyerConstants.BUYER_UPDATE_FAILURE:
            return { 
              loading: false,
              error: action.error
            };
    // DELETE BUYER
    case buyerConstants.BUYER_DELETE_REQUEST,
          buyerConstants.BUYER_DELETE_SUCCESS,
          buyerConstants.ACTIVATE_REQUEST,
          buyerConstants.ACTIVATE_SUCCESS:
      return {};

    case buyerConstants.BUYER_DELETE_FAILURE,
    buyerConstants.ACTIVATE_FAILURE:
        return { 
          error: action.error
        };
         // VERIFY BUYER
    case buyerConstants.VERIFY_REQUEST,
    buyerConstants.VERIFY_SUCCESS:
      return {};

    case buyerConstants.VERIFY_FAILURE:
        return { 
          error: action.error
        };
      // ENABLE DISABLE BUYER
    case buyerConstants.ENABLE_REQUEST,
    buyerConstants.ENABLE_SUCCESS:
      return {};

    case buyerConstants.ENABLE_FAILURE:
        return { 
          error: action.error
        };
    case buyerConstants.CLEAR_STORE:
        return {};
    default:
      return state
  }
}
