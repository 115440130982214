import { INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from '../_constants';
import { listingConstants } from '../_constants';
import {initailState} from '../_constants/pagination.constant';

// ACTIONS CREATORS
function $pageSize(pageSize = INDEX_PAGE_SIZE_DEFAULT) {
    if (pageSize < 1) {
        pageSize = 100;
    }
    else if (pageSize > 100) {
        pageSize = 100;
    }
    return {
        type: listingConstants.GETALL_REQUEST,
        meta: {
            pageSize,
            page: 1,
            loading: true
        },
    };
}

function $page(page = 1,sort,sortBy) {    
    return (dispatch, getState) => {
        // const { meta } = getState().users;

        if (page < 1) {
            page = 1;
        }
        dispatch({
            type: listingConstants.GETALL_REQUEST,
            meta: {
                page,
                sort,
                sortBy,
                loading: true
            },
        });
    };
}

function $search(search = '') {
    return (dispatch) => {
        dispatch({
            type: listingConstants.GETALL_REQUEST,
            meta: {
                search,
                loading: true
            },
        });
    };
}

function $filter(params) {
    var data = {
        states:params.states?params.states:'',
        district:params.districts?params.districts:'',
        block:params.block?params.block:'',
        coll_id:params.coll_id?params.coll_id:'',
        clus_id:params.clus_id?params.clus_id:'',
        status:params.status?params.status:'',
        cat_id:params.cat_id?params.cat_id:'',
        loading: true,
        
    };
    return (dispatch) => {
        dispatch({
            type: listingConstants.GETALL_REQUEST,
            meta:data
        });
    };
}

function $setDefault(params) {
    return (dispatch) => {
        dispatch({
            type: listingConstants.GETALL_REQUEST,
            meta: {
                loading: true,
                search: '',
                states:'',
                district:'',
                block:'',
                page: 1,
                pageSize: INDEX_PAGE_SIZE_DEFAULT,
                pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
                pageTotal: 1,
                total: 0,
                image_url:'',
                id : '',
                coll_id:'',
                clus_id:'',
                status:''
            }
        });
    };
}


function makeOject(obj){
    for (var propName in obj) { 
        if (obj[propName] === null || obj[propName] === undefined || obj[propName]=='') {
          delete obj[propName];
        }
      }
    return obj;
}
  

export const paginationActions = {
    $pageSize,
    $page,
    $search,
    $filter,
    $setDefault
};