export const sellerConstants = {
    SELLER_REGISTER_REQUEST: 'SELLER_REGISTER_REQUEST',
    SELLER_REGISTER_SUCCESS: 'SELLER_REGISTER_SUCCESS',
    SELLER_REGISTER_FAILURE: 'SELLER_REGISTER_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    SELLER_UPDATE_REQUEST: 'SELLER_UPDATE_REQUEST',
    SELLER_UPDATE_SUCCESS: 'SELLER_UPDATE_SUCCESS',
    SELLER_UPDATE_FAILURE: 'SELLER_UPDATE_FAILURE',


    VIEW_REQUEST: 'SELLER_VIEW_REQUEST',
    VIEW_SUCCESS: 'SELLER_VIEW_SUCCESS',
    VIEW_FAILURE: 'SELLER_VIEW_FAILURE',

    ORDER_VIEW_REQUEST: 'ORDER_VIEW_REQUEST',
    ORDER_VIEW_SUCCESS: 'ORDER_VIEW_SUCCESS',
    ORDER_VIEW_FAILURE: 'ORDER_VIEW_FAILURE',

    VERIFY_REQUEST: 'SELLER_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'SELLER_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'SELLER_VERIFY_FAILURE',

    ENABLE_REQUEST: 'SELLER_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'SELLER_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'SELLER_ENABLE_FAILURE',

    ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'ACTIVATE_FAILURE',

    CLEAR_STORE:'CLEAR_STORE'

}