import config from 'config';
import { APIURL } from "../_constants/apiStore";
import { authHeader } from '../_helpers';

export const offerService = {
    getOffer,
    updateOffer  
};


//****** GET OFFER ADVANCE ******//
function getOffer() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiUrl}${APIURL['GET_OFFERS_ADV']}`, requestOptions)
        .then(handleResponse)
        .then(offerResponse => {
           if (offerResponse.status == 0) {
                return offerResponse;
            } else {
                return offerResponse;
            }
        })
        .catch(function(err){
           // debugger;
        });
}



     //*******  OFFER ADVANCE  UPDATE  ******/////////

         function updateOffer(params) {
             
            const requestOptions = {
                method: 'PUT',
                headers: { 
                           'Content-Type': 'application/json',
                            'Authorization': 'Basic S3JJc0hpRmFMb045OktyaXNoaWZhbClAI3B3ZCgjQE9ubGluZQ==',
                            'accessToken': localStorage.getItem('jwt')
                         },
                         body:params
            };
        
            return fetch(`${config.apiUrl}${APIURL['UPDATE_OFFERS_ADV']}`,requestOptions )
                .then(handleResponse)
                .then(offerResponse => {
                   if (offerResponse.status === 1) {
                        return offerResponse;
                    } else {
                        return offerResponse;
                    }
                })
                .catch(err=>{
                });
        }


function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}