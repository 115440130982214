import { listingConstants,purchaseOrdConstants } from '../_constants';
import { purchaseOrdService } from '../_services';
import { alertActions } from '.';
import { paymentService } from '../_services/payment.service';



export const paymentActions = {
    getList,
   
};

function getParam(meta){
    var url = "?";
    if(meta.page!=undefined && meta.page != ''){
        url = url+`pageNo=${meta.page}&`;
    }
    if(meta.pageSize!=undefined && meta.pageSize != ''){
        url = url+`limit=${meta.pageSize}&`;
    }
    if(meta.search!=undefined && meta.search != ''){
       url = url+`search=${meta.search}&`;
    }
    if(meta.sort!=undefined && meta.sort != ''){
        url = url+`sort=${meta.sort}&`;
     }
     if(meta.sortBy!=undefined && meta.sortBy != ''){
        url = url+`sortBy=${meta.sortBy}&`;
     }
     return url;
}
           

//******** PURCHASE REQUEST LIST *********//
function getList(cc,type="pending") { 

    return (dispatch, getState) => {        
        dispatch(request(getState().listings.meta));
        const { meta } = getState().listings; 
        paymentService.getList(cc,getParam(meta),type)
            .then(
                purchaseReqList => {
                    if (purchaseReqList.status) {
                        let res = {
                            items: purchaseReqList.res.order,
                            meta: {
                                page: parseInt(meta.page),
                                loading: false,
                                pageSize: parseInt(meta.pageSize),
                                total: parseInt(purchaseReqList.res.count),
                            },
                        }                        
                        dispatch(success(res));
                    } else {
                        dispatch(failure(purchaseReqList.err.msg.toString()));
                        dispatch(alertActions.error(purchaseReqList.err.msg.toString()));
                    }
                },
                error => {
                    debugger;
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(meta) { return { type: listingConstants.GETALL_REQUEST, meta} }
    function success(lists) { return { type: listingConstants.GETALL_SUCCESS, lists } }
    function failure(error) { return { type: listingConstants.GETALL_FAILURE, error } }
}




function clear() {
    return { type: purchaseOrdConstants.CLEAR_STORE };
}


